import PropTypes from 'prop-types';
import {Select, InputLabel, MenuItem, FormControl, Avatar } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Link } from "react-router-dom";

  const tableColumns = [
    {
      field: "week",
      headerName: "Week",
      type: 'number',
      headerClassName: 'customHeader',
      width: '110',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      },
    {
      field: "owner",
      headerName: "Owner",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    }, 
    {
      field: "orders",
      headerName: "Orders",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "mexsd_re_f_units",
      headerName: "MXSD",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "mexsd_re_f_percent",
      headerName: "MXSD (%)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 0.40;
        
        return (
          <div

          >
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: "cr_units",
      headerName: "CR",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "cr_percent",
      headerName: "SCR (%)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 0.40;
        
        return (
          <div

          >
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: "late_handover_units",
      headerName: "LH",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "late_handover_percent",
      headerName: "LH (%)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 0.40;
        
        return (
          <div

          >
            {formattedValue}
          </div>
        );
      },
    },
    ];


export default tableColumns;