import { useState, useEffect, useCallback, useMemo } from "react";
import { listMQBandQBRS } from "../../../graphql/queries";
import { listMisses } from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import awsconfig from '../../../aws-exports';
import tableColumns from './summary_columns';
import tableColumns2 from './blank_reason_columns';
import tableColumns3 from './classification_performance_columns';
import { groupBy, sumBy } from 'lodash';
import './summary.css';
import { withAuthenticator } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

const Summary = ({ username, userGroup }) => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);

  const [editedRows, setEditedRows] = useState([]);
  const [open, setOpen] = useState(false);


  async function fetchData() {
    try {
      const response = await API.graphql(graphqlOperation(listMQBandQBRS, { limit: 100 }));
      const items = response.data.listMQBandQBRS.items;
      setData(items);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  function getTwoWeeksAgo() {
    const now = new Date();
    const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 15);
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
    const millisecondsTillOneWeekAgo = oneWeekAgo - startOfYear;
    const weekNumberOneWeekAgo = Math.ceil(millisecondsTillOneWeekAgo / millisecondsPerWeek);
    return weekNumberOneWeekAgo;
  }
  const twoWeeksAgo = getTwoWeeksAgo();

  function getCurrentWeek() {
    const now = new Date();
  
    if (now.getDay() === 0 || now.getDay() === 1) { 
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
      const millisecondsTillToday = now - startOfYear;
      const weekNumberToday = Math.ceil(millisecondsTillToday / millisecondsPerWeek);
      
      const previousWeekNumber = weekNumberToday - 1;
      
      return previousWeekNumber;
    } else {
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
      const millisecondsTillToday = now - startOfYear;
      const weekNumber = Math.ceil(millisecondsTillToday / millisecondsPerWeek);
      
      return weekNumber;
    }
  }
  const currentWeekNumber = getCurrentWeek();

  async function fetchData2() {
    try {
      let nextToken = null;
      const items = [];
  
      do {
        const response = await API.graphql(graphqlOperation(listMisses, {
          filter: {
            week: {
              ge: twoWeeksAgo
            }
          },
          limit: 10000,
          nextToken: nextToken
        }));
  
        const fetchedItems = response.data.listMisses.items;
        items.push(...fetchedItems);
  
        nextToken = response.data.listMisses.nextToken;
      } while (nextToken);
  
      // Get unique values for the "week" column
      const uniqueWeeks = Array.from(new Set(items.map(item => item.week)));
  
      // Create a table with unique weeks and unique ops_owners
      const table = [];
      uniqueWeeks.forEach((week) => {
        const uniqueOpsOwners = Array.from(new Set(items.filter(item => item.week === week).map(item => item.ops_owner)));
  
        uniqueOpsOwners.forEach((opsOwner, index) => {
          const row = {
            id: `${week}_${opsOwner}_${index}`,
            week: week,
            ops_owner: opsOwner,
          };
  
          const missTypes = ['MXSD', 'CANCELLATION', 'LH_BY_SELLER'];
          missTypes.forEach(missType => {
            const units = items
              .filter(item => item.week === week && item.ops_owner === opsOwner && item.miss_type === missType && !item.reason)
              .reduce((sum, item) => sum + item.units, 0);
              const unitsNotBlank = items
              .filter(item => item.week === week && item.ops_owner === opsOwner && item.miss_type === missType && item.reason)
              .reduce((sum, item) => sum + item.units, 0);  
            const totalUnits = items
              .filter(item => item.week === week && item.ops_owner === opsOwner && item.miss_type === missType)
              .reduce((sum, item) => sum + item.units, 0);
  
            const percent = totalUnits > 0 ? ((unitsNotBlank / totalUnits) * 100).toFixed(2) : 0;
  
            row[`${missType}_units`] = units;
            row[`${missType}_percent`] = percent;
            row[`${missType}_ordersNotBlank`] = unitsNotBlank;
            row[`${missType}_total`] = totalUnits;
          });
  
          table.push(row);
        });
      });
  
      setData2(table);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  const sortedData1 = useMemo(() => {
    const sortedItems = [...data].sort((a, b) => {
      const weekComparison = b.week - a.week;
      if (weekComparison !== 0) {
        return weekComparison;
      }
    });
  
    return sortedItems;
  }, [data]);
  const sortedData = useMemo(() => {
    const sortedItems = [...data2].sort((a, b) => {
      const weekComparison = b.week - a.week;
      if (weekComparison !== 0) {
        return weekComparison;
      }
      // Calculate the sum of percentages for each item
      const sumA = a.CANCELLATION_units + a.MXSD_units + a.LH_BY_SELLER_units;
      const sumB = b.CANCELLATION_units + b.MXSD_units + b.LH_BY_SELLER_units;
  
      // Sort by the sum of percentages in descending order
      return sumB - sumA;
    });
  
    return sortedItems;
  }, [data2]);

  async function fetchData3() {
    try {
      const response = await API.graphql(graphqlOperation(listMisses, { limit: 2000 }));
      const items = response.data.listMisses.items;
  
      // Get unique values for the "week" column
      const uniqueWeeks = Array.from(new Set(items.map(item => item.week)));
  
      // Create a table with unique weeks and unique ops_owners
      const table = [];
      uniqueWeeks.forEach((week) => {
        const uniqueClassification = Array.from(new Set(items.filter(item => item.week === week).map(item => item.classification)));
  
        uniqueClassification.forEach((classification, index) => {
          const row = {
            id: `${week}_${classification}_${index}`,
            week: week,
            classification: classification,
          };
  
          const missTypes = ['MXSD', 'CANCELLATION', 'LH_BY_SELLER'];
          missTypes.forEach(missType => {
            const units = items
              .filter(item => item.week === week && item.classification === classification && item.miss_type === missType && !item.reason)
              .reduce((sum, item) => sum + item.units, 0);
  
            const totalUnits = items
              .filter(item => item.week === week && item.classification === classification && item.miss_type === missType)
              .reduce((sum, item) => sum + item.units, 0);
  
            const percent = totalUnits > 0 ? ((units / totalUnits) * 100).toFixed(2) : 0;
  
            row[`${missType}_units`] = units;
            row[`${missType}_percent`] = percent;
          });
  
          table.push(row);
        });
      });
  
      setData3(table);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  const sortedData3 = useMemo(() => {
    const sortedItems = [...data3].sort((a, b) => {
      const weekComparison = b.week - a.week;
      if (weekComparison !== 0) {
        return weekComparison;
      }
    });
    return sortedItems;
  }, [data3]);

  useEffect(() => {
      try {
        fetchData();
        fetchData2();
        //fetchData3();
      } catch (error) {
        console.error('Error fetching user group information:', error);
      }
  }, []);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
    </GridToolbarContainer>
  );
  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2} direction="row">
        <Grid item xs={3} md={3}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Seller Flex- Summary
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
            
            
          </Typography>
        </Grid>
      </Grid>
      <DataGrid
      
        sx={{
          marginTop: '2vh',
          marginLeft: 1,
          marginRight: 2,
          padding: '10px 18px 18px 18px',
          backgroundColor: "#FFFFFF",
          border: '1px solid #e0e0e0',
          borderRadius: '10px',
          minHeight: '40%',
          maxHeight: '40%',
        }}
        rows={sortedData1}
        columns={tableColumns}
        components={{
          Toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        editMode="row"
        disableColumnSelector
        disableDensitySelector
        initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            filter: {
              filterModel: {
                items: [{ field: 'week', operator: '=', value: currentWeekNumber }],
              },
            },
        }}  
      />
      <Grid container spacing={2} direction="row">
        <Grid item xs={3} md={3}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Blank Reasons
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
            
            
          </Typography>
        </Grid>
      </Grid>
      <DataGrid
        sx={{
          marginTop: '2vh',
          marginLeft: 1,
          marginRight: 2,
          padding: '10px 18px 18px 18px',
          backgroundColor: "#FFFFFF",
          border: '0px',
          borderRadius: '10px',
          minHeight: '40%',
          maxHeight: '40%',
        }}
        rows={sortedData}
        columns={tableColumns2}
        components={{
          Toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        editMode="row"
        disableColumnSelector
        disableDensitySelector 
        autoHeight 
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          filter: {
            filterModel: {
              items: [{ field: 'week', operator: '>=', value: currentWeekNumber }],
            },
          },
      }}  
      />
    </div>
    );
};

export default (Summary);