import { useState, useEffect, useCallback, useMemo } from "react";
import { listMissesEasyShips } from "../../../graphql/queries";
import { updateMissesEasyShip } from "../../../graphql/mutations";
import { API, Amplify, Auth, graphqlOperation  } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Chip, Grid, Snackbar } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import awsconfig from '../../../aws-exports';
import { tableColumnsAdmin, tableColumnsOps, tableColumnsView } from './PreWBR_ES_columns';
import './PreWBR.css';
import { subWeeks  } from 'date-fns';
import { withAuthenticator, Loader } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

const MissesDayES = ({ username, userGroup, data, loading }) => {
  const [editedRows, setEditedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [initialData, setInitialData] = useState(data); 
  const [openSnackbar, setOpenSnackbar] = useState(false); 

  // Calculate the ratio for the session user only
  const sessionUserStats = data.reduce((stats, row) => {
    if (row.owner === username) {
      stats.total++;
      if (row.reason !== "LH_BY_CARRIER" && !row.reason) {
        stats.withoutReason++;
      }
    }
    return stats;
  }, { total: 0, withoutReason: 0 });

  sessionUserStats.ratio = sessionUserStats.withoutReason / sessionUserStats.total;
  const ratioPercentage = (sessionUserStats.ratio * 100).toFixed(0);
  let chipColor = 'error';
  let chipIcon = <ErrorIcon />;
  let chipLabel = `${ratioPercentage}% of your misses have no reason mapped`;

  if(ratioPercentage == 0){
    chipColor = 'success'
    chipIcon = <CheckCircleIcon />
    chipLabel = `No pending actions. Congrats!`
  }
  if (isNaN(ratioPercentage)) {
    chipColor = 'success';
    chipIcon = <CheckCircleIcon />;
    chipLabel = `No pending actions.`;
  }

  // Get the date for 1 week ago
  const sortedData = useMemo(() => {

    const sortedItems = data.sort((a, b) => {
      // Sort by date column in descending order
      const dateComparison = new Date(b.date) - new Date(a.date);
      if (dateComparison !== 0) {
        return dateComparison;
      }
  
      const ordersComparison = b.orders - a.orders;
      if (ordersComparison !== 0) {
        return ordersComparison;
      }
  
      const opsComparison = b.owner - a.owner;
      if (opsComparison !== 0) {
        return opsComparison;
      }
  
    });
  
    return sortedItems;
  }, [data]);

let tableColumns;

if (userGroup.includes('admin') )  {
  tableColumns = tableColumnsAdmin; 
} else if (userGroup.includes('ops_owner') || userGroup.includes('managers')){
  tableColumns = tableColumnsOps; 
} else {
  tableColumns = tableColumnsView; 
}

  const handleProcessRowUpdateError = (error, params) => {
    console.error('Error updating row:', error);
    // Perform additional error handling logic, such as showing an error message
  };

  const handleEditRow = useCallback(async (params) => {
    const { id, seller_name, owner, date, mid, orders, reason, sub_reason, remove_from_pm, comments, pod, poa, self_report, ticket, miss_type, week, classification, _version } = params;
    const editedRow = { 
      id, 
      seller_name,
      owner,
      date,
      mid,
      orders,
      reason,
      sub_reason,
      remove_from_pm,
      comments,
      pod,
      poa,
      self_report,
      ticket,
      miss_type,
      week,
      classification,
      user_edition: username,
      _version };
    // Check if the edited row is already in the editedRows state
    const existingRow = editedRows.find((row) => row.id === editedRow.id);
    try {
      if (existingRow) {
        // If the edited row is already in the state, update its value
        const updatedRows = editedRows.map((row) =>
          row.id === editedRow.id ? editedRow : row
        );
        setEditedRows(updatedRows);
      } else {
        // If the edited row is not in the state, add it
        setEditedRows((prevRows) => [...prevRows, editedRow]);
      }
    } catch (error) {
      console.error('Error updating rows:', error);
    }
  }, [editedRows, username]);
  
  const handleUpdateDatabase = async () => {
    try {
      for (const { id, owner, seller_name, date, reason, sub_reason, remove_from_pm, comments, pod, poa, self_report, ticket, _version } of editedRows) {
        const input = {
          id: id,
          owner: owner,
          seller_name: seller_name,
          date: date,
          reason: reason,
          sub_reason: sub_reason,
          remove_from_pm: remove_from_pm,
          comments: comments,
          pod: pod,
          poa: poa,
          self_report: self_report,
          ticket: ticket,
          _version: _version,
          user_edition: username, 
        };
        // Perform the GraphQL mutation for each edited row
        await API.graphql(graphqlOperation(updateMissesEasyShip, { input }));
      }
      setInitialData((prevData) => {
        const updatedData = prevData.map((row) => {
          const editedRow = editedRows.find((editedRow) => editedRow.id === row.id);
          return editedRow ? { ...row, ...editedRow } : row;
        });
        return updatedData;
      });
      // Clear the edited rows
      setEditedRows([]);
      setOpen(false);
      setOpenSnackbar(true); 
    } catch (error) {
      console.error('Error updating rows:', error);
    }
  };  
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarExport />
      <Button
        variant="contained"
        color="primary"
        disabled={editedRows.length === 0} 
        onClick={handleUpdateDatabase}
      >
        Salvar
      </Button>
      <GridToolbarQuickFilter
        sx={{
          position: 'absolute',
          padding: '18px',
          right: '15px'
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
        <div>
          <Grid container spacing={2} direction="row">
          <Grid item xs={3} md={3}>
              <Typography
                sx={{
                  marginTop: 0,
                  marginLeft: 3,
                  fontFamily: 'Source Sans Pro',
                  fontSize: 'calc(10px + 0.5vh)',
                  fontWeight: 'lighter',
                  backgroundColor: 'lightcoral',
                  padding: '10px',
                  borderRadius: '5px',
                  textAlign: 'center',
                }}
              >
                Dear User, New Feature Alert: Now, you can view all tracking IDs and order IDs by hovering your mouse cursor over the 'units' cell!
              </Typography>
            </Grid>
            <Grid item xs={8} md={8} className="blankReasonsChip" sx={{ marginTop: '3vh', textAlign: 'right' }}>
              <Chip icon={chipIcon} label={chipLabel} color={chipColor} />
            </Grid>
          </Grid>
          {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
              </div>
            </div>
            ) : (
            <DataGrid
                sx={{
                  marginTop: '2vh',
                  marginLeft: 2,
                  marginRight: 2,
                  padding: '10px 18px 18px 18px',
                  backgroundColor: "#FFFFFF",
                  border: '0px',
                  borderRadius: '10px',
                  maxHeight: '90vh',
                  overflow: 'auto', 
              }}
              rows={sortedData}
              columns={tableColumns}
              processRowUpdate={handleEditRow}
              onCellEditCommit={handleEditRow}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              components={{
                Toolbar: CustomToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              editMode="row"
              checkboxSelection
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
              }}
            />
            )}
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000} 
              onClose={handleSnackbarClose}
              message="Edition saved successfully!"
              action={
                <Button color="primary" size="small" onClick={handleSnackbarClose}>
                  Close
                </Button>
              }
            />
        </div>
    </div>
  );
};


export default withAuthenticator(MissesDayES);