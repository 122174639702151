import React, { useState, useEffect } from 'react';
import { Avatar, Button, Grid, Select, MenuItem, Typography } from '@mui/material';

import { useMemo } from "react";
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Snackbar, Alert, Chip } from '@mui/material';
import { Loader } from '@aws-amplify/ui-react';


const TopOffender = ({ data, loading }) => {
    const [mxsdData, setMxsdData] = useState([]);
    const [cancellationData, setCancellationData] = useState([]);
    const [lhBySellerData, setLhBySellerData] = useState([]);
    const [displayInterval, setDisplayInterval] = useState('day'); // default to day
    const sortedData = useMemo(() => {
      if (!data || data.length === 0) return [];
  
      const filteredAndSortedData = data
        .filter(item => 
          ['MXSD', 'CANCELLATION', 'LH_BY_SELLER'].includes(item.miss_type) &&
          (item.classification !== 'FST' || (item.classification === 'FST' && item.units > 5))
        );
  
      return filteredAndSortedData.sort((a, b) => {
        const dateComparison = displayInterval === 'day' ?
          new Date(b.date) - new Date(a.date) :
          new Date(b.week) - new Date(a.week);
  
        if (dateComparison !== 0) {
          return dateComparison;
        }
  
        const ordersComparison = b.orders - a.orders;
        if (ordersComparison !== 0) {
          return ordersComparison;
        }
  
        const classificationComparison = (a.classification || '').localeCompare(b.classification || '');

        // E substitua o bloco de comparação pelo seguinte:
        if (classificationComparison !== 0) {
          return classificationComparison;
        }
  
        const opsComparison = b.owner - a.owner;
        if (opsComparison !== 0) {
          return opsComparison;
        }
  
        return a.miss_type.localeCompare(b.miss_type);
      });
    }, [data, displayInterval]);

    useEffect(() => {
      console.log("Data:", data);
      if (data && data.length > 0) {
        const filteredAndSortedData = data
          .filter(item => 
            ['MXSD', 'CANCELLATION', 'LH_BY_SELLER'].includes(item.miss_type) &&
            (item.classification !== 'FST' || (item.classification === 'FST' && item.units > 5))
          );

    let sortedData;

    if (displayInterval === 'day') {
      sortedData = filteredAndSortedData.sort((a, b) => {
        // Sort by date column in descending order
        const dateComparison = new Date(b.date) - new Date(a.date);
        if (dateComparison !== 0) {
          return dateComparison;
        }

        const ordersComparison = b.orders - a.orders;
        if (ordersComparison !== 0) {
          return ordersComparison;
        }

        const classificationComparison = (a.classification || '').localeCompare(b.classification || '');

        // E substitua o bloco de comparação pelo seguinte:
        if (classificationComparison !== 0) {
          return classificationComparison;
        }

        const opsComparison = b.owner - a.owner;
        if (opsComparison !== 0) {
          return opsComparison;
        }

        return a.miss_type.localeCompare(b.miss_type);
      });
      } else if (displayInterval === 'week') {
      sortedData = filteredAndSortedData.sort((a, b) => {
        // Sort by date column in descending order
        const dateComparison = new Date(b.week) - new Date(a.week);
        if (dateComparison !== 0) {
          return dateComparison;
        }

        const ordersComparison = b.orders - a.orders;
        if (ordersComparison !== 0) {
          return ordersComparison;
        }

        const classificationComparison = (a.classification || '').localeCompare(b.classification || '');

        // E substitua o bloco de comparação pelo seguinte:
        if (classificationComparison !== 0) {
          return classificationComparison;
        }

        const opsComparison = b.owner - a.owner;
        if (opsComparison !== 0) {
          return opsComparison;
        }

        return a.miss_type.localeCompare(b.miss_type);
      });
    }
    
          const groupedData = sortedData.reduce((acc, item) => {
            const key = `${item.date}_${item.miss_type}_${item.classification}`;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          }, {});
      
          // Get the top 3 items for each date, miss_type, and classification
          const top3Data = Object.values(groupedData).map(items => items.slice(0, 3)).flat().map(item => ({ ...item, displayClassification: item.classification }));
      
          // Separate the data based on miss_type
          const mxsdData = top3Data.filter(item => item.miss_type === 'MXSD');
          const cancellationData = top3Data.filter(item => item.miss_type === 'CANCELLATION');
          const lhBySellerData = top3Data.filter(item => item.miss_type === 'LH_BY_SELLER');
    
        setMxsdData(mxsdData);
        setCancellationData(cancellationData);
        setLhBySellerData(lhBySellerData);
      }
    }, [sortedData]);  

    const tableColumns = [
      { field: 'mid', headerName: 'MCID', flex: 1,headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center' },
      { field: 'seller_name', headerName: 'Seller Name', flex: 1,headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center' },
      { 
        field: 'owner', 
        headerName: 'Ops Owner',
        flex: 1,
        headerClassName: 'customHeader boldHeader',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const opsOwner = params?.value;
          const opsOwnerName = opsOwner; 
      
          if (opsOwner) {
            return (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />

              </div>
            );
          }
      
          return null; 
        },
      },
      
      { field: 'date', headerName: 'Date', flex: 1, 
      valueFormatter: params => new Date(params?.value).toLocaleDateString(),
      headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center' },
      //{ field: 'week', headerName: 'Week', flex: 1,headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center' },
      { field: 'orders', headerName: 'Orders', flex: 1, headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center' },
      { field: 'displayClassification', headerName: 'Classification', flex: 1, headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center' },
    ];
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
    </GridToolbarContainer>
  );
  const dataDoDiaAnterior = new Date();
  dataDoDiaAnterior.setDate(dataDoDiaAnterior.getDate() - 1);
  
  const dia = dataDoDiaAnterior.getDate().toString().padStart(2, '0');
  const mes = (dataDoDiaAnterior.getMonth() + 1).toString().padStart(2, '0');
  const ano = dataDoDiaAnterior.getFullYear();
  
  const dataFormatada = `${ano}-${mes}-${dia}T00:00:00.000-03:00`;
  
  return (
        <div className="datatable" style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column',  }}>
          <Select
            value={displayInterval}
            onChange={(e) => setDisplayInterval(e.target.value)}
            sx={{ margin: '10px' }}
          >
            <MenuItem value="day">Day</MenuItem>
            <MenuItem value="week">Week</MenuItem>
          </Select>
          <Grid container spacing={8} direction="row">
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Typography
              sx={{
                marginTop: '2vh',
                marginLeft: 3,
                fontFamily: 'Source Sans Pro',
                fontSize: 'calc(15px + 0.5vh)',
                fontWeight: 'bold',
              }}
            >
              Mxsd Top Offenders
            </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)', 
              fontWeight: 'lighter',
            }}
          >
            
          </Typography>
          {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
              </div>
            </div>
            ) : (
              <DataGrid
                sx={{
                  marginTop: '2vh',
                  marginLeft: 2,
                  marginRight: 2,
                  padding: '10px 18px 18px 18px',
                  backgroundColor: "#FFFFFF",
                  border: '0px',
                  borderRadius: '10px',
                  minHeight: '30vh',
                  maxHeight: '90vh',
                  overflow: 'auto', // Enable vertical scrolling if needed
                }}
                rows={mxsdData}
                columns={tableColumns}
                components={{
                    Toolbar: CustomToolbar,
                }}
                disableColumnSelector
                disableDensitySelector
                initialState={{
                  pagination: { paginationModel: { pageSize: 15 } },

              }}    
              />
            )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Cancellation Top Offenders
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
            
          </Typography>
          {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
              </div>
            </div>
            ) : (
            <DataGrid
                sx={{
                    marginTop: '2vh',
                    marginLeft: 2,
                    marginRight: 2,
                    padding: '10px 18px 18px 18px',
                    backgroundColor: "#FFFFFF",
                    border: '0px',
                    borderRadius: '10px',
                    minHeight: '40vh',
                    maxHeight: '90vh'
                    ,overflow: 'auto', // Enable vertical scrolling if needed
                }}
                rows={cancellationData}
                columns={tableColumns}
                components={{
                    Toolbar: CustomToolbar,
                }}
                disableColumnSelector
                disableDensitySelector
                initialState={{
                  pagination: { paginationModel: { pageSize: 15 } },

              }}    
                />
            )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            LH BY Seller Top Offenders
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
          </Typography>
          {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
              </div>
            </div>
            ) : (
          <DataGrid
                sx={{
                    marginTop: '2vh',
                    marginLeft: 2,
                    marginRight: 2,
                    padding: '10px 18px 18px 18px',
                    backgroundColor: "#FFFFFF",
                    border: '0px',
                    borderRadius: '10px',
                    minHeight: '40vh',
                    maxHeight: '90vh',
                    overflow: 'auto', // Enable vertical scrolling if needed
                }}
                rows={lhBySellerData}
                columns={tableColumns}
                components={{
                    Toolbar: CustomToolbar,
                }}
                disableColumnSelector
                disableDensitySelector
                initialState={{
                  pagination: { paginationModel: { pageSize: 15 } },

              }}   
                />
            )}
            </Grid>
          </Grid>
        </div>
      );
    };
    
    export default TopOffender;
