import { Avatar } from '@mui/material';


// ------------------------------------------TABELA PARA ADMINS
export  const tableColumnsAdmin = [
    {
        field: "id",
        headerName: "MCID",
        flex: 1,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "seller_name",
        headerName: "Seller Name",
        flex: 1,
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
      field: "owner",
      headerName: "Ops Owner",
      headerClassName: 'customHeader',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
    
        return null; 
      },
    },
    {
        field: "backup_owner",
        headerName: "Backup Owner",
        headerClassName: 'customHeader',
        flex: 1,
        editable: true,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const opsOwner = params?.value;
      
          if (opsOwner) {
            return (
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
            );
          }
      
          return null; 
        },
    },
    {
        field: "launch_date",
        headerName: "Launch Date",
        flex: 1,  
        editable: true,
        valueFormatter: params => new Date(params?.value).toLocaleDateString(),
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "old_classification",
        headerName: "Old Classification",
        flex: 1,
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
    },  
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
    },            
    {
        field: "new_classification",
        headerName: "New Classification",
        flex: 1,
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },     
    {
        field: "sales_cohort",
        headerName: "Sales Cohort",
        flex: 1,
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },  
    {
        field: "nam",
        headerName: "Nam",
        flex: 1,
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "ship_method",
        headerName: "Ship Method",
        flex: 1,
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    }, 
    ];

    // ------------------------------------------TABELA PARA OPS
export  const tableColumnsOps = [
  {
      field: "id",
      headerName: "MCID",
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
      field: "seller_name",
      headerName: "Seller Name",
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
    field: "owner",
    headerName: "Ops Owner",
    headerClassName: 'customHeader',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const opsOwner = params?.value;
  
      if (opsOwner) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
            <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
          </div>
        );
      }
  
      return null; 
    },
  },
  {
      field: "backup_owner",
      headerName: "Backup Owner",
      headerClassName: 'customHeader',
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
          );
        }
    
        return null; 
      },
  },
  {
      field: "launch_date",
      headerName: "Launch Date",
      flex: 1,  
      editable: false,
      valueFormatter: params => new Date(params?.value).toLocaleDateString(),
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'customHeader',
  },  
  {
    field: "phone",
    headerName: "Phone",
    flex: 1,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'customHeader',
  },
  {
      field: "old_classification",
      headerName: "Old Classification",
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },            
  {
      field: "new_classification",
      headerName: "New Classification",
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },    
  {
      field: "sales_cohort",
      headerName: "Sales Cohort",
      flex: 1,
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },  
  {
      field: "nam",
      headerName: "Nam",
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
      field: "ship_method",
      headerName: "Ship Method",
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  }, 
  ];

// ------------------------------------------TABELA PARA OPS
export  const tableColumnsView = [
    {
        field: "id",
        headerName: "MCID",
        flex: 1,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "seller_name",
        headerName: "Seller Name",
        flex: 1,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
      field: "owner",
      headerName: "Ops Owner",
      headerClassName: 'customHeader',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
    
        return null; 
      },
    },
    {
        field: "backup_owner",
        headerName: "Backup Owner",
        headerClassName: 'customHeader',
        flex: 1,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const opsOwner = params?.value;
      
          if (opsOwner) {
            return (
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
            );
          }
      
          return null; 
        },
    },
    {
        field: "launch_date",
        headerName: "Launch Date",
        flex: 1,
        editable: false,
        valueFormatter: params => new Date(params?.value).toLocaleDateString(),
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "old_classification",
        headerName: "Old Classification",
        flex: 1,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },            
    {
        field: "new_classification",
        headerName: "New Classification",
        flex: 1,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },    
    {
        field: "sales_cohort",
        headerName: "Sales Cohort",
        flex: 1,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },  
    {
        field: "nam",
        headerName: "Nam",
        flex: 1,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "ship_method",
        headerName: "Ship Method",
        flex: 1,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    }, 
    ];