/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFbaSpoShipmentLevel = /* GraphQL */ `
  query GetFbaSpoShipmentLevel($id: ID!) {
    getFbaSpoShipmentLevel(id: $id) {
      id
      legacy_merchant_id
      seller_name
      seller_status
      is_fba_eligible
      fba_cohort
      program
      fba_launch_channel
      pf
      is_fba_managed
      alias_account_manager
      management_status
      arc_status
      po_id
      po_status
      shipment_id
      merchant_shipment_id
      merch_carrier_request_id
      shipment_create_date
      shipment_update_date
      ship_method
      has_invoice
      invoice_issued_date
      is_ship_valid_final
      carrier_received_manifest
      virtual_manifest_received_date
      manifest_status
      carrier
      ship_type
      expected_pickup_date
      was_ship_pickedup
      real_pickup_date
      was_ship_delivered_fc
      real_fc_receive_date_tex
      real_fc_receive_date_fc
      issue_with_pickup
      ship_undeliverable_date
      no_show_confirmed_date
      was_no_show
      packages_onhold
      fc_estimated_arrival_date
      fc_estimated_arrival_date_adjusted
      postal_code
      state
      city
      district
      commercial_locality
      address_status
      clear_address1_ft_flag
      destination_warehouse_id
      is_eligible_for_pcp
      expected_box_qty
      received_box_qty
      pickedup_box_qty
      uf
      coverage
      pcp_launched
      fba_launched
      tax_regime
      shipment_order
      delivery_date_adjusted
      edd_adjusted
      last_received_date_local
      last_event
      real_sla_transit_time
      arrived_fc_ontime
      expected_units
      received_units
      shipment_expected_gms
      shipment_received_gms
      untitledfield
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFbaSpoShipmentLevels = /* GraphQL */ `
  query ListFbaSpoShipmentLevels(
    $filter: ModelFbaSpoShipmentLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFbaSpoShipmentLevels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        legacy_merchant_id
        seller_name
        seller_status
        is_fba_eligible
        fba_cohort
        program
        fba_launch_channel
        pf
        is_fba_managed
        alias_account_manager
        management_status
        arc_status
        po_id
        po_status
        shipment_id
        merchant_shipment_id
        merch_carrier_request_id
        shipment_create_date
        shipment_update_date
        ship_method
        has_invoice
        invoice_issued_date
        is_ship_valid_final
        carrier_received_manifest
        virtual_manifest_received_date
        manifest_status
        carrier
        ship_type
        expected_pickup_date
        was_ship_pickedup
        real_pickup_date
        was_ship_delivered_fc
        real_fc_receive_date_tex
        real_fc_receive_date_fc
        issue_with_pickup
        ship_undeliverable_date
        no_show_confirmed_date
        was_no_show
        packages_onhold
        fc_estimated_arrival_date
        fc_estimated_arrival_date_adjusted
        postal_code
        state
        city
        district
        commercial_locality
        address_status
        clear_address1_ft_flag
        destination_warehouse_id
        is_eligible_for_pcp
        expected_box_qty
        received_box_qty
        pickedup_box_qty
        uf
        coverage
        pcp_launched
        fba_launched
        tax_regime
        shipment_order
        delivery_date_adjusted
        edd_adjusted
        last_received_date_local
        last_event
        real_sla_transit_time
        arrived_fc_ontime
        expected_units
        received_units
        shipment_expected_gms
        shipment_received_gms
        untitledfield
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFbaSpoShipmentLevels = /* GraphQL */ `
  query SyncFbaSpoShipmentLevels(
    $filter: ModelFbaSpoShipmentLevelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFbaSpoShipmentLevels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        legacy_merchant_id
        seller_name
        seller_status
        is_fba_eligible
        fba_cohort
        program
        fba_launch_channel
        pf
        is_fba_managed
        alias_account_manager
        management_status
        arc_status
        po_id
        po_status
        shipment_id
        merchant_shipment_id
        merch_carrier_request_id
        shipment_create_date
        shipment_update_date
        ship_method
        has_invoice
        invoice_issued_date
        is_ship_valid_final
        carrier_received_manifest
        virtual_manifest_received_date
        manifest_status
        carrier
        ship_type
        expected_pickup_date
        was_ship_pickedup
        real_pickup_date
        was_ship_delivered_fc
        real_fc_receive_date_tex
        real_fc_receive_date_fc
        issue_with_pickup
        ship_undeliverable_date
        no_show_confirmed_date
        was_no_show
        packages_onhold
        fc_estimated_arrival_date
        fc_estimated_arrival_date_adjusted
        postal_code
        state
        city
        district
        commercial_locality
        address_status
        clear_address1_ft_flag
        destination_warehouse_id
        is_eligible_for_pcp
        expected_box_qty
        received_box_qty
        pickedup_box_qty
        uf
        coverage
        pcp_launched
        fba_launched
        tax_regime
        shipment_order
        delivery_date_adjusted
        edd_adjusted
        last_received_date_local
        last_event
        real_sla_transit_time
        arrived_fc_ontime
        expected_units
        received_units
        shipment_expected_gms
        shipment_received_gms
        untitledfield
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVFSortability = /* GraphQL */ `
  query GetVFSortability($id: ID!) {
    getVFSortability(id: $id) {
      id
      warehouse_id
      expected_ship_date
      order_shipped_date
      status
      br_asin_sortability
      quantity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVFSortabilities = /* GraphQL */ `
  query ListVFSortabilities(
    $filter: ModelVFSortabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVFSortabilities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        warehouse_id
        expected_ship_date
        order_shipped_date
        status
        br_asin_sortability
        quantity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVFSortabilities = /* GraphQL */ `
  query SyncVFSortabilities(
    $filter: ModelVFSortabilityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVFSortabilities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        warehouse_id
        expected_ship_date
        order_shipped_date
        status
        br_asin_sortability
        quantity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVFOrderStatus = /* GraphQL */ `
  query GetVFOrderStatus($id: ID!) {
    getVFOrderStatus(id: $id) {
      id
      warehouse_code
      order_id
      customer_order_id
      status
      created_datetime
      expected_ship_date
      ship_method
      quantity
      invoiced_datetime
      confirmed_datetime
      packed_datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVFOrderStatuses = /* GraphQL */ `
  query ListVFOrderStatuses(
    $filter: ModelVFOrderStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVFOrderStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        warehouse_code
        order_id
        customer_order_id
        status
        created_datetime
        expected_ship_date
        ship_method
        quantity
        invoiced_datetime
        confirmed_datetime
        packed_datetime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVFOrderStatuses = /* GraphQL */ `
  query SyncVFOrderStatuses(
    $filter: ModelVFOrderStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVFOrderStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        warehouse_code
        order_id
        customer_order_id
        status
        created_datetime
        expected_ship_date
        ship_method
        quantity
        invoiced_datetime
        confirmed_datetime
        packed_datetime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPCPDefects = /* GraphQL */ `
  query GetPCPDefects($id: ID!) {
    getPCPDefects(id: $id) {
      id
      date_value
      merchant_id
      hdsc
      iss_inbounded
      iss_not_inbounded
      rpi
      prep
      shipped_units
      root_cause
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPCPDefects = /* GraphQL */ `
  query ListPCPDefects(
    $filter: ModelPCPDefectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPCPDefects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date_value
        merchant_id
        hdsc
        iss_inbounded
        iss_not_inbounded
        rpi
        prep
        shipped_units
        root_cause
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPCPDefects = /* GraphQL */ `
  query SyncPCPDefects(
    $filter: ModelPCPDefectsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPCPDefects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date_value
        merchant_id
        hdsc
        iss_inbounded
        iss_not_inbounded
        rpi
        prep
        shipped_units
        root_cause
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getESDailyOrderStatus = /* GraphQL */ `
  query GetESDailyOrderStatus($id: ID!) {
    getESDailyOrderStatus(id: $id) {
      id
      orderStatus
      orderCondition
      orderDate
      eventExecutionLog
      actualDeliveryDate
      estimatedShippingStartDate
      estimatedShippingEndDate
      promiseDeliveryStartDate
      promiseDeliveryEndDate
      packageDamagedEventDate
      packageLostEventDate
      packageRejectedEventDate
      packageReturnedToSellerEventDate
      deliveryStatus
      pickUpStatus
      actualScheduleDate
      estimatedScheduleEndDate
      estimatedScheduleStartDate
      actualPickUpDate
      customerId
      merchantId
      shipOption
      ops_owner
      seller_name
      orders
      backup_owner
      new_classification
      pending_orders
      scheduled
      phone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listESDailyOrderStatuses = /* GraphQL */ `
  query ListESDailyOrderStatuses(
    $filter: ModelESDailyOrderStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listESDailyOrderStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderStatus
        orderCondition
        orderDate
        eventExecutionLog
        actualDeliveryDate
        estimatedShippingStartDate
        estimatedShippingEndDate
        promiseDeliveryStartDate
        promiseDeliveryEndDate
        packageDamagedEventDate
        packageLostEventDate
        packageRejectedEventDate
        packageReturnedToSellerEventDate
        deliveryStatus
        pickUpStatus
        actualScheduleDate
        estimatedScheduleEndDate
        estimatedScheduleStartDate
        actualPickUpDate
        customerId
        merchantId
        shipOption
        ops_owner
        seller_name
        orders
        backup_owner
        new_classification
        pending_orders
        scheduled
        phone
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncESDailyOrderStatuses = /* GraphQL */ `
  query SyncESDailyOrderStatuses(
    $filter: ModelESDailyOrderStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncESDailyOrderStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderStatus
        orderCondition
        orderDate
        eventExecutionLog
        actualDeliveryDate
        estimatedShippingStartDate
        estimatedShippingEndDate
        promiseDeliveryStartDate
        promiseDeliveryEndDate
        packageDamagedEventDate
        packageLostEventDate
        packageRejectedEventDate
        packageReturnedToSellerEventDate
        deliveryStatus
        pickUpStatus
        actualScheduleDate
        estimatedScheduleEndDate
        estimatedScheduleStartDate
        actualPickUpDate
        customerId
        merchantId
        shipOption
        ops_owner
        seller_name
        orders
        backup_owner
        new_classification
        pending_orders
        scheduled
        phone
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEasyShipOrderStatus2 = /* GraphQL */ `
  query GetEasyShipOrderStatus2($id: ID!) {
    getEasyShipOrderStatus2(id: $id) {
      id
      order_status
      order_condition
      order_date
      event_execution_log
      actual_delivery_date
      estimated_shipping_start_date
      estimated_shipping_end_date
      promise_delivery_start_date
      promise_delivery_end_date
      package_damaged_event_date
      package_lost_event_date
      package_rejected_event_date
      package_returned_to_seller_event_date
      delivery_status
      pick_up_status
      actual_schedule_date
      estimated_schedule_end_date
      estimated_schedule_start_date
      actual_pick_up_date
      customer_id
      merchant_id
      ship_option
      ops_owner
      seller_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEasyShipOrderStatus2s = /* GraphQL */ `
  query ListEasyShipOrderStatus2s(
    $filter: ModelEasyShipOrderStatus2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEasyShipOrderStatus2s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        order_status
        order_condition
        order_date
        event_execution_log
        actual_delivery_date
        estimated_shipping_start_date
        estimated_shipping_end_date
        promise_delivery_start_date
        promise_delivery_end_date
        package_damaged_event_date
        package_lost_event_date
        package_rejected_event_date
        package_returned_to_seller_event_date
        delivery_status
        pick_up_status
        actual_schedule_date
        estimated_schedule_end_date
        estimated_schedule_start_date
        actual_pick_up_date
        customer_id
        merchant_id
        ship_option
        ops_owner
        seller_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEasyShipOrderStatus2s = /* GraphQL */ `
  query SyncEasyShipOrderStatus2s(
    $filter: ModelEasyShipOrderStatus2FilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEasyShipOrderStatus2s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        order_status
        order_condition
        order_date
        event_execution_log
        actual_delivery_date
        estimated_shipping_start_date
        estimated_shipping_end_date
        promise_delivery_start_date
        promise_delivery_end_date
        package_damaged_event_date
        package_lost_event_date
        package_rejected_event_date
        package_returned_to_seller_event_date
        delivery_status
        pick_up_status
        actual_schedule_date
        estimated_schedule_end_date
        estimated_schedule_start_date
        actual_pick_up_date
        customer_id
        merchant_id
        ship_option
        ops_owner
        seller_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEasyShipSellerOrderStatusFromPickup = /* GraphQL */ `
  query GetEasyShipSellerOrderStatusFromPickup($id: ID!) {
    getEasyShipSellerOrderStatusFromPickup(id: $id) {
      id
      orderStatus
      orderCondition
      orderDate
      eventExecutionLog
      actualDeliveryDate
      estimatedShippingStartDate
      estimatedShippingEndDate
      promiseDeliveryStartDate
      promiseDeliveryEndDate
      packageDamagedEventDate
      packageLostEventDate
      packageRejectedEventDate
      packageReturnedToSellerEventDate
      deliveryStatus
      pickUpStatus
      actualScheduleDate
      estimatedScheduleEndDate
      estimatedScheduleStartDate
      actualPickUpDate
      customerId
      merchantId
      shipOption
      ops_owner
      seller_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEasyShipSellerOrderStatusFromPickups = /* GraphQL */ `
  query ListEasyShipSellerOrderStatusFromPickups(
    $filter: ModelEasyShipSellerOrderStatusFromPickupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEasyShipSellerOrderStatusFromPickups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderStatus
        orderCondition
        orderDate
        eventExecutionLog
        actualDeliveryDate
        estimatedShippingStartDate
        estimatedShippingEndDate
        promiseDeliveryStartDate
        promiseDeliveryEndDate
        packageDamagedEventDate
        packageLostEventDate
        packageRejectedEventDate
        packageReturnedToSellerEventDate
        deliveryStatus
        pickUpStatus
        actualScheduleDate
        estimatedScheduleEndDate
        estimatedScheduleStartDate
        actualPickUpDate
        customerId
        merchantId
        shipOption
        ops_owner
        seller_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEasyShipSellerOrderStatusFromPickups = /* GraphQL */ `
  query SyncEasyShipSellerOrderStatusFromPickups(
    $filter: ModelEasyShipSellerOrderStatusFromPickupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEasyShipSellerOrderStatusFromPickups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderStatus
        orderCondition
        orderDate
        eventExecutionLog
        actualDeliveryDate
        estimatedShippingStartDate
        estimatedShippingEndDate
        promiseDeliveryStartDate
        promiseDeliveryEndDate
        packageDamagedEventDate
        packageLostEventDate
        packageRejectedEventDate
        packageReturnedToSellerEventDate
        deliveryStatus
        pickUpStatus
        actualScheduleDate
        estimatedScheduleEndDate
        estimatedScheduleStartDate
        actualPickUpDate
        customerId
        merchantId
        shipOption
        ops_owner
        seller_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEasyShipOrderStatusTest = /* GraphQL */ `
  query GetEasyShipOrderStatusTest($id: ID!) {
    getEasyShipOrderStatusTest(id: $id) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEasyShipOrderStatusTests = /* GraphQL */ `
  query ListEasyShipOrderStatusTests(
    $filter: ModelEasyShipOrderStatusTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEasyShipOrderStatusTests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEasyShipOrderStatusTests = /* GraphQL */ `
  query SyncEasyShipOrderStatusTests(
    $filter: ModelEasyShipOrderStatusTestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEasyShipOrderStatusTests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEasyShipOrderStatus = /* GraphQL */ `
  query GetEasyShipOrderStatus($id: ID!) {
    getEasyShipOrderStatus(id: $id) {
      id
      orderStatus
      orderCondition
      orderDate
      eventExecutionLog
      actualDeliveryDate
      estimatedShippingStartDate
      estimatedShippingEndDate
      promiseDeliveryStartDate
      promiseDeliveryEndDate
      packageDamagedEventDate
      packageLostEventDate
      packageRejectedEventDate
      packageReturnedToSellerEventDate
      deliveryStatus
      pickUpStatus
      actualScheduleDate
      estimatedScheduleEndDate
      estimatedScheduleStartDate
      actualPickUpDate
      customerId
      merchantId
      shipOption
      ops_owner
      seller_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEasyShipOrderStatuses = /* GraphQL */ `
  query ListEasyShipOrderStatuses(
    $filter: ModelEasyShipOrderStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEasyShipOrderStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderStatus
        orderCondition
        orderDate
        eventExecutionLog
        actualDeliveryDate
        estimatedShippingStartDate
        estimatedShippingEndDate
        promiseDeliveryStartDate
        promiseDeliveryEndDate
        packageDamagedEventDate
        packageLostEventDate
        packageRejectedEventDate
        packageReturnedToSellerEventDate
        deliveryStatus
        pickUpStatus
        actualScheduleDate
        estimatedScheduleEndDate
        estimatedScheduleStartDate
        actualPickUpDate
        customerId
        merchantId
        shipOption
        ops_owner
        seller_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEasyShipOrderStatuses = /* GraphQL */ `
  query SyncEasyShipOrderStatuses(
    $filter: ModelEasyShipOrderStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEasyShipOrderStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderStatus
        orderCondition
        orderDate
        eventExecutionLog
        actualDeliveryDate
        estimatedShippingStartDate
        estimatedShippingEndDate
        promiseDeliveryStartDate
        promiseDeliveryEndDate
        packageDamagedEventDate
        packageLostEventDate
        packageRejectedEventDate
        packageReturnedToSellerEventDate
        deliveryStatus
        pickUpStatus
        actualScheduleDate
        estimatedScheduleEndDate
        estimatedScheduleStartDate
        actualPickUpDate
        customerId
        merchantId
        shipOption
        ops_owner
        seller_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getForecastSF = /* GraphQL */ `
  query GetForecastSF($id: ID!) {
    getForecastSF(id: $id) {
      id
      seller_id
      seller_name
      ops_owner
      forecast
      date
      backlog
      capacity
      cpt_hour
      node
      forecast_pkgs
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listForecastSFS = /* GraphQL */ `
  query ListForecastSFS(
    $filter: ModelForecastSFFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForecastSFS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        seller_id
        seller_name
        ops_owner
        forecast
        date
        backlog
        capacity
        cpt_hour
        node
        forecast_pkgs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncForecastSFS = /* GraphQL */ `
  query SyncForecastSFS(
    $filter: ModelForecastSFFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncForecastSFS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        seller_id
        seller_name
        ops_owner
        forecast
        date
        backlog
        capacity
        cpt_hour
        node
        forecast_pkgs
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPCPPreWBR = /* GraphQL */ `
  query GetPCPPreWBR($id: ID!) {
    getPCPPreWBR(id: $id) {
      id
      expected_ship_date
      merchant_id
      total
      missed_units
      reason
      edited_by
      self_report_survey
      fdps
      amazon_comments
      miss_type
      miss_type_seller
      subreason_seller
      reason_seller
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPCPPreWBRS = /* GraphQL */ `
  query ListPCPPreWBRS(
    $filter: ModelPCPPreWBRFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPCPPreWBRS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        expected_ship_date
        merchant_id
        total
        missed_units
        reason
        edited_by
        self_report_survey
        fdps
        amazon_comments
        miss_type
        miss_type_seller
        subreason_seller
        reason_seller
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPCPPreWBRS = /* GraphQL */ `
  query SyncPCPPreWBRS(
    $filter: ModelPCPPreWBRFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPCPPreWBRS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        expected_ship_date
        merchant_id
        total
        missed_units
        reason
        edited_by
        self_report_survey
        fdps
        amazon_comments
        miss_type
        miss_type_seller
        subreason_seller
        reason_seller
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSellerDataPCP2 = /* GraphQL */ `
  query GetSellerDataPCP2($id: ID!) {
    getSellerDataPCP2(id: $id) {
      id
      seller_id
      rank
      seller_name
      on_time
      second_attempt
      no_show
      cancelled
      seller_miss
      seller_miss_perc
      metrics_impact_perc
      business_relevance
      classification
      ops_support
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSellerDataPCP2s = /* GraphQL */ `
  query ListSellerDataPCP2s(
    $filter: ModelSellerDataPCP2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellerDataPCP2s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        seller_id
        rank
        seller_name
        on_time
        second_attempt
        no_show
        cancelled
        seller_miss
        seller_miss_perc
        metrics_impact_perc
        business_relevance
        classification
        ops_support
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSellerDataPCP2s = /* GraphQL */ `
  query SyncSellerDataPCP2s(
    $filter: ModelSellerDataPCP2FilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSellerDataPCP2s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        seller_id
        rank
        seller_name
        on_time
        second_attempt
        no_show
        cancelled
        seller_miss
        seller_miss_perc
        metrics_impact_perc
        business_relevance
        classification
        ops_support
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSummaryPCP = /* GraphQL */ `
  query GetSummaryPCP($id: ID!) {
    getSummaryPCP(id: $id) {
      id
      merchant_id
      tracking_id
      shipment_id
      fdps
      total
      reason
      expected_ship_date
      actual_pick_up_date
      amazon_comments
      miss_type
      reason_seller
      subreason_seller
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSummaryPCPS = /* GraphQL */ `
  query ListSummaryPCPS(
    $filter: ModelSummaryPCPFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSummaryPCPS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        merchant_id
        tracking_id
        shipment_id
        fdps
        total
        reason
        expected_ship_date
        actual_pick_up_date
        amazon_comments
        miss_type
        reason_seller
        subreason_seller
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSummaryPCPS = /* GraphQL */ `
  query SyncSummaryPCPS(
    $filter: ModelSummaryPCPFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSummaryPCPS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        merchant_id
        tracking_id
        shipment_id
        fdps
        total
        reason
        expected_ship_date
        actual_pick_up_date
        amazon_comments
        miss_type
        reason_seller
        subreason_seller
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSellerDataFBA = /* GraphQL */ `
  query GetSellerDataFBA($id: ID!) {
    getSellerDataFBA(id: $id) {
      id
      email
      phone_nft
      phone_comercial
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSellerDataFBAS = /* GraphQL */ `
  query ListSellerDataFBAS(
    $filter: ModelSellerDataFBAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellerDataFBAS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        phone_nft
        phone_comercial
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSellerDataFBAS = /* GraphQL */ `
  query SyncSellerDataFBAS(
    $filter: ModelSellerDataFBAFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSellerDataFBAS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        phone_nft
        phone_comercial
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSFCPTManagement = /* GraphQL */ `
  query GetSFCPTManagement($id: ID!) {
    getSFCPTManagement(id: $id) {
      id
      node
      shipment_id_missing_invoice
      order_id_missing_invoice
      shipment_id_invoice_ok
      exsd_timestamp
      last_updated
      cpt_date
      cpt_hour
      pending_shipments
      manifested_shipments
      shipments_today
      packed_shipments
      picked_shipments
      new_shipments
      pending_invoice
      SellerDataSF {
        node
        seller_name
        backup_owner
        capacity
        certificate
        classification
        efpm_delay
        efpm_ft_delay
        email1
        email2
        email3
        erp
        ft_percentage
        integrator
        is_node_active_fos
        is_temporary
        latest_cpt
        launch_date
        marketplace
        merchant_id
        nam
        ops_owner
        phone1
        phone2
        phone3
        return_rate_target
        seller_poc
        sprint
        capacity_update_request
        location_city
        location_address
        location_state
        is_active
        user_edition
        relaunch_seller
        wpp_group
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      seller_name
      ops_owner
      backup_owner
      phone1
      phone2
      nam
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sFCPTManagementSellerDataSFId
      __typename
    }
  }
`;
export const listSFCPTManagements = /* GraphQL */ `
  query ListSFCPTManagements(
    $filter: ModelSFCPTManagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFCPTManagements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        node
        shipment_id_missing_invoice
        order_id_missing_invoice
        shipment_id_invoice_ok
        exsd_timestamp
        last_updated
        cpt_date
        cpt_hour
        pending_shipments
        manifested_shipments
        shipments_today
        packed_shipments
        picked_shipments
        new_shipments
        pending_invoice
        seller_name
        ops_owner
        backup_owner
        phone1
        phone2
        nam
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sFCPTManagementSellerDataSFId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSFCPTManagements = /* GraphQL */ `
  query SyncSFCPTManagements(
    $filter: ModelSFCPTManagementFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSFCPTManagements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        node
        shipment_id_missing_invoice
        order_id_missing_invoice
        shipment_id_invoice_ok
        exsd_timestamp
        last_updated
        cpt_date
        cpt_hour
        pending_shipments
        manifested_shipments
        shipments_today
        packed_shipments
        picked_shipments
        new_shipments
        pending_invoice
        seller_name
        ops_owner
        backup_owner
        phone1
        phone2
        nam
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sFCPTManagementSellerDataSFId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSummaryVF = /* GraphQL */ `
  query GetSummaryVF($id: ID!) {
    getSummaryVF(id: $id) {
      id
      month
      calendar_year
      units_amzl
      total_units
      total_packages
      total_shipments
      su_amzl_perc
      su_share_vs_network
      ups
      gas_tank_vol
      woc
      return_qtd
      ship_to
      vlt_net
      vlt_vb
      fr_vb
      fr_net
      units_net
      units_xcv9
      ship_day
      vlt
      received_units
      ce_miss
      cancel_qtd
      po_qty_submitted
      po_qty_received
      po_qty_open
      avg_vlt_sum
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSummaryVFS = /* GraphQL */ `
  query ListSummaryVFS(
    $filter: ModelSummaryVFFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSummaryVFS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        month
        calendar_year
        units_amzl
        total_units
        total_packages
        total_shipments
        su_amzl_perc
        su_share_vs_network
        ups
        gas_tank_vol
        woc
        return_qtd
        ship_to
        vlt_net
        vlt_vb
        fr_vb
        fr_net
        units_net
        units_xcv9
        ship_day
        vlt
        received_units
        ce_miss
        cancel_qtd
        po_qty_submitted
        po_qty_received
        po_qty_open
        avg_vlt_sum
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSummaryVFS = /* GraphQL */ `
  query SyncSummaryVFS(
    $filter: ModelSummaryVFFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSummaryVFS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        month
        calendar_year
        units_amzl
        total_units
        total_packages
        total_shipments
        su_amzl_perc
        su_share_vs_network
        ups
        gas_tank_vol
        woc
        return_qtd
        ship_to
        vlt_net
        vlt_vb
        fr_vb
        fr_net
        units_net
        units_xcv9
        ship_day
        vlt
        received_units
        ce_miss
        cancel_qtd
        po_qty_submitted
        po_qty_received
        po_qty_open
        avg_vlt_sum
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSummaryES = /* GraphQL */ `
  query GetSummaryES($id: ID!) {
    getSummaryES(id: $id) {
      id
      owner
      week
      date
      classification
      mexsd_re_f
      cr
      late_handover
      orders
      lsr_re_f
      general_lsr
      dropoff_issue_re
      pickup_issue_re
      evaluation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSummaryES = /* GraphQL */ `
  query ListSummaryES(
    $filter: ModelSummaryESFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSummaryES(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        week
        date
        classification
        mexsd_re_f
        cr
        late_handover
        orders
        lsr_re_f
        general_lsr
        dropoff_issue_re
        pickup_issue_re
        evaluation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSummaryES = /* GraphQL */ `
  query SyncSummaryES(
    $filter: ModelSummaryESFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSummaryES(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        week
        date
        classification
        mexsd_re_f
        cr
        late_handover
        orders
        lsr_re_f
        general_lsr
        dropoff_issue_re
        pickup_issue_re
        evaluation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSellerDataES = /* GraphQL */ `
  query GetSellerDataES($id: ID!) {
    getSellerDataES(id: $id) {
      id
      seller_name
      launch_date
      email
      phone
      owner
      backup_owner
      old_classification
      new_classification
      sales_cohort
      nam
      integrador
      ship_method
      mcid
      user_edition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSellerDataES = /* GraphQL */ `
  query ListSellerDataES(
    $filter: ModelSellerDataESFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellerDataES(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        seller_name
        launch_date
        email
        phone
        owner
        backup_owner
        old_classification
        new_classification
        sales_cohort
        nam
        integrador
        ship_method
        mcid
        user_edition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSellerDataES = /* GraphQL */ `
  query SyncSellerDataES(
    $filter: ModelSellerDataESFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSellerDataES(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        seller_name
        launch_date
        email
        phone
        owner
        backup_owner
        old_classification
        new_classification
        sales_cohort
        nam
        integrador
        ship_method
        mcid
        user_edition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMissesEasyShip = /* GraphQL */ `
  query GetMissesEasyShip($id: ID!) {
    getMissesEasyShip(id: $id) {
      id
      week
      owner
      seller_name
      date
      mid
      miss_type
      orders
      reason
      sub_reason
      comments
      pod
      poa
      remove_from_pm
      self_report
      ticket
      classification
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMissesEasyShips = /* GraphQL */ `
  query ListMissesEasyShips(
    $filter: ModelMissesEasyShipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMissesEasyShips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        week
        owner
        seller_name
        date
        mid
        miss_type
        orders
        reason
        sub_reason
        comments
        pod
        poa
        remove_from_pm
        self_report
        ticket
        classification
        user_edition
        order_id_miss
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMissesEasyShips = /* GraphQL */ `
  query SyncMissesEasyShips(
    $filter: ModelMissesEasyShipFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMissesEasyShips(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        week
        owner
        seller_name
        date
        mid
        miss_type
        orders
        reason
        sub_reason
        comments
        pod
        poa
        remove_from_pm
        self_report
        ticket
        classification
        user_edition
        order_id_miss
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMQBandQBR = /* GraphQL */ `
  query GetMQBandQBR($id: ID!) {
    getMQBandQBR(id: $id) {
      id
      su_q2
      su_q3
      su_q4
      active_nodes
      su_quarter
      lsr
      scr
      su_month
      su_week
      week
      wow
      su_q1
      fdps
      lsr_perc
      scr_perc
      fdps_perc
      lsr_wow
      scr_wow
      fdps_wow
      ft_share
      scr_controllable_wow
      scr_controllable_perc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMQBandQBRS = /* GraphQL */ `
  query ListMQBandQBRS(
    $filter: ModelMQBandQBRFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMQBandQBRS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        su_q2
        su_q3
        su_q4
        active_nodes
        su_quarter
        lsr
        scr
        su_month
        su_week
        week
        wow
        su_q1
        fdps
        lsr_perc
        scr_perc
        fdps_perc
        lsr_wow
        scr_wow
        fdps_wow
        ft_share
        scr_controllable_wow
        scr_controllable_perc
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMQBandQBRS = /* GraphQL */ `
  query SyncMQBandQBRS(
    $filter: ModelMQBandQBRFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMQBandQBRS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        su_q2
        su_q3
        su_q4
        active_nodes
        su_quarter
        lsr
        scr
        su_month
        su_week
        week
        wow
        su_q1
        fdps
        lsr_perc
        scr_perc
        fdps_perc
        lsr_wow
        scr_wow
        fdps_wow
        ft_share
        scr_controllable_wow
        scr_controllable_perc
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMisses = /* GraphQL */ `
  query GetMisses($id: ID!) {
    getMisses(id: $id) {
      id
      week
      ops_owner
      seller_name
      date
      node
      miss_type
      units
      reason
      subreason
      remove_from_pm
      comments
      self_report
      pod
      poa
      ticket
      classification
      carrier
      tracking_id
      recurrence
      su_week
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMisses = /* GraphQL */ `
  query ListMisses(
    $filter: ModelMissesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMisses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        week
        ops_owner
        seller_name
        date
        node
        miss_type
        units
        reason
        subreason
        remove_from_pm
        comments
        self_report
        pod
        poa
        ticket
        classification
        carrier
        tracking_id
        recurrence
        su_week
        user_edition
        order_id_miss
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMisses = /* GraphQL */ `
  query SyncMisses(
    $filter: ModelMissesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMisses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        week
        ops_owner
        seller_name
        date
        node
        miss_type
        units
        reason
        subreason
        remove_from_pm
        comments
        self_report
        pod
        poa
        ticket
        classification
        carrier
        tracking_id
        recurrence
        su_week
        user_edition
        order_id_miss
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSellerDataSF = /* GraphQL */ `
  query GetSellerDataSF($id: ID!) {
    getSellerDataSF(id: $id) {
      node
      seller_name
      backup_owner
      capacity
      certificate
      classification
      efpm_delay
      efpm_ft_delay
      email1
      email2
      email3
      erp
      ft_percentage
      integrator
      is_node_active_fos
      is_temporary
      latest_cpt
      launch_date
      marketplace
      merchant_id
      nam
      ops_owner
      phone1
      phone2
      phone3
      return_rate_target
      seller_poc
      sprint
      capacity_update_request
      location_city
      location_address
      location_state
      is_active
      user_edition
      relaunch_seller
      wpp_group
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSellerDataSFS = /* GraphQL */ `
  query ListSellerDataSFS(
    $filter: ModelSellerDataSFFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellerDataSFS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        node
        seller_name
        backup_owner
        capacity
        certificate
        classification
        efpm_delay
        efpm_ft_delay
        email1
        email2
        email3
        erp
        ft_percentage
        integrator
        is_node_active_fos
        is_temporary
        latest_cpt
        launch_date
        marketplace
        merchant_id
        nam
        ops_owner
        phone1
        phone2
        phone3
        return_rate_target
        seller_poc
        sprint
        capacity_update_request
        location_city
        location_address
        location_state
        is_active
        user_edition
        relaunch_seller
        wpp_group
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSellerDataSFS = /* GraphQL */ `
  query SyncSellerDataSFS(
    $filter: ModelSellerDataSFFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSellerDataSFS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        node
        seller_name
        backup_owner
        capacity
        certificate
        classification
        efpm_delay
        efpm_ft_delay
        email1
        email2
        email3
        erp
        ft_percentage
        integrator
        is_node_active_fos
        is_temporary
        latest_cpt
        launch_date
        marketplace
        merchant_id
        nam
        ops_owner
        phone1
        phone2
        phone3
        return_rate_target
        seller_poc
        sprint
        capacity_update_request
        location_city
        location_address
        location_state
        is_active
        user_edition
        relaunch_seller
        wpp_group
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
