import { listESDailyOrderStatuses } from '../../../graphql/queries';
import { onCreateSFCPTManagement, onUpdateSFCPTManagement, onDeleteSFCPTManagement } from '../../../graphql/subscriptions';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { API, Auth, graphqlOperation  } from 'aws-amplify';

import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid } from '@mui/material';
import { Loader } from '@aws-amplify/ui-react';
import moment from 'moment-timezone';

function PostList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const fetchData = async () => {
    try {
      setLoading(true);
      const today = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1); // Add 1 day to the current date
      const tomorrowDate = tomorrow.toISOString().slice(0, 10); // Get the date in YYYY-MM-DD format
      
      console.log(tomorrowDate);
      let items = [];
      let nextToken = null;
      const limit = 40000000; // Fetch data in smaller chunks
      do {
        const response = await API.graphql(
          graphqlOperation(listESDailyOrderStatuses, {
            limit,
            nextToken,
            filter: {
              or: [
                {
                  estimatedScheduleEndDate: {
                    beginsWith: `${today} `, // Filter for dates starting with today's date
                  },
                },
                {
                  estimatedScheduleEndDate: {
                    beginsWith: `${tomorrowDate} `, // Filter for dates starting with tomorrow's date
                  },
                },
              ],
            },
          })
        );
        const fetchedItems = response.data.listESDailyOrderStatuses.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listESDailyOrderStatuses.nextToken;
      } while (nextToken);
  
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
      try {
        fetchData();
      } catch (error) {
        console.error('Error fetching user group information:', error);
      }

  }, []);

  const sortedData = useMemo(() => {
    const currentDate = new Date();

    // Calculate yesterday's date
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);

    const filteredItems = data.filter(item => 
      new Date(item.estimatedScheduleEndDate) >= yesterday
    );
    const sortedItems = filteredItems.sort((a, b) => {
      const dateComparison = new Date(b.estimatedScheduleEndDate) - new Date(a.estimatedScheduleEndDate);
      if (dateComparison !== 0) {
        return dateComparison;
      }
      return b.orders - a.orders;
    });

    return sortedItems;
  }, [data]);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarQuickFilter debounceMs={500} />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
  
  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={6} md={6}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
          ES - Seller DAILY Order Status Monitoring - backup plan
          { loading && <Loader size="large" sx={{ marginLeft: '40px' }} /> }
          </Typography>
        </Grid>
        <Grid item lg={6} xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
    
            <Button variant="contained" color="primary" onClick={fetchData}>
              Refresh Data
            </Button>
        
        </Grid>
      </Grid>
      <Typography
        sx={{
          marginTop: 0,
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(7px + 0.5vh)',
          fontWeight: 'lighter',
        }}
      >
        Order Status Monitoring - (expect some delay)
      </Typography>

        <DataGrid
          sx={{
            marginTop: '2vh',
            marginLeft: 1,
            marginRight: 2,
            padding: '10px 18px 18px 18px',
            backgroundColor: "#FFFFFF",
            border: '1px solid #e0e0e0',
            borderRadius: '10px',
            minHeight: '40vh',
            maxHeight: '90vh',
            overflow: 'auto', // Enable vertical scrolling if needed
          }}
          rows={sortedData}
          columns={[
            { field: 'merchantId', headerName: 'merchant_id', flex: 1 },
            { field: 'ops_owner', headerName: 'ops_owner', flex: 1, align: 'center', headerAlign: 'center' },
            { field: 'seller_name', headerName: 'seller_name', flex: 1, align: 'center', headerAlign: 'center' },
            { field: 'orderStatus', headerName: 'order_status', flex: 1, align: 'center', headerAlign: 'center' },
            //{ field: 'estimatedScheduleEndDate', headerName: 'estimatedScheduleEndDate', flex: 1, align: 'center', headerAlign: 'center' },
            {
              field: 'estimatedScheduleEndDate',
              headerName: 'estimatedScheduleEndDate',
              flex: 1,
              align: 'center',
              headerAlign: 'center',
              valueGetter: (params: GridValueGetterParams) => {
                const estimatedScheduleEndDate = params.row.estimatedScheduleEndDate;
                const saoPauloTimezone = moment(estimatedScheduleEndDate)
                  .subtract(3, 'hours')
                  .format('YYYY-MM-DD HH:mm:ss');
                return saoPauloTimezone;
              },
            },
            { field: 'orders', headerName: 'orders', flex: 1, align: 'center', headerAlign: 'center' },

          ]}
          components={{
            Toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          editMode="row"
          disableColumnSelector
          disableDensitySelector
          initialState={{
            pagination: { pageSize: 100 },
            sortModel: [{ field: 'deviation', sort: 'asc' }],
          }}
        />

    </div>
  );

}
export default PostList;

