import { listVFSortabilities } from '../../../graphql/queries';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { API, Auth, graphqlOperation  } from 'aws-amplify';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, LineChart, Line } from 'recharts';

import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid } from '@mui/material';
import { Loader } from '@aws-amplify/ui-react';
import moment from 'moment-timezone';

const PostList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const fetchData = async () => {
    try {
      setLoading(true);
      const today = new Date();
      const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
  
      let startDate, endDate;
      if (dayOfWeek === 1) { // Monday
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 3);
        endDate = new Date();
        endDate.setDate(endDate.getDate() - 2);
        console.log('final',endDate)
      } else {
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        endDate = new Date();
        endDate.setDate(endDate.getDate()+1);
        console.log('final',endDate)
      }
  
      const startDateStr = startDate.toISOString().slice(0, 10);
      const endDateStr = endDate.toISOString().slice(0, 10);
  
      let items = [];
      let nextToken = null;
      const limit = 40000000; // Fetch data in smaller chunks
      do {
        const response = await API.graphql(
          graphqlOperation(listVFSortabilities, {
            limit,
            nextToken,
            filter: {
              expected_ship_date: {
                gt: startDateStr, // Filter for dates greater than yesterday's date
                lt: endDateStr, // Filter for dates less than tomorrow's date
              },
            },
          })
        );
        const fetchedItems = response.data.listVFSortabilities.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listVFSortabilities.nextToken;
      } while (nextToken);
  
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
      try {
        fetchData();
      } catch (error) {
        console.error('Error fetching user group information:', error);
      }

  }, []);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarQuickFilter debounceMs={500} />
      <GridToolbarExport />
    </GridToolbarContainer>
  );


  const sortedData_table = useMemo(() => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    let startDate, endDate;
      if (dayOfWeek === 1) { // Monday
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 4);
        endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
      } else {
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        endDate = new Date();
        endDate.setDate(endDate.getDate());
      }
  
    const filteredItems = data.filter(item =>
      new Date(item.expected_ship_date) >= startDate &&
      new Date(item.expected_ship_date) < endDate
    );
  
    // Group the data by expected_ship_date, warehouse_code, and br_asin_sortability
    const groupedData_2 = filteredItems.reduce((acc, item) => {
      const key = `${item.expected_ship_date}-${item.warehouse_id}-${item.br_asin_sortability}`;
      if (!acc[key]) {
        acc[key] = {
          id: key,
          br_asin_sortability: item.br_asin_sortability,
          expected_ship_date: item.expected_ship_date,
          warehouse_id: item.warehouse_id,
          processed: 0,
          not_processed: 0,
          totalOrders: 0,
          totalOrders_processed: 0,
          backlog_processed: 0,
        };
      }
  
      // Determine the most recent status and update the corresponding column
      if (item.status === 'INVOICE_SUBMITTED') {
        acc[key].processed += item.quantity;
        acc[key].backlog_processed += item.quantity;
        acc[key].totalOrders_processed += item.quantity;
      } else if (item.status === 'PACKED') {
        acc[key].processed += item.quantity;
        acc[key].backlog_processed += item.quantity;
        acc[key].totalOrders_processed += item.quantity;
      } else if (item.status === 'CONFIRMED') {
        acc[key].not_processed += item.quantity;
      } else if (item.status === 'NEW') {
        acc[key].not_processed += item.quantity;
      }
  
      acc[key].totalOrders += item.quantity;
  
      return acc;
    }, {});
  
    // Convert the grouped data to an array and sort it
    const sortedItems2 = Object.values(groupedData_2).sort((a, b) => {
      const dateComparison = new Date(b.expected_ship_date) - new Date(a.expected_ship_date);
      if (dateComparison !== 0) {
        return dateComparison;
      }
      return b.totalOrders_processed - a.totalOrders_processed;
    });
  
    // Calculate the processed percentage for each group
    sortedItems2.forEach(item => {
      item.processedperc = item.totalOrders > 0 ? item.processed / item.totalOrders : 0;
    });
  
    return sortedItems2;
  }, [data]);
  
  const lineChartData = useMemo(() => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    let startDate, endDate;
      if (dayOfWeek === 1) { // Monday
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 4);
        endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
      } else {
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        endDate = new Date();
        endDate.setDate(endDate.getDate());
      }
  
    const filteredItems = data.filter(item =>
      new Date(item.expected_ship_date) >= startDate &&
      new Date(item.expected_ship_date) < endDate &&
      item.br_asin_sortability === 'sortable'
    );
  
    const lineChartData = {
      'sortable': [],
    };
  
    filteredItems.forEach((item) => {
      const orderShippedDate = new Date(item.order_shipped_date);
      lineChartData['sortable'].push({
        order_shipped_hour: orderShippedDate.getHours(),
        quantity: item.status === 'INVOICE_SUBMITTED' || item.status === 'PACKED' ? item.quantity : 0,
      });
    });
  
    lineChartData['sortable'].sort((a, b) => a.order_shipped_hour - b.order_shipped_hour);
  
    const summedData = lineChartData['sortable'].reduce((acc, curr) => {
      const existingItem = acc.find(
        (item) => item.order_shipped_hour === curr.order_shipped_hour
      );
      if (existingItem) {
        existingItem.quantity += curr.quantity;
      } else {
        acc.push({
          order_shipped_hour: curr.order_shipped_hour,
          quantity: curr.quantity,
        });
      }
      return acc;
    }, []);
  
    return [
      {
        name: 'sortable',
        data: summedData,
      },
    ];
  }, [data]);



  const lineChartData_fullcase = useMemo(() => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    let startDate, endDate;
      if (dayOfWeek === 1) { // Monday
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 4);
        endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
      } else {
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        endDate = new Date();
        endDate.setDate(endDate.getDate());
      }
  
    const filteredItems = data.filter(item =>
      new Date(item.expected_ship_date) >= startDate &&
      new Date(item.expected_ship_date) < endDate &&
      item.br_asin_sortability === 'fullcase'
    );
  
    const lineChartData = {
      'fullcase': [],
    };
  
    filteredItems.forEach((item) => {
      const orderShippedDate = new Date(item.order_shipped_date);
      lineChartData['fullcase'].push({
        order_shipped_hour: orderShippedDate.getHours(),
        quantity: item.status === 'INVOICE_SUBMITTED' || item.status === 'PACKED' ? item.quantity : 0,
      });
    });
  
    lineChartData['fullcase'].sort((a, b) => a.order_shipped_hour - b.order_shipped_hour);
  
    const summedData = lineChartData['fullcase'].reduce((acc, curr) => {
      const existingItem = acc.find(
        (item) => item.order_shipped_hour === curr.order_shipped_hour
      );
      if (existingItem) {
        existingItem.quantity += curr.quantity;
      } else {
        acc.push({
          order_shipped_hour: curr.order_shipped_hour,
          quantity: curr.quantity,
        });
      }
      return acc;
    }, []);
  
    return [
      {
        name: 'fullcase',
        data: summedData,
      },
    ];
  }, [data]);



  const lineChartData_noncore = useMemo(() => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    let startDate, endDate;
      if (dayOfWeek === 1) { // Monday
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 4);
        endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
      } else {
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        endDate = new Date();
        endDate.setDate(endDate.getDate());
      }
  
    const filteredItems = data.filter(item =>
      new Date(item.expected_ship_date) >= startDate &&
      new Date(item.expected_ship_date) < endDate &&
      item.br_asin_sortability === 'noncore'
    );
  
    const lineChartData = {
      'noncore': [],
    };
  
    filteredItems.forEach((item) => {
      const orderShippedDate = new Date(item.order_shipped_date);
      lineChartData['noncore'].push({
        order_shipped_hour: orderShippedDate.getHours(),
        quantity: item.status === 'INVOICE_SUBMITTED' || item.status === 'PACKED' ? item.quantity : 0,
      });
    });
  
    lineChartData['noncore'].sort((a, b) => a.order_shipped_hour - b.order_shipped_hour);
  
    const summedData = lineChartData['noncore'].reduce((acc, curr) => {
      const existingItem = acc.find(
        (item) => item.order_shipped_hour === curr.order_shipped_hour
      );
      if (existingItem) {
        existingItem.quantity += curr.quantity;
      } else {
        acc.push({
          order_shipped_hour: curr.order_shipped_hour,
          quantity: curr.quantity,
        });
      }
      return acc;
    }, []);
  
    return [
      {
        name: 'noncore',
        data: summedData,
      },
    ];
  }, [data]);

  
 

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
    <Grid container spacing={2}>
    <Grid item lg={6} xs={6} md={6}>
    </Grid>
    <Grid item lg={6} xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
    <Button variant="contained" color="primary" onClick={fetchData}>
    Refresh Data
    </Button>
    </Grid>
    </Grid>
    <Grid item lg={8} xs={12} md={8}>
      <Typography
            sx={{
              marginTop: '2vh',
              //marginLeft: center,
              align: 'center',
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Last CPT (processed orders per asin sortability)
            {loading && <Loader size="large" sx={{ marginLeft: '40px' }} />}
          </Typography>
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 1,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '1px solid #e0e0e0',
              borderRadius: '10px',
              minHeight: '40vh',
              maxHeight: '90vh',
              overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={sortedData_table}
            columns={[
              { field: 'warehouse_id', headerName: 'Node', flex: 1 },
              { field: 'expected_ship_date', headerName: 'Expected Ship Date', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'br_asin_sortability', headerName: 'Sortability', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'not_processed', headerName: 'Not Processed Orders', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'processed', headerName: 'Processed Orders', flex: 1, align: 'center', headerAlign: 'center' },
              //{ field: 'backlog_processed', headerName: 'Backlog Processed', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'processedperc', headerName: 'Processed %', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: (params) => `${(params.value * 100).toFixed(2)}%` },
            ]}
            components={{
              Toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            disableColumnSelector
            disableDensitySelector
            initialState={{
              pagination: { pageSize: 100 },
              sortModel: [{ field: 'deviation', sort: 'asc' }],
            }}
          />
        </Grid>
    
    <Typography
            sx={{
              marginTop: '2vh',
              //marginLeft: center,
              align: 'center',
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Processed orders per hour (asin sortability level)
            {loading && <Loader size="large" sx={{ marginLeft: '40px' }} />}
          </Typography>
  <Grid container spacing={2}>
  <Grid item lg={6} xs={12} md={6}>
  
    <Typography
      sx={{
        marginTop: '2vh',
        align: 'center',
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(15px + 0.5vh)',
        fontWeight: 'bold',
      }}
    >
      Fullcase
      {loading && <Loader size="large" />}
    </Typography>
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={lineChartData_fullcase} background="#FFFFFF">
        <XAxis dataKey="order_shipped_hour" type="number" domain={[0, 24]} />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        {lineChartData_fullcase.map((line, index) => (
          <Line
            key={`line-${index}`}
            dataKey="quantity"
            data={line.data}
            name={line.name}
            stroke={`#${((1 << 24) * Math.random() | 0).toString(16).padStart(6, '0')}`}
            strokeWidth={3} // Set the stroke width to 3 pixels
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  </Grid>

  <Grid item lg={6} xs={12} md={6}>
    <Typography
      sx={{
        marginTop: '2vh',
        align: 'center',
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(15px + 0.5vh)',
        fontWeight: 'bold',
      }}
    >
      Sortable
      {loading && <Loader size="large" />}
    </Typography>
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={lineChartData}>
        <XAxis dataKey="order_shipped_hour" type="number" domain={[0, 24]} />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        {lineChartData.map((line, index) => (
          <Line
            key={`line-${index}`}
            dataKey="quantity"
            data={line.data}
            name={line.name}
            stroke={`#${((1 << 24) * Math.random() | 0).toString(16).padStart(6, '0')}`}
            strokeWidth={3} // Set the stroke width to 3 pixels
            connectNulls
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  </Grid>
</Grid>

<Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Grid item lg={6} xs={12} md={6}>
  
    <Typography
      sx={{
        marginTop: '2vh',
        align: 'center',
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(15px + 0.5vh)',
        fontWeight: 'bold',
      }}
    >
      Non-Core
      {loading && <Loader size="large" />}
    </Typography>
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={lineChartData_noncore} background="#FFFFFF">
        <XAxis dataKey="order_shipped_hour" type="number" domain={[0, 24]} />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        {lineChartData_noncore.map((line, index) => (
          <Line
            key={`line-${index}`}
            dataKey="quantity"
            data={line.data}
            name={line.name}
            stroke={`#${((1 << 24) * Math.random() | 0).toString(16).padStart(6, '0')}`}
            strokeWidth={3} // Set the stroke width to 3 pixels

          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  </Grid>

  
</Grid>

      
      


    </div>
  );
}

export default PostList;