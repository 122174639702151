import PropTypes from 'prop-types';
import {Select, InputLabel, MenuItem, FormControl, Avatar } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Link } from "react-router-dom";

  const tableColumns = [
    {
      field: "week",
      headerName: "Week",
      type: 'number',
      headerClassName: 'customHeader',
      width: '110',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      },
    {
      field: "classification",
      headerName: "Classification",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    }, 
    {
      field: "orders",
      headerName: "Orders",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    },
    {
      field: "shareOrders",
      headerName: "Share Orders (%)",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        
        return (
          <div
            style={{
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
          </div>
        );
      },
    }, 
    {
      field: "lsr_re_f_units",
      headerName: "LSR RE",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "lsr_re_f_percent",
      headerName: "LSR RE (%)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        
        return (
          <div
            style={{
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
          </div>
        );
      },
    }, 
        {
      field: "lsr_re_target",
      headerName: "LSR RE TARGET (%)",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const classification = params.row.classification;
    
        switch (classification) {
          case 'HOTW':
            return '4.00%'
          case 'CHARLIE':
            return '2.80%';
          case 'BETA':
            return '2.50%';
          case 'ALPHA':
            return '2.10%';
          case 'LOW TOUCH':
            return '0.50%'
          case 'FST':
            return '1.90%'
          case 'NEW':
            return '4.50%'
          default:
            return ''; 
        }
      },
    },
    {
      field: "lsr_re_dif_target",
      headerName: "LSR RE Diff vs TARGET (%)",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const classification = params.row.classification;
        const lsr = params.row.lsr_re_f_percent;
    
        switch (classification) {
          case 'HOTW':
            return `${((0.04-lsr)*100).toFixed(2)}%`
          case 'CHARLIE':
            return `${((0.028-lsr)*100).toFixed(2)}%`
          case 'BETA':
            return `${((0.025-lsr)*100).toFixed(2)}%`
          case 'ALPHA':
            return `${((0.021-lsr)*100).toFixed(2)}%`
          case 'LOW TOUCH':
            return `${((0.05-lsr)*100).toFixed(2)}%`
          case 'FST':
            return `${((0.019-lsr)*100).toFixed(2)}%`
          case 'NEW':
            return `${((0.045-lsr)*100).toFixed(2)}%`
          default:
            return ''; 
        }
      },
    },
    {
      field: "mexsd_re_f_units",
      headerName: "MXSD",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "mexsd_re_f_percent",
      headerName: "MXSD (%)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        
        return (
          <div
            style={{
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
          </div>
        );
      },
    }, 
    {
      field: "late_handover_units",
      headerName: "LH",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "late_handover_percent",
      headerName: "LH (%)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        
        return (
          <div
            style={{
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: "cr_units",
      headerName: "CR",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    },
    {
      field: "cr_percent",
      headerName: "SCR (%)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        
        return (
          <div
            style={{
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: "cr_target",
      headerName: "CR TARGET (%)",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const classification = params.row.classification;
    
        switch (classification) {
          case 'HOTW':
            return '3.00%'
          case 'CHARLIE':
            return '1.20%';
          case 'BETA':
            return '0.90%';
          case 'ALPHA':
            return '0.70%';
          case 'LOW TOUCH':
            return '0.30%'
          case 'FST':
            return '1.00%'
          case 'NEW':
            return '3.50%'
          default:
            return ''; 
        }
      },
    },
    {
      field: "cr_dif_target",
      headerName: "CR Diff vs TARGET (%)",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const classification = params.row.classification;
        const cr = params.row.cr_percent;
    
        switch (classification) {
          case 'HOTW':
            return `${((0.03-cr)*100).toFixed(2)}%`
          case 'CHARLIE':
            return `${((0.012-cr)*100).toFixed(2)}%`
          case 'BETA':
            return `${((0.009-cr)*100).toFixed(2)}%`
          case 'ALPHA':
            return `${((0.007-cr)*100).toFixed(2)}%`
          case 'LOW TOUCH':
            return `${((0.003-cr)*100).toFixed(2)}%`
          case 'FST':
            return `${((0.010-cr)*100).toFixed(2)}%`
          case 'NEW':
            return `${((0.035-cr)*100).toFixed(2)}%`
          default:
            return ''; 
        }
      },
      
    },
    ];


export default tableColumns;