// Error404.js

const Error404 = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <img
        id="memeImage"
        src="/ric_boladao.png"
        alt="Meme"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
      <h1 style={{ fontSize: '2rem', marginTop: '20px' }}>404 - Not found</h1>
      <p style={{ textAlign: 'center', fontSize: '1rem', margin: '10px' }}>Sorry, The page you are looking for doesn't exist or other error occurred.</p>
      <p style={{ textAlign: 'center', fontSize: '1rem', margin: '10px' }}>Go back, or head over to <a href="https://osc.d1sms6jvdn4nry.amplifyapp.com">Home Page</a> to choose another direction.</p>
    </div>
  );
};

export default Error404;
