import { useState, useEffect, useCallback } from "react";
import { listSellerDataFBAS } from "../../../graphql/queries";
import { updateSellerDataFBA } from "../../../graphql/mutations";
import { onupdateSellerDataFBA } from "../../../graphql/subscriptions";
import { API, Amplify, Auth, graphqlOperation  } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import awsconfig from '../../../aws-exports';
import { tableColumnsAdmin, tableColumnsOps, tableColumnsView } from './SellerData_columns';
import { withAuthenticator } from '@aws-amplify/ui-react';
import './SellerData.css';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';


Amplify.configure(awsconfig);

const SellerData = ({ username, userGroup }) => {
  const [data, setData] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);


  const fetchData = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      do {
        const response = await API.graphql(
          graphqlOperation(listSellerDataFBAS, { 
            limit: 20000, nextToken })
        );
        const fetchedItems = response.data.listSellerDataFBAS.items;
        
        // Filter the items to include only those with ops_owner not containing "Deactivated"
        //const filteredItems = fetchedItems.filter(item => !item.ops_owner.includes('Deactivated'));
        
        //items = items.concat(filteredItems);
        items.push(...fetchedItems);
        nextToken = response.data.listSellerDataFBAS.nextToken;
      } while (nextToken);
    
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  useEffect(() => {
      try {
        fetchData();
      } catch (error) {
        console.error('Error fetching user group information:', error);
      }
  }, []);

let tableColumns;

if (userGroup.includes('admin') || userGroup.includes('managers') || userGroup.includes('pocs') )  {
  tableColumns = tableColumnsAdmin; 
} else if (userGroup.includes('ops_owner')){
  tableColumns = tableColumnsOps; 
} else {
  tableColumns = tableColumnsView; 
}
  const handleProcessRowUpdateError = (error, params) => {
    console.error('Error updating row:', error);
    // Perform additional error handling logic, such as showing an error message
  };
  const handleEditRow = useCallback(async (params) => {
    const { id, email ,phone_nft, phone_comercial, _version } = params;
    const editedRow = { 
      id, 
      email,
      phone_nft,
      phone_comercial,
      user_edition: username, 
      _version };
    // Check if the edited row is already in the editedRows state
    const existingRow = editedRows.find((row) => row.id === editedRow.id);
    try {
      if (existingRow) {
        // If the edited row is already in the state, update its value
        const updatedRows = editedRows.map((row) =>
          row.id === editedRow.id ? editedRow : row
        );
        setEditedRows(updatedRows);
      } else {
        // If the edited row is not in the state, add it
        setEditedRows((prevRows) => [...prevRows, editedRow]);
      }
    } catch (error) {
      console.error('Error updating rows:', error);
    }
  }, [editedRows, username]);
  
  const handleUpdateDatabase = async () => {
    try {
      for (const { id, email ,phone_nft, phone_comercial, _version } of editedRows) {
        const input = {
          id: id, 
          email: email,
          phone_nft: phone_nft,
          phone_comercial: phone_comercial,
          user_edition: username, 
        };
        // Perform the GraphQL mutation for each edited row
        await API.graphql(graphqlOperation(updateSellerDataFBA, { input }));
      }
      window.location.reload();
    } catch (error) {
      console.error('Error updating rows:', error);
    }
  };
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarExport />
      <Button
        variant="contained"
        color="primary"
        disabled={editedRows.length === 0} // Disable the button if no rows are edited
        onClick={handleUpdateDatabase}
      >
        Salvar
      </Button>
      <GridToolbarQuickFilter
        sx={{
          position: 'absolute',
          padding: '18px',
          right: '15px'
        }}
      />
    </GridToolbarContainer>
  );
  const handleRefreshData = async () => {
    try {
      setLoading(true);
      await fetchData();
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2} direction="row">
        <Grid item xs={3} md={3}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            PCP - SELLER DATA
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
            Table detailing Sellers informations 
          </Typography>
        </Grid>
          <Grid item xs={2} textAlign="right">
              <Button
              variant="contained"
              color="primary"
              onClick={handleRefreshData}
              >
              Refresh Data
              </Button>
          </Grid>

        
        <Grid item xs={8} md={8} className="blankReasonsChip" sx={{marginTop: '3vh', textAlign: 'right'}}>
        </Grid>
      </Grid>
      {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20vh' }}>
                <CircularProgress />
              </Box>
            ) : (
      <DataGrid
        sx={{
          marginTop: '2vh',
          marginLeft: 2,
          marginRight: 2,
          padding: '10px 18px 18px 18px',
          backgroundColor: "#FFFFFF",
          border: '0px',
          borderRadius: '10px',
          maxHeight: '90%',
        }}
        rows={data}
        columns={tableColumns}
        processRowUpdate={handleEditRow}
        onCellEditCommit = {handleEditRow}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        components={{
          Toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        editMode="row"
        checkboxSelection
        disableColumnSelector
        disableDensitySelector
        initialState={{
          pagination: { paginationModel: { pageSize: 100 } },
        }}
      />
      )}
    </div>
    );
};


export default withAuthenticator(SellerData);