import { useState, useEffect, useCallback, useMemo } from "react";
import { listMisses } from "../../../graphql/queries";
import { updateMisses } from "../../../graphql/mutations";
import { onUpdateMisses } from "../../../graphql/subscriptions";
import { API, Amplify, Auth, graphqlOperation  } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Chip, Grid  } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import awsconfig from '../../../aws-exports';
import { tableColumnsAdmin, tableColumnsOps, tableColumnsView } from './PreWBR_SF_columns';
import { getISOWeek, subWeeks  } from 'date-fns'; 
import './PreWBR.css';
import { withAuthenticator} from '@aws-amplify/ui-react';
//import { } from 'aws-amplify-react';
import { Loader } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';


Amplify.configure(awsconfig);

const MissesDay = ({ onRefresh, fetchData, username, userGroup, data, loading }) => {
  const [editedRows, setEditedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [initialData, setInitialData] = useState(data); 
  const [openSnackbar, setOpenSnackbar] = useState(false); 
  const [isLoading, setLoading] = useState(loading);
 

  // Calculate the ratio for the session user only
  const sessionUserStats = data.reduce((stats, row) => {
    if (row.ops_owner === username) {
      stats.total++;
      if (row.reason !== "LH_BY_CARRIER" && !row.reason) {
        stats.withoutReason++;
      }
    }
    return stats;
  }, { total: 0, withoutReason: 0 });

  console.log('Username:', username);
  sessionUserStats.ratio = sessionUserStats.withoutReason / sessionUserStats.total;
  const ratioPercentage = (sessionUserStats.ratio * 100).toFixed(0);
  let chipColor = 'error';
  let chipIcon = <ErrorIcon />;
  let chipLabel = `${ratioPercentage}% of your misses have no reason mapped`;

  if(ratioPercentage == 0){
    chipColor = 'success'
    chipIcon = <CheckCircleIcon />
    chipLabel = `No pending actions. Congrats!`
  }
  if (isNaN(ratioPercentage)) {
    chipColor = 'success';
    chipIcon = <CheckCircleIcon />;
    chipLabel = `No pending actions.`;
  }

  const sortedData = useMemo(() => {
    const oneWeekAgo = subWeeks(new Date(), 2); // Get the date for 1 week ago
    const filteredData = data.filter(item => {
      const itemDate = new Date(item.date);
      return itemDate >= oneWeekAgo; // Filter the data by checking if the item's date is greater than or equal to 1 week ago
    });
  
    const sortedItems = filteredData.sort((a, b) => {
      // Sort by date column in descending order
      const dateComparison = new Date(b.date) - new Date(a.date);
      if (dateComparison !== 0) {
        return dateComparison;
      }
  
      const unitsComparison = b.units - a.units;
      if (unitsComparison !== 0) {
        return unitsComparison;
      }
  
      const opsComparison = b.ops_owner - a.ops_owner;
      if (opsComparison !== 0) {
        return opsComparison;
      }
  
      // Sort by miss_type column (MxSD, Cancellation, LH)
      const missTypeOrder = ['MXSD', 'CANCELLATION', 'LH_BY_SELLER'];
      return missTypeOrder.indexOf(b.miss_type) - missTypeOrder.indexOf(a.miss_type);
    });
  
    return sortedItems;
  }, [data]);
  
  let tableColumns;

  if (userGroup.includes('admin') )  {
    tableColumns = tableColumnsAdmin; 
  } else if (userGroup.includes('ops_owner') || userGroup.includes('managers')){
    tableColumns = tableColumnsOps; 
  } else {
    tableColumns = tableColumnsView; 
  }


  const handleProcessRowUpdateError = (error, params) => {
    console.error('Error updating row:', error);
   
  };
  const handleEditRow = useCallback(async (params) => {
    const { id, seller_name, ops_owner, date, node, units, reason, subreason, remove_from_pm, comments, carrier, pod, poa, self_report, ticket, miss_type, week, classification, _version } = params;
    const editedRow = {
      id,
      seller_name,
      ops_owner,
      date,
      node,
      units,
      reason,
      subreason,
      remove_from_pm,
      comments,
      carrier,
      pod,
      poa,
      self_report,
      ticket,
      miss_type,
      week,
      classification,
      _version,
      user_edition: username, 
    };
    // Check if the edited row is already in the editedRows state
    const existingRow = editedRows.find((row) => row.id === editedRow.id);
    try {
      if (existingRow) {
        // If the edited row is already in the state, update its value
        const updatedRows = editedRows.map((row) =>
          row.id === editedRow.id ? editedRow : row
        );
        setEditedRows(updatedRows);
      } else {
        // If the edited row is not in the state, add it
        setEditedRows((prevRows) => [...prevRows, editedRow]);
      }
    } catch (error) {
      console.error('Error updating rows:', error);
    }
  }, [editedRows, username]);
  
  const handleUpdateDatabase = async () => {
    try {
      for (const { id, ops_owner, date, reason, subreason, remove_from_pm, comments, carrier, poa, pod, self_report, ticket, _version } of editedRows) {
        const input = {
          id: id,
          ops_owner: ops_owner,
          date: date,
          reason: reason,
          subreason: subreason,
          remove_from_pm: remove_from_pm,
          comments: comments,
          carrier: carrier,
          pod: pod,
          poa: poa,
          self_report: self_report,
          ticket: ticket,
          _version: _version,
          user_edition: username, 
        };
        // Perform the GraphQL mutation for each edited row
        await API.graphql(graphqlOperation(updateMisses, { input }));
      }

      // Update the table data without reloading the page
      setInitialData((prevData) => {
        const updatedData = prevData.map((row) => {
          const editedRow = editedRows.find((editedRow) => editedRow.id === row.id);
          return editedRow ? { ...row, ...editedRow } : row;
        });
        return updatedData;
      });

      // Clear the edited rows
      setEditedRows([]);
      setOpen(false);
      setOpenSnackbar(true); 
    } catch (error) {
      console.error('Error updating rows:', error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarExport />
      <Button
        variant="contained"
        color="primary"
        disabled={editedRows.length === 0} 
        onClick={handleUpdateDatabase}
      >
        Salvar
      </Button>
      <GridToolbarQuickFilter
        sx={{
          position: 'absolute',
          padding: '18px',
          right: '15px'
        }}
      />
    </GridToolbarContainer>
  );

  const handleRefresh = async () => {
    try {
      setLoading(true);
      await fetchData(); // Call fetchData function passed from props
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
        <div>
          <Grid container alignItems="center" spacing={2}>
            {/* Typography */}
            <Grid item xs={3} md={3}>
              <Typography
                sx={{
                  marginTop: 0,
                  marginLeft: 3,
                  fontFamily: 'Source Sans Pro',
                  fontSize: 'calc(10px + 0.5vh)',
                  fontWeight: 'lighter',
                  backgroundColor: 'lightcoral',
                  padding: '10px',
                  borderRadius: '5px',
                  textAlign: 'center',
                }}
              >
                Dear User, New Feature Alert: Now, you can view all tracking IDs and order IDs by hovering your mouse cursor over the 'units' cell!
              </Typography>
            </Grid>

            {/* Middle Spacer */}
            <Grid item xs={1} />

            {/* Button */}
            <Grid item xs={2} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={onRefresh}
              >
                Refresh Data
              </Button>
            </Grid>

            {/* Right Spacer */}
            <Grid item xs={1} />

            {/* Chip */}
            <Grid item xs={4} md={4} className="blankReasonsChip" sx={{ marginTop: '3vh', textAlign: 'right' }}>
              <Chip icon={chipIcon} label={chipLabel} color={chipColor} />
            </Grid>
          </Grid>

            {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
              </div>
            </div>
            ) : (
            <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                maxHeight: '80vh', 
                overflow: 'auto', 
              }}
              rows={sortedData}
              columns={tableColumns}
              processRowUpdate={handleEditRow}
              onCellEditCommit={handleEditRow}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              components={{
                Toolbar: CustomToolbar,
              }}
              
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              editMode="row"
              checkboxSelection
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
              }}
            />
            )}   
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000} 
              onClose={handleSnackbarClose}
              message="Edition saved successfully!"
              action={
                <Button color="primary" size="small" onClick={handleSnackbarClose}>
                  Close
                </Button>
              }
            />
          </div>
    </div>
  );
    
};


export default withAuthenticator(MissesDay);