import { useState, useEffect, useCallback, useMemo } from "react";
import { listMissesEasyShips } from "../../../graphql/queries";
import { updateMissesEasyShip } from "../../../graphql/mutations";
import { API, Amplify, Auth, graphqlOperation  } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Chip, Grid } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import awsconfig from '../../../aws-exports';
import { tableColumnsAdmin, tableColumnsOps, tableColumnsView } from './PreWBR_ES_columns';
import './PreWBR.css';
import { subWeeks  } from 'date-fns';
import { withAuthenticator, Loader } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

const MissesDayES = ({ username, userGroup  }) => {
  const [data, setData] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  // Calculate the ratio for the session user only
  const sessionUserStats = data.reduce((stats, row) => {
    if (row.owner === username) {
      stats.total++;
      if (row.reason !== "LH_BY_CARRIER" && !row.reason) {
        stats.withoutReason++;
      }
    }
    return stats;
  }, { total: 0, withoutReason: 0 });

  sessionUserStats.ratio = sessionUserStats.withoutReason / sessionUserStats.total;
  const ratioPercentage = (sessionUserStats.ratio * 100).toFixed(0);
  let chipColor = 'error';
  let chipIcon = <ErrorIcon />;
  let chipLabel = `${ratioPercentage}% of your misses have no reason mapped`;

  if(ratioPercentage == 0){
    chipColor = 'success'
    chipIcon = <CheckCircleIcon />
    chipLabel = `No pending actions. Congrats!`
  }
  // get (week - 1) number
  function getCurrentWeekNumber() {
    const now = new Date();
    const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate()) ;
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
    const millisecondsTillOneWeekAgo = (oneWeekAgo) - startOfYear;
    const weekNumberOneWeekAgo = Math.ceil(millisecondsTillOneWeekAgo / millisecondsPerWeek);
    return weekNumberOneWeekAgo -2;
  }
  const currentWeekNumber = getCurrentWeekNumber();
  async function fetchData() {
    try {
      let nextToken = null;
      const items = [];
      setLoading(true);
  
      do {
        const response = await API.graphql(graphqlOperation(listMissesEasyShips, {
          filter: {
            week: {
              ge: currentWeekNumber
            }
          },
          limit: 10000,
          nextToken: nextToken // Set the nextToken parameter
        }));
  
        const fetchedItems = response.data.listMissesEasyShips.items;
        items.push(...fetchedItems);
  
        nextToken = response.data.listMissesEasyShips.nextToken;
      } while (nextToken);
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  console.log(currentWeekNumber);
  // Get the date for 1 week ago
  const sortedData = useMemo(() => {
    const oneWeekAgo = subWeeks(new Date(), 2); 
    const filteredData = data.filter(item => {
      const itemDate = new Date(item.date);
      return itemDate >= oneWeekAgo; 
    });
  
    const sortedItems = filteredData.sort((a, b) => {
      // Sort by date column in descending order
      const dateComparison = new Date(b.date) - new Date(a.date);
      if (dateComparison !== 0) {
        return dateComparison;
      }
  
      const ordersComparison = b.orders - a.orders;
      if (ordersComparison !== 0) {
        return ordersComparison;
      }
  
      const opsComparison = b.owner - a.owner;
      if (opsComparison !== 0) {
        return opsComparison;
      }
  
    });
  
    return sortedItems;
  }, [data]);
  
  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error('Error fetching user group information:', error);
    }
}, []);

let tableColumns;

if (userGroup.includes('admin') )  {
  tableColumns = tableColumnsAdmin; 
} else if (userGroup.includes('ops_owners') || userGroup.includes('managers')){
  tableColumns = tableColumnsOps; 
} else {
  tableColumns = tableColumnsView; 
}

  const handleProcessRowUpdateError = (error, params) => {
    console.error('Error updating row:', error);
    // Perform additional error handling logic, such as showing an error message
  };

  const handleEditRow = useCallback(async (params) => {
    const { id, seller_name, owner, date, mid, orders, reason, sub_reason, remove_from_pm, comments, pod, poa, self_report, ticket, miss_type, week, classification, _version } = params;
    const editedRow = { id, seller_name, owner, date, mid, orders, reason, sub_reason, remove_from_pm, comments, pod, poa, self_report, ticket, miss_type, week, classification, _version };
    // Check if the edited row is already in the editedRows state
    const existingRow = editedRows.find((row) => row.id === editedRow.id);
    try {
      if (existingRow) {
        // If the edited row is already in the state, update its value
        const updatedRows = editedRows.map((row) =>
          row.id === editedRow.id ? editedRow : row
        );
        setEditedRows(updatedRows);
      } else {
        // If the edited row is not in the state, add it
        setEditedRows((prevRows) => [...prevRows, editedRow]);
      }
    } catch (error) {
      console.error('Error updating rows:', error);
    }
  }, [editedRows]);
  
  const handleUpdateDatabase = async () => {
    try {
      for (const { id, owner, date, reason, sub_reason, remove_from_pm, comments, pod, poa, self_report, ticket, _version } of editedRows) {
        const input = {
          id: id,
          owner: owner,
          date: date,
          reason: reason,
          sub_reason: sub_reason,
          remove_from_pm: remove_from_pm,
          comments: comments,
          pod: pod,
          poa: poa,
          self_report: self_report,
          ticket: ticket,
          _version: _version,
        };
        // Perform the GraphQL mutation for each edited row
        await API.graphql(graphqlOperation(updateMissesEasyShip, { input }));
      }
      window.location.reload();
    } catch (error) {
      console.error('Error updating rows:', error);
    }
  };  

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarExport />
      <Button
        variant="contained"
        color="primary"
        disabled={editedRows.length === 0} // Disable the button if no rows are edited
        onClick={handleUpdateDatabase}
      >
        Salvar
      </Button>
      <GridToolbarQuickFilter
        sx={{
          position: 'absolute',
          padding: '18px',
          right: '15px'
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Loader size="large" />
        </div>
      ) : (
        <div style={{ height: 'calc(100vh - 64px)', overflow: 'auto' }}>
          <Grid container spacing={2} direction="row">
            <Grid item xs={3} md={3}>
              <Typography
                sx={{
                  marginTop: '2vh',
                  marginLeft: 3,
                  fontFamily: 'Source Sans Pro',
                  fontSize: 'calc(15px + 0.5vh)',
                  fontWeight: 'bold',
                }}
              >
                SELLER FLEX - DAILY MISSES
              </Typography>
              <Typography
                sx={{
                  marginTop: 0,
                  marginLeft: 3,
                  fontFamily: 'Source Sans Pro',
                  fontSize: 'calc(7px + 0.5vh)',
                  fontWeight: 'lighter',
                }}
              >
                Table detailing Seller Flex's operational misses
              </Typography>
            </Grid>
            <Grid item xs={8} md={8} className="blankReasonsChip" sx={{ marginTop: '3vh', textAlign: 'right' }}>
              <Chip icon={chipIcon} label={chipLabel} color={chipColor} />
            </Grid>
          </Grid>
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 2,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '0px',
              borderRadius: '10px',
              maxHeight: '90%',
            }}
            rows={sortedData}
            columns={tableColumns}
            processRowUpdate={handleEditRow}
            onCellEditCommit={handleEditRow}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            components={{
              Toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            editMode="row"
            checkboxSelection
            disableColumnSelector
            disableDensitySelector
            initialState={{
              pagination: { paginationModel: { pageSize: 100 } },
            }}
          />
        </div>
      )}
    </div>
  );
};


export default withAuthenticator(MissesDayES);