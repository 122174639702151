import React, { useState, useEffect } from 'react';
import { Avatar } from '@mui/material';

import { useMemo } from "react";
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid } from '@mui/material';
import { Loader } from '@aws-amplify/ui-react';


const TopOffender = ({ data, loading, onRefresh, fetchData }) => {
    const [mxsdData, setMxsdData] = useState([]);
    const [cancellationData, setCancellationData] = useState([]);
    const [lhBySellerData, setLhBySellerData] = useState([]);
    const [isLoading, setLoading] = useState(loading); // Define setLoading using useState
  
    useEffect(() => {
      if (data && data.length > 0) {
        // Filtrar as linhas com unidades >= 10 e miss_type em ['MXSD', 'CANCELLATION', 'LH_BY_SELLER']
        const filteredAndSortedData = data
          .filter(item => item.units >= 10 && ['MXSD', 'CANCELLATION', 'LH_BY_SELLER'].includes(item.miss_type))
          const sortedData = filteredAndSortedData.sort((a, b) => {
            // Sort by date column in descending order
            const dateComparison = new Date(b.date) - new Date(a.date);
            if (dateComparison !== 0) {
              return dateComparison;
            }
    
            const unitsComparison = b.units - a.units;
            if (unitsComparison !== 0) {
              return unitsComparison;
            }
    
            const opsComparison = b.ops_owner - a.ops_owner;
            if (opsComparison !== 0) {
              return opsComparison;
            }
    
            // A ordem alfabética dos valores de miss_type será usada para a ordenação.
            return a.miss_type.localeCompare(b.miss_type);
          });
    
        // Separe os dados com base em miss_type
        const mxsdData = sortedData.filter(item => item.miss_type === 'MXSD');
        const cancellationData = sortedData.filter(item => item.miss_type === 'CANCELLATION');
        const lhBySellerData = sortedData.filter(item => item.miss_type === 'LH_BY_SELLER');
    
        setMxsdData(mxsdData);
        setCancellationData(cancellationData);
        setLhBySellerData(lhBySellerData);
      }
    }, [data]);  
    const tableColumns = [
      { field: 'node', headerName: 'Node', flex: 1,headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center' },
      { field: 'seller_name', headerName: 'Seller Name', flex: 1,headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center' },
      { 
        field: 'ops_owner', 
        headerName: 'Ops Owner',
        flex: 1,
        headerClassName: 'customHeader boldHeader',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const opsOwner = params?.value;
          const opsOwnerName = opsOwner; 
      
          if (opsOwner) {
            return (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />

              </div>
            );
          }
      
          return null; 
        },
      },
      
      { field: 'date', headerName: 'Date', flex: 1, 
      valueFormatter: params => new Date(params?.value).toLocaleDateString()
      ,headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center' },
      //{ field: 'week', headerName: 'Week', flex: 1,headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center' },
      { field: 'units', headerName: 'Units', flex: 1, headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center' },
    ];
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
    </GridToolbarContainer>
  );
  const dataDoDiaAnterior = new Date();
  const dateDayLess2 = new Date();
  dataDoDiaAnterior.setDate(dataDoDiaAnterior.getDate() - 1);
  dateDayLess2.setDate(dataDoDiaAnterior.getDate() - 1);
  
  const dia = dataDoDiaAnterior.getDate().toString().padStart(2, '0');
  const dayLH = dateDayLess2.getDate().toString().padStart(2, '0');
  const mes = (dataDoDiaAnterior.getMonth() + 1).toString().padStart(2, '0');
  const mesLH = (dateDayLess2.getMonth() + 1).toString().padStart(2, '0');
  const ano = dataDoDiaAnterior.getFullYear();
  const anoLH = dateDayLess2.getFullYear();
  
  const dataFormatada = `${ano}-${mes}-${dia}T00:00:00.000-03:00`;
  const dataFormatadaLF = `${anoLH}-${mesLH}-${dayLH}T00:00:00.000-03:00`;

  
  return (
        
        <div className="datatable" style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column',  }}>
          <Grid item xs={12} textAlign="center" style={{ marginTop: '2vh' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={onRefresh}
            >
              Refresh Data
            </Button>
          </Grid>
          {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
              </div>
            </div>
          ) : (
            <Grid container spacing={8} direction="row">
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Typography
                sx={{
                  marginTop: '2vh',
                  marginLeft: 3,
                  fontFamily: 'Source Sans Pro',
                  fontSize: 'calc(15px + 0.5vh)',
                  fontWeight: 'bold',
                }}
              >
                Mxsd Top Offenders
              </Typography>
              <Typography
                sx={{
                  marginTop: 0,
                  marginLeft: 3,
                  fontFamily: 'Source Sans Pro',
                  fontSize: 'calc(15px + 0.5vh)', 
                  fontWeight: 'lighter',
                }}
              >
              </Typography>
              
            {loading ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginTop: '20vh' }}>
                  <Loader size="large" /> 
                </div>
              </div>
              ) : (
                <DataGrid
                  sx={{
                    marginTop: '2vh',
                    marginLeft: 2,
                    marginRight: 2,
                    padding: '10px 18px 18px 18px',
                    backgroundColor: "#FFFFFF",
                    border: '0px',
                    borderRadius: '10px',
                    minHeight: '30vh',
                    maxHeight: '90vh',
                    overflow: 'auto', // Enable vertical scrolling if needed
                  }}
                  rows={mxsdData}
                  columns={tableColumns}
                  components={{
                      Toolbar: CustomToolbar,
                  }}
                  disableColumnSelector
                  disableDensitySelector
                  initialState={{
                    pagination: { paginationModel: { pageSize: 15 } },
                    filter: {
                      filterModel: {
                        items: [{ field: 'date', operator: 'contains', value: dataFormatada  }],
                      },
                    },
                }}    
                />
              )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
              <Typography
              sx={{
                marginTop: '2vh',
                marginLeft: 3,
                fontFamily: 'Source Sans Pro',
                fontSize: 'calc(15px + 0.5vh)',
                fontWeight: 'bold',
              }}
            >
              Cancellation Top Offenders
            </Typography>
            <Typography
              sx={{
                marginTop: 0,
                marginLeft: 3,
                fontFamily: 'Source Sans Pro',
                fontSize: 'calc(7px + 0.5vh)',
                fontWeight: 'lighter',
              }}
            >
              
            </Typography>
            {loading ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginTop: '20vh' }}>
                  <Loader size="large" /> 
                </div>
              </div>
              ) : (
              <DataGrid
                  sx={{
                      marginTop: '2vh',
                      marginLeft: 2,
                      marginRight: 2,
                      padding: '10px 18px 18px 18px',
                      backgroundColor: "#FFFFFF",
                      border: '0px',
                      borderRadius: '10px',
                      minHeight: '40vh',
                      maxHeight: '90vh'
                      ,overflow: 'auto', // Enable vertical scrolling if needed
                  }}
                  rows={cancellationData}
                  columns={tableColumns}
                  components={{
                      Toolbar: CustomToolbar,
                  }}
                  disableColumnSelector
                  disableDensitySelector
                  initialState={{
                    pagination: { paginationModel: { pageSize: 15 } },
                    filter: {
                      filterModel: {
                        items: [{ field: 'date', operator: 'contains', value: dataFormatada  }],
                      },
                    },
                }}    
                  />
              )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
              <Typography
              sx={{
                marginTop: '2vh',
                marginLeft: 3,
                fontFamily: 'Source Sans Pro',
                fontSize: 'calc(15px + 0.5vh)',
                fontWeight: 'bold',
              }}
            >
              LH BY Seller Top Offenders
            </Typography>
            <Typography
              sx={{
                marginTop: 0,
                marginLeft: 3,
                fontFamily: 'Source Sans Pro',
                fontSize: 'calc(7px + 0.5vh)',
                fontWeight: 'lighter',
              }}
            >
            </Typography>
            {loading ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginTop: '20vh' }}>
                  <Loader size="large" /> 
                </div>
              </div>
              ) : (
            <DataGrid
                  sx={{
                      marginTop: '2vh',
                      marginLeft: 2,
                      marginRight: 2,
                      padding: '10px 18px 18px 18px',
                      backgroundColor: "#FFFFFF",
                      border: '0px',
                      borderRadius: '10px',
                      minHeight: '40vh',
                      maxHeight: '90vh',
                      overflow: 'auto', // Enable vertical scrolling if needed
                  }}
                  rows={lhBySellerData}
                  columns={tableColumns}
                  components={{
                      Toolbar: CustomToolbar,
                  }}
                  disableColumnSelector
                  disableDensitySelector
                  initialState={{
                    pagination: { paginationModel: { pageSize: 15 } },
                    filter: {
                      filterModel: {
                        items: [{ field: 'date', operator: 'contains', value: dataFormatadaLF }],
                      },
                    },
                }}   
                  />
              )}
              </Grid>
            </Grid>
          )}
        </div>
      );
    };
    
    export default TopOffender;
