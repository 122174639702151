// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const MissReassonEasyShip = {
  "INVENTORY_MISMATCH": "INVENTORY_MISMATCH",
  "AMAZON_TECHNICAL_ISSUE": "AMAZON_TECHNICAL_ISSUE",
  "REPORTING_ERROR_NOT_LH": "REPORTING_ERROR_NOT_LH",
  "MISSING_INVOICE_AMAZON": "MISSING_INVOICE_AMAZON",
  "MISSING_INVOICE_SEFAZ": "MISSING_INVOICE_SEFAZ",
  "MISSING_INVOICE_INTEGRATOR": "MISSING_INVOICE_INTEGRATOR",
  "MISSING_INVOICE_SELLER": "MISSING_INVOICE_SELLER",
  "LOGIN_ISSUES": "LOGIN_ISSUES_",
  "POWER_OR_INTERNET_OUTAGE": "POWER_OR_INTERNET_OUTAGE",
  "PERSONAL_EMERGENCY": "PERSONAL_EMERGENCY",
  "CANCELLATION_SELLER_NOT_CONTROLLABLE": "CANCELLATION_SELLER_NOT_CONTROLLABLE",
  "CANCELLATION_SELLER_CONTROLLABLE": "CANCELLATION_SELLER_CONTROLLABLE",
  "CAPACITY_BREACH": "CAPACITY_BREACH",
  "CARRIER_ISSUE": "CARRIER_ISSUE",
  "PRICING_ERROR": "PRICING_ERROR",
  "PRINTING_ISSUES": "PRINTING_ISSUES",
  "SELLER_OPERATIONAL_ISSUE": "SELLER_OPERATIONAL_ISSUE",
  "STARTED_TO_PROCESS_ORDERS_LATE": "STARTED_TO_PROCESS_ORDERS_LATE",
  "SELLER_NON_RESPONSIVE": "SELLER_NON_RESPONSIVE",
  "HARDWARE_ISSUE": "HARDWARE_ISSUE"
};

const MissReasons = {
  "INVENTORY_MISMATCH": "INVENTORY_MISMATCH",
  "AMAZON_TECHNICAL_ISSUE": "AMAZON_TECHNICAL_ISSUE",
  "MISSING_INVOICE_AMAZON": "MISSING_INVOICE_AMAZON",
  "MISSING_INVOICE_SEFAZ": "MISSING_INVOICE_SEFAZ",
  "MISSING_INVOICE_INTEGRATOR": "MISSING_INVOICE_INTEGRATOR",
  "MISSING_INVOICE_SELLER": "MISSING_INVOICE_SELLER",
  "LOGIN_ISSUES": "LOGIN_ISSUES",
  "POWER_OR_INTERNET_OUTAGE": "POWER_OR_INTERNET_OUTAGE",
  "PERSONAL_EMERGENCY": "PERSONAL_EMERGENCY",
  "CANCELLATION_SELLER_NOT_CONTROLLABLE": "CANCELLATION_SELLER_NOT_CONTROLLABLE",
  "CANCELLATION_SELLER_CONTROLLABLE": "CANCELLATION_SELLER_CONTROLLABLE",
  "CAPACITY_BREACH": "CAPACITY_BREACH",
  "CARRIER_ISSUE": "CARRIER_ISSUE",
  "PRICING_ERROR": "PRICING_ERROR",
  "PRINTING_ISSUES": "PRINTING_ISSUES",
  "SELLER_OPERATIONAL_ISSUE": "SELLER_OPERATIONAL_ISSUE",
  "STARTED_TO_PROCESS_ORDERS_LATE": "STARTED_TO_PROCESS_ORDERS_LATE",
  "SELLER_UNRESPONSIVE": "SELLER_UNRESPONSIVE",
  "HARDWARE_ISSUE": "HARDWARE_ISSUE",
  "REPORTING_ERROR_NOT_LH": "REPORTING_ERROR_NOT_LH",
  "UNKNOWN": "UNKNOWN"
};

const MissTypes = {
  "MXSD": "MXSD",
  "CANCELLATION": "CANCELLATION",
  "LH_BY_SELLER": "LH_BY_SELLER",
  "LH_BY_CARRIER": "LH_BY_CARRIER",
  "LH_REPORT_ERROR": "LH_REPORT_ERROR",
  "LH_BY_AMAZON": "LH_BY_AMAZON"
};

const { VendorFlexTRB, FbaSpoShipmentLevel, VFSortability, VFOrderStatus, PCPDefects, ESDailyOrderStatus, EasyShipOrderStatus2, EasyShipSellerOrderStatusFromPickup, EasyShipOrderStatusTest, EasyShipOrderStatus, ForecastSF, PCPPreWBR, SellerDataPCP2, SummaryPCP, SellerDataFBA, SFCPTManagement, SummaryVF, SummaryES, SellerDataES, MissesEasyShip, MQBandQBR, Misses, SellerDataSF } = initSchema(schema);

export {
  VendorFlexTRB,
  FbaSpoShipmentLevel,
  VFSortability,
  VFOrderStatus,
  PCPDefects,
  ESDailyOrderStatus,
  EasyShipOrderStatus2,
  EasyShipSellerOrderStatusFromPickup,
  EasyShipOrderStatusTest,
  EasyShipOrderStatus,
  ForecastSF,
  PCPPreWBR,
  SellerDataPCP2,
  SummaryPCP,
  SellerDataFBA,
  SFCPTManagement,
  SummaryVF,
  SummaryES,
  SellerDataES,
  MissesEasyShip,
  MQBandQBR,
  Misses,
  SellerDataSF,
  MissReassonEasyShip,
  MissReasons,
  MissTypes
};