import { useState, useEffect, useCallback, useMemo } from "react";
import { listSummaryVFS } from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import awsconfig from '../../../aws-exports';
import { groupBy, sumBy } from 'lodash';
import './summary.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Loader } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

const groupTypes = ['day', 'week', 'month', 'quarter', 'year'];
const Summary = ({ loading, onRefresh, data, fetchData }) => {
  const [isLoading, setLoading] = useState(loading); 
  const [groupByType, setGroupByType] = useState('week');
  
  const handleGroupByChange = (type) => {
    setGroupByType(type);
  };


  useEffect(() => {
    console.log("Dados brutos:", data);
  }, [data]);


  function getWeekNumber(date) {
    // Copy date so don't modify original
    date = new Date(date);
    
    // Set to nearest Saturday: current date - current day number + 6
    date.setUTCDate(date.getUTCDate() - date.getUTCDay() + 6);
    
    // Get first day of year
    var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    
    // Calculate full weeks to nearest Saturday
    var weekNo = Math.ceil(((date - yearStart) / 86400000) / 7);
    
    // Return array of year and week number
    return [date.getUTCFullYear(), weekNo];
}

const getMonthNumber = (date) => {
  // Copy date so don't modify original
  date = new Date(date);

  // Get the year
  const year = date.getUTCFullYear();

  // Get month number (0-11), then add 1 to get (1-12)
  const monthNo = date.getUTCMonth() + 1;

  // Return array of year and month number
  return [year, monthNo];
};

const getQuarterNumber = (date) => {
  // Copy date so don't modify original
  date = new Date(date);

  // Get the year
  const year = date.getUTCFullYear();

  // Get month number (0-11), then calculate quarter (1-4)
  const month = date.getUTCMonth();
  const quarterNo = Math.floor(month / 3) + 1;

  // Return array of year and quarter number
  return [quarterNo];
};


  const groupData = useMemo(() => {
    // Ordena os dados por 'id' antes de agrupá-los
    const sortedData = [...data].sort((a, b) => new Date(b.id) - new Date(a.id));
  
    return groupBy(sortedData, (item) => {
      const id = new Date(item.id);
      switch (groupByType) {
        case 'week':
          //const weekNumber = getWeekNumber(id);
          const weekNumber = getWeekNumber(id).toString().padStart(2, '0');
          return weekNumber;
        case 'month':
          const monthNumber = getMonthNumber(id).toString().padStart(2, '0');
          return monthNumber;
        case 'quarter':
          const quarterNumber = getQuarterNumber(id).toString().padStart(2, '0');
          return quarterNumber;
        case 'year':
          return `(${id.getFullYear()})`;
        default:
          return id.toISOString().split('T')[0];
      }      
    });
    
  }, [data, groupByType]);

  const aggregatedData = useMemo(() => {
    const aggregated = [];
    
    for (const key in groupData) {
      if (groupData.hasOwnProperty(key)) {
        const items = groupData[key];
        const aggregatedItem = { id: key };
        for (const item of items) {
          for (const column in item) {
            if (column !== 'id' && (typeof item[column] === 'number' || typeof item[column] === 'string')) {
              aggregatedItem[column] = (aggregatedItem[column] || 0) + parseFloat(item[column]);
            }
          }
        }

        // Adicionando a coluna ce_miss
      if (aggregatedItem.ce_miss && aggregatedItem.total_units) {
        aggregatedItem.miss = (aggregatedItem.ce_miss / aggregatedItem.total_units * 100).toFixed(1) + '%'
      } else {
        aggregatedItem.miss = 0; // Caso miss ou total_units sejam zero ou indefinidos
      }

      if (aggregatedItem.cancel_qtd && aggregatedItem.total_units) {
        aggregatedItem.cancel = (aggregatedItem.cancel_qtd / aggregatedItem.total_units * 100).toFixed(1) + '%'
      } else {
        aggregatedItem.cancel = 0; // Caso miss ou total_units sejam zero ou indefinidos
      }

      if (aggregatedItem.units_xcv9 && aggregatedItem.total_units) {
        aggregatedItem.xcv9 = (aggregatedItem.total_units / aggregatedItem.units_xcv9 * 100).toFixed(1) + '%'
      } else {
        aggregatedItem.xcv9 = 0; // Caso miss ou total_units sejam zero ou indefinidos
      }

      if (aggregatedItem.units_net && aggregatedItem.total_units) {
        aggregatedItem.net = (aggregatedItem.total_units / aggregatedItem.units_net * 100).toFixed(1) + '%'
      } else {
        aggregatedItem.net = 0; // Caso miss ou total_units sejam zero ou indefinidos
      }

      if (aggregatedItem.total_shipments && aggregatedItem.total_units) {
        aggregatedItem.ups = (aggregatedItem.total_units / aggregatedItem.total_shipments).toFixed(2)
      } else {
        aggregatedItem.ups = 0; // Caso miss ou total_units sejam zero ou indefinidos
      }

      if (aggregatedItem.po_qty_submitted && aggregatedItem.po_qty_received) {
        aggregatedItem.fr = (aggregatedItem.po_qty_received / aggregatedItem.po_qty_submitted * 100).toFixed(1) + '%'
      } else {
        aggregatedItem.fr = 0; // Caso miss ou total_units sejam zero ou indefinidos
      }

      if (aggregatedItem.return_qtd && aggregatedItem.total_units) {
        aggregatedItem.returns = (aggregatedItem.return_qtd / aggregatedItem.total_units * 100).toFixed(1) + '%'
      } else {
        aggregatedItem.returns = 0; // Caso miss ou total_units sejam zero ou indefinidos
      }

      if (aggregatedItem.received_units && aggregatedItem.vlt) {
        aggregatedItem.vlt_2 = ((aggregatedItem.received_units * aggregatedItem.vlt) / aggregatedItem.received_units).toFixed(2);
    } else {
        aggregatedItem.vlt_2 = 0; // Caso miss ou total_units sejam zero ou indefinidos
    }

    if (aggregatedItem.avg_vlt_sum && aggregatedItem.vlt) {
      aggregatedItem.vlt_2 = (aggregatedItem.avg_vlt_sum / aggregatedItem.received_units).toFixed(2);
  } else {
      aggregatedItem.vlt_2 = 0; // Caso miss ou total_units sejam zero ou indefinidos
  }

  if (aggregatedItem.gas_tank_vol && aggregatedItem.total_units) {
    aggregatedItem.turns = ((aggregatedItem.total_units * 52) / aggregatedItem.gas_tank_vol).toFixed(2);
} else {
    aggregatedItem.turns = 0; // Caso miss ou total_units sejam zero ou indefinidos
}
    

      let gasTankVolValue = 0;
      switch (groupByType) {
        case 'week':
          aggregatedItem.gasTankVolValue = sumBy(groupData[key], 'gas_tank_vol') / 10000;
          break;
        case 'day':
              
          aggregatedItem.gasTankVolValue = groupData[key][0].gas_tank_vol;
          break;
        case 'month':
    
        aggregatedItem.gasTankVolValue = sumBy(groupData[key], 'gas_tank_vol') / 40000;
          break;

          case 'quarter':
    
          aggregatedItem.gasTankVolValue = sumBy(groupData[key], 'gas_tank_vol') / 120000;
            break;

          case 'year':
    
            aggregatedItem.gasTankVolValue = sumBy(groupData[key], 'gas_tank_vol') / 480000;
              break;
        default:
          break;
      }


        aggregated.push(aggregatedItem);
      }
    }
    return aggregated;
  }, [groupData]);

  

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error('Error fetching user group information:', error);
    }
  }, []);  

  const aggregatedDataSorted = useMemo(() => {
    // Copie os dados agregados para evitar mutações
    const aggregatedCopy = [...aggregatedData];
  
    // Ordena os dados pelo campo 'id' em ordem decrescente
    aggregatedCopy.sort((a, b) => new Date(b.id) - new Date(a.id));
  
    return aggregatedCopy;
  }, [aggregatedData]);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
    </GridToolbarContainer>
  );
  const handleRefresh = async () => {
    try {
      setLoading(true);
      await fetchData(); // Call fetchData function passed from props
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };
  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center">
        
          <Typography
            sx={{
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          
          >
            Group By:
          </Typography>
          {groupTypes.map(type => (
            <Chip
              key={type}
              label={type}
              variant={groupByType === type ? 'filled' : 'outlined'}
              color={groupByType === type ? 'primary' : 'default'}
              onClick={() => handleGroupByChange(type)}
            />
            
          ))}
                  <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={onRefresh}
            sx={{ marginTop: '10px' }}
          >
            Refresh Data
          </Button>
        </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row">
      {/* Primeira tabela */}
      <Grid item lg={6} xs={12} md={6}>
        <Typography
          sx={{
            marginTop: '2vh',
            marginLeft: 3,
            fontFamily: 'Source Sans Pro',
            fontSize: 'calc(15px + 0.5vh)',
            fontWeight: 'bold',
          }}
        >
          Inbound
        </Typography>
        <Typography
          sx={{
            marginTop: 0,
            marginLeft: 3,
            fontFamily: 'Source Sans Pro',
            fontSize: 'calc(7px + 0.5vh)',
            fontWeight: 'lighter',
          }}
        >
        </Typography>
        {loading ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ marginTop: '20vh' }}>
              <Loader size="large" />
            </div>
          </div>
        ) : (
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 1,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '1px solid #e0e0e0',
              borderRadius: '10px',
              minHeight: '40vh',
              maxHeight: '90vh',
              overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={aggregatedData}
            columns={[
              { field: 'id', headerName: 'Date', flex: 2 },
              { field: 'received_units', headerName: 'Received Units', flex: 2 },
              { field: 'po_qty_open', headerName: 'Open PO', flex: 2 },
              //{ field: 'po_qty_received', headerName: 'PO Qtd Received ', flex: 2 },
              //{ field: 'po_qty_submitted', headerName: 'Submitted PO', flex: 2 },
              { field: 'fr', headerName: 'Fill Rate', flex: 2 },
              { field: 'vlt_2', headerName: 'VLT', flex: 2 },
              { field: 'turns', headerName: 'Turns VF', flex: 2 },
            ]}//
            components={{
              Toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            editMode="row"
            disableColumnSelector
            disableDensitySelector
            initialState={{
              pagination: { paginationModel: { pageSize: 50 } },
            }}
          />
        )}
      </Grid>

      {/* Segunda tabela */}
      <Grid item lg={6} xs={12} md={6}>
        <Typography
          sx={{
            marginTop: '2vh',
            marginLeft: 3,
            fontFamily: 'Source Sans Pro',
            fontSize: 'calc(15px + 0.5vh)',
            fontWeight: 'bold',
          }}
        >
          Inventory
        </Typography>
        <Typography
          sx={{
            marginTop: 0,
            marginLeft: 3,
            fontFamily: 'Source Sans Pro',
            fontSize: 'calc(7px + 0.5vh)',
            fontWeight: 'lighter',
          }}
        >
        </Typography>
        {loading ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ marginTop: '20vh' }}>
              <Loader size="large" />
            </div>
          </div>
        ) : (
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 1,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '1px solid #e0e0e0',
              borderRadius: '10px',
              minHeight: '40vh',
              maxHeight: '90vh',
              overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={aggregatedData}
            columns={[
              { field: 'id', headerName: 'Date', flex: 2 },
              { field: 'gasTankVolValue', headerName: 'Gas Tank', flex: 2 },
              { field: 'return_qtd', headerName: 'Customer Returns', flex: 2 },
              { field: 'returns', headerName: 'Return Rate', flex: 2 },
            ]}
            components={{
              Toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            editMode="row"
            disableColumnSelector
            disableDensitySelector
            initialState={{
              pagination: { paginationModel: { pageSize: 50 } },
            }}
          />
        )}
      </Grid>
    </Grid>

    {/* Terceira tabela */}
    <Grid container spacing={2} direction="row">
      <Grid item lg={12} xs={12} md={12}>
        <Typography
          sx={{
            marginTop: '2vh',
            marginLeft: 3,
            fontFamily: 'Source Sans Pro',
            fontSize: 'calc(15px + 0.5vh)',
            fontWeight: 'bold',
          }}
        >
          Outbound
        </Typography>
        <Typography
          sx={{
            marginTop: 0,
            marginLeft: 3,
            fontFamily: 'Source Sans Pro',
            fontSize: 'calc(7px + 0.5vh)',
            fontWeight: 'lighter',
          }}
        >
        </Typography>
        {loading ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ marginTop: '20vh' }}>
              <Loader size="large" />
            </div>
          </div>
        ) : (
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 1,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '1px solid #e0e0e0',
              borderRadius: '10px',
              minHeight: '40vh',
              maxHeight: '90vh',
              overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={aggregatedData}
            columns={[
              { field: 'id', headerName: 'Date', flex: 2 },
              { field: 'total_units', headerName: 'Total Units', flex: 2 },
              { field: 'ups', headerName: 'UPS', flex: 2 },
              { field: 'net', headerName: 'Share Network', flex: 2 },
              { field: 'xcv9', headerName: 'Share XCV9', flex: 2 },
              { field: 'cancel_qtd', headerName: 'Cancellation Units', flex: 2 },
              { field: 'cancel', headerName: 'Cancellation Rate', flex: 2 },
              { field: 'ce_miss', headerName: 'CE Miss', flex: 2 },
              { field: 'miss', headerName: 'CE Miss %', flex: 2 },
            ]}
            components={{
              Toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            editMode="row"
            disableColumnSelector
            disableDensitySelector
            initialState={{
              pagination: { paginationModel: { pageSize: 50 } },
            }}
          />
        )}
      </Grid>
    </Grid>



    </div>

    
    );

    
};

export default (Summary);
