import { listESDailyOrderStatuses } from '../../../graphql/queries';
import { onCreateSFCPTManagement, onUpdateSFCPTManagement, onDeleteSFCPTManagement } from '../../../graphql/subscriptions';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { API, Auth, graphqlOperation  } from 'aws-amplify';

import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid } from '@mui/material';
import { Loader } from '@aws-amplify/ui-react';
import { DynamoDBScanExpression } from '@aws-amplify/datastore';
import { DataStore } from 'aws-amplify';
import { ESDailyOrderStatus } from '../../../models/index'; // Import your data model
import * as subscriptions from '../../../graphql/subscriptions';
import debounce from 'lodash.debounce';

import { Amplify, } from 'aws-amplify';
//import { EasyShipOrderStatus } from '../../../models/schema'; // Import your data model



function PostList() {
  const [posts, setPosts] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [optimisticPost, setOptimisticPost] = useState(null);
  const [todos, setTodos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSynced, setIsSynced] = useState(false); 


  useEffect(() => {
    DataStore.configure({
      syncPageSize: 100,
      maxRecordsToSync: 10000000,
    });

    const setupSubscription = async () => {
      try {
        const subscription = DataStore.observeQuery(ESDailyOrderStatus)
          .subscribe(({ items, isSynced }) => {
            console.log('Subscription callback triggered');
            console.log('is synced', isSynced);
            setTodos(items);
            setIsSynced(isSynced);
          });

        return () => subscription.unsubscribe();
      } catch (error) {
        console.error('Error setting up subscription:', error);
      }
    };

    setupSubscription();
  }, []);
  /*
  useEffect(() => {
    const subscription = DataStore.observeQuery(ESDailyOrderStatus)
      .subscribe(({ items, isSynced }) => {
        console.log('Subscription callback triggered');
        console.log('is synced', isSynced);
        setTodos(items);
        setIsSynced(isSynced); // Update sync status
      });

    return () => subscription.unsubscribe();
  }, []);
  */
  const sortedData = useMemo(() => {
    const currentDate = new Date();

    // Calculate yesterday's date
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);

    const filteredItems = todos.filter(item => 
      new Date(item.estimatedScheduleEndDate) >= yesterday
    );
    const sortedItems = filteredItems.sort((a, b) => {
      const dateComparison = new Date(a.estimatedScheduleEndDate) - new Date(b.estimatedScheduleEndDate);
      if (dateComparison !== 0) {
        return dateComparison;
      }
      return b.orders - a.orders;
    });

    return sortedItems;
  }, [todos]);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarQuickFilter debounceMs={500} />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
  
  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={6} md={6}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
          ES - Seller Order Status Monitoring
          { !isSynced && <Loader size="large" sx={{ marginLeft: '40px' }} /> }
          </Typography>
        </Grid>
      </Grid>
      <Typography
        sx={{
          marginTop: 0,
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(7px + 0.5vh)',
          fontWeight: 'lighter',
        }}
      >
        Order Status Monitoring
      </Typography>

        <DataGrid
          sx={{
            marginTop: '2vh',
            marginLeft: 1,
            marginRight: 2,
            padding: '10px 18px 18px 18px',
            backgroundColor: "#FFFFFF",
            border: '1px solid #e0e0e0',
            borderRadius: '10px',
            minHeight: '40vh',
            maxHeight: '90vh',
            overflow: 'auto', // Enable vertical scrolling if needed
          }}
          rows={sortedData}
          columns={[
            { field: 'merchantId', headerName: 'merchant_id', flex: 1 },
            { field: 'ops_owner', headerName: 'ops_owner', flex: 1, align: 'center', headerAlign: 'center' },
            { field: 'seller_name', headerName: 'seller_name', flex: 1, align: 'center', headerAlign: 'center' },
            { field: 'orderStatus', headerName: 'order_status', flex: 1, align: 'center', headerAlign: 'center' },
            { field: 'estimatedScheduleEndDate', headerName: 'estimatedScheduleEndDate', flex: 1, align: 'center', headerAlign: 'center' },
            { field: 'orders', headerName: 'orders', flex: 1, align: 'center', headerAlign: 'center' },

          ]}
          components={{
            Toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          editMode="row"
          disableColumnSelector
          disableDensitySelector
          initialState={{
            pagination: { pageSize: 100 },
            sortModel: [{ field: 'deviation', sort: 'asc' }],
          }}
        />

    </div>
  );

}
export default PostList;

