import { useState, useEffect, useMemo } from "react";
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Grid } from '@mui/material';
import awsconfig from '../../../aws-exports';
import './summary.css';
import { withAuthenticator, Loader } from '@aws-amplify/ui-react';
import { listSellerDataPCP2s, listPCPDefects, listSummaryPCPS} from "../../../graphql/queries";
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import tableColumns from './seller_data_columns';
import './table-styles.css';
import { startOfWeek, startOfMonth, startOfQuarter, startOfYear } from 'date-fns';
import { groupBy, orderBy } from 'lodash';
import {Select, InputLabel, MenuItem, FormControl, Avatar } from '@mui/material';
import summary from "./summary";

Amplify.configure(awsconfig);

const groupTypes = ['day', 'week', 'month'];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const CustomToolbar = () => (
  <GridToolbarContainer
    sx={{
      marginBottom: '10px',
    }}
    spacing={2}
  >
    <GridToolbarExport />
    <GridToolbarQuickFilter
      sx={{
        position: 'absolute',
        padding: '18px',
        right: '15px'
      }}
    />
  </GridToolbarContainer>
);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const getStartAndEndOfWeek = (date) => {
  const start = new Date(date);
  const end = new Date(date);
  start.setDate(start.getDate() - start.getDay() + 1); // Monday
  end.setDate(end.getDate() - start.getDay() + 7); // Sunday
  return [start.toISOString().split('T')[0], end.toISOString().split('T')[0]];
};

const getStartAndEndOfMonth = (date) => {
  const start = new Date(date.getFullYear(), date.getMonth(), 1);
  const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return [start.toISOString().split('T')[0], end.toISOString().split('T')[0]];
};

const getStartAndEndOfPreviousMonth = (date) => {
  const month = date.getMonth() === 0 ? 11 : date.getMonth() - 1;
  const year = date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
  const start = new Date(year, month, 1);
  const end = new Date(year, month + 1, 0);
  return [start.toISOString().split('T')[0], end.toISOString().split('T')[0]];
};

const isFirstMondayOfMonth = (date) => {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const firstMonday = new Date(firstDayOfMonth);
  firstMonday.setDate(firstDayOfMonth.getDate() + ((1 - firstDayOfMonth.getDay() + 7) % 7));
  return date.toDateString() === firstMonday.toDateString();
};

const getFilterDates = (date, groupByType) => {
  const today = new Date(date);
  const isMonday = today.getDay() === 1;

  if (groupByType === 'day') {
    if (isMonday) {
      const lastFriday = new Date(today);
      lastFriday.setDate(today.getDate() - (today.getDay() + 2) % 7);
      return [lastFriday.toISOString().split('T')[0], lastFriday.toISOString().split('T')[0]];
    } else {
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      return [yesterday.toISOString().split('T')[0], yesterday.toISOString().split('T')[0]];
    }
  }

  if (groupByType === 'week') {
    if (isMonday) {
      const lastWeekStart = new Date(today);
      lastWeekStart.setDate(today.getDate() - 7);
      const lastWeekEnd = new Date(lastWeekStart);
      lastWeekEnd.setDate(lastWeekStart.getDate() + 6);
      return [lastWeekStart.toISOString().split('T')[0], lastWeekEnd.toISOString().split('T')[0]];
    } else {
      return getStartAndEndOfWeek(today);
    }
  }

  if (groupByType === 'month') {
    if (isFirstMondayOfMonth(today)) {
      return getStartAndEndOfPreviousMonth(today);
    } else {
      return getStartAndEndOfMonth(today);
    }
  }

  return [null, null]; // Handle other cases if needed
};

const Summary = ({ loading, onRefresh }) => {
  const [isLoading, setLoading] = useState(loading);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [groupByType, setGroupByType] = useState('week');
  const [totalConfirmedPackages, setTotalConfirmedPackages] = useState(0);

  const fetchData = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      const [startDate, endDate] = getFilterDates(new Date(), groupByType);
      do {
        const response = await API.graphql(
          graphqlOperation(listPCPDefects, { limit: 20000, nextToken })
        );
        const fetchedItems = response.data.listPCPDefects.items;
        const filteredItems = fetchedItems.filter(item => {
          const itemDate = new Date(item.date_value).toISOString().split('T')[0];
          return (!startDate || itemDate >= startDate) && (!endDate || itemDate <= endDate);
        });
        items = items.concat(filteredItems);
        nextToken = response.data.listPCPDefects.nextToken;
      } while (nextToken);
  
      console.log("Fetched defect data:", items); // Check the data here
      setData(items);
      setLoading(true);
      return items; // Ensure to return the items
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(true);
      return []; // Return an empty array in case of error
    }
  };
  


  const fetchDataSD = async () => {
    try {
      setLoading(true);
      let nextToken = null;
      const items = [];
      do {
        const response = await API.graphql(graphqlOperation(listSellerDataPCP2s, {
          limit: 200000,
          nextToken: nextToken
        }));

        const fetchedItems = response.data.listSellerDataPCP2s.items;
        items.push(...fetchedItems);

        nextToken = response.data.listSellerDataPCP2s.nextToken;
      } while (nextToken);
      return items;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchDataFDPS = async () => {
    try {
      setLoading(true);
      let nextToken = null;
      const items = [];
      do {
        const response = await API.graphql(graphqlOperation(listSummaryPCPS, {
          limit: 200000,
          nextToken: nextToken
        }));

        const fetchedItems = response.data.listSummaryPCPS.items;
        items.push(...fetchedItems);

        nextToken = response.data.listSummaryPCPS.nextToken;
      } while (nextToken);
      return items;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const mergeData = (sellerData, defectData) => {
    console.log('Seller Data:', sellerData);
    console.log('Defect Data:', defectData);
  
    const mergedData = defectData.map(defect => {
      const seller = sellerData.find(seller => defect.merchant_id === seller.seller_id);
      console.log('Defect:', defect);
      console.log('Found Seller:', seller);
  
      return {
        ...defect,
        ops_support: seller ? seller.ops_support : null,
        seller_name: seller ? seller.seller_name : null
      };
    });
  
    console.log('Merged Data:', mergedData);
    return mergedData;
  };

  const mergeData2 = (sellerData, fdpsData) => {
    console.log('Seller Data:', sellerData);
    console.log('Defect Data:', fdpsData);
  
    const mergedData2 = fdpsData.map(fdps => {
      const seller = sellerData.find(seller => fdps.merchant_id === seller.seller_id);
      console.log('FDPS:', fdps);
      console.log('Found Seller:', seller);
  
      return {
        ...fdps,
        ops_support: seller ? seller.ops_support : null,
        seller_name: seller ? seller.seller_name : null
      };
    });
  
    console.log('Merged Data:', mergedData2);
    return mergedData2;
  };


  useEffect(() => {
    fetchData();
  }, [groupByType]);

  useEffect(() => {
    const total = data.reduce((acc, item) => acc + (item.total || 0), 0);
    setTotalConfirmedPackages(total);
  }, [data]);

  useEffect(() => {
    const fetchDataAndMerge = async () => {
      try {
        const sellerData = await fetchDataSD();
        const defectData = await fetchData();
        const mergedData = mergeData(sellerData, defectData);
        setData(mergedData);
        setLoading(true);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(true);
      }
    };

    fetchDataAndMerge();
  }, []);

  useEffect(() => {
    const fetchDataAndMerge2 = async () => {
      try {
        const sellerData = await fetchDataSD();
        const sellerFDPS = await fetchDataFDPS();
        const mergedData2 = mergeData2(sellerData, sellerFDPS);
        setData2(mergedData2);
        setLoading(true);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(true);
      }
    };

    fetchDataAndMerge2();
  }, []);

  const handleGroupByChange = (type) => {
    setGroupByType(type);
  };

  const getWeekNumber = (date_value) => {
    date_value = new Date(date_value);
    date_value.setUTCDate(date_value.getUTCDate() - date_value.getUTCDay() + 6);
    const yearStart = new Date(Date.UTC(date_value.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil(((date_value - yearStart) / 86400000) / 7);
    return [date_value.getUTCFullYear(), weekNo];
  };

  const getMonthNumber = (date) => {
    date = new Date(date);
    const year = date.getUTCFullYear();
    const monthNo = date.getUTCMonth() + 1;
    return [year, monthNo];
  };

  const getQuarterNumber = (date) => {
    date = new Date(date);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const quarterNo = Math.floor(month / 3) + 1;
    return [month, quarterNo];
  };

  const aggregatedDataById2AmazonComments2 = useMemo(() => {
    const groupedById2 = groupBy(data, (item) => {
      const expected_ship_date = new Date(item.date_value);
      let groupKey;
      switch (groupByType) {
        case 'day':
          groupKey = expected_ship_date.toISOString().split('T')[0];
          break;
        case 'week':
          const weekNumber = getWeekNumber(expected_ship_date)[1];
          groupKey = `${expected_ship_date.getFullYear()} Week ${weekNumber.toString().padStart(2, '0')}`;
          break;
        case 'month':
          const monthNumber = getMonthNumber(expected_ship_date)[1];
          groupKey = `${expected_ship_date.getFullYear()} Month ${monthNumber.toString().padStart(2, '0')}`;
          break;
        default:
          groupKey = expected_ship_date.toISOString().split('T')[0];
      }
      return `${groupKey}_${item.merchant_id || 'N/A'}_${item.ops_support || 'N/A'}_${item.seller_name || 'N/A'}`;
    });

    const aggregatedById2 = [];
    for (const key in groupedById2) {
      if (groupedById2.hasOwnProperty(key)) {
        const items = groupedById2[key];
        const [groupKey, merchant_id, ops_support, seller_name] = key.split('_');
        const aggregatedItem = { groupKey, merchant_id, ops_support, seller_name, id: key };
        for (const item of items) {
          for (const column in item) {
            if (column !== 'date_value' && column !== 'merchant_id' && column !== 'ops_support' && column !== 'seller_name' && (typeof item[column] === 'number' || typeof item[column] === 'string')) {
              aggregatedItem[column] = (aggregatedItem[column] || 0) + parseFloat(item[column]);
            }
          }
        }
        aggregatedById2.push(aggregatedItem);
      }
    }

    // Ordenar os dados pelo valor da coluna 'hdsc' do maior para o menor
    return aggregatedById2;
  }, [data, groupByType]);


  const aggregatedDataById2AmazonComments3 = useMemo(() => {

      const filteredData = data2.filter(item => (
        item.amazon_comments &&
        (item.amazon_comments.includes("Seller didn't provide the packages") ||
         item.amazon_comments.includes('Seller Unavailable') ||
         item.amazon_comments.includes('Incorrect Address') ||
         item.amazon_comments.includes('- Seller'))
      ));
      const groupedById3 = groupBy(filteredData, (item) => {
      const expected_ship_date = new Date(item.expected_ship_date);
      let groupKey;
      switch (groupByType) {
        case 'day':
          groupKey = expected_ship_date.toISOString().split('T')[0];
          break;
        case 'week':
          const weekNumber = getWeekNumber(expected_ship_date)[1];
          groupKey = `${expected_ship_date.getFullYear()} Week ${weekNumber.toString().padStart(2, '0')}`;
          break;
        case 'month':
          const monthNumber = getMonthNumber(expected_ship_date)[1];
          groupKey = `${expected_ship_date.getFullYear()} Month ${monthNumber.toString().padStart(2, '0')}`;
          break;
        default:
          groupKey = expected_ship_date.toISOString().split('T')[0];
      }
      return `${groupKey}_${item.merchant_id || 'N/A'}_${item.ops_support || 'N/A'}_${item.seller_name || 'N/A'}`;
    });

    const aggregatedById3 = [];
    for (const key in groupedById3) {
      if (groupedById3.hasOwnProperty(key)) {
        const items2 = groupedById3[key];
        const [groupKey, merchant_id, ops_support, seller_name] = key.split('_');
        const aggregatedItem2 = { groupKey, merchant_id, ops_support, seller_name, id: key };
        for (const item2 of items2) {
          for (const column in item2) {
            if (column !== 'expected_ship_date' && column !== 'merchant_id' && column !== 'ops_support' && column !== 'seller_name' && (typeof item2[column] === 'number' || typeof item2[column] === 'string')) {
              aggregatedItem2[column] = (aggregatedItem2[column] || 0) + parseFloat(item2[column]);
            }
          }
        }
        aggregatedById3.push(aggregatedItem2);
      }
    }

    // Ordenar os dados pelo valor da coluna 'hdsc' do maior para o menor
    return aggregatedById3;
  }, [data2, groupByType]);

  const aggregatedDataById2AmazonComments4 = useMemo(() => {

    const filteredData2 = data2.filter(item => (
      item.amazon_comments &&
      (item.amazon_comments === "Bip at Carrier's Facility" ||
        item.amazon_comments === 'Carrier No Show' ||
        item.amazon_comments === 'Others')
    ));
    const groupedById4 = groupBy(filteredData2, (item) => {
    const expected_ship_date = new Date(item.expected_ship_date);
    let groupKey;
    switch (groupByType) {
      case 'day':
        groupKey = expected_ship_date.toISOString().split('T')[0];
        break;
      case 'week':
        const weekNumber = getWeekNumber(expected_ship_date)[1];
        groupKey = `${expected_ship_date.getFullYear()} Week ${weekNumber.toString().padStart(2, '0')}`;
        break;
      case 'month':
        const monthNumber = getMonthNumber(expected_ship_date)[1];
        groupKey = `${expected_ship_date.getFullYear()} Month ${monthNumber.toString().padStart(2, '0')}`;
        break;
      default:
        groupKey = expected_ship_date.toISOString().split('T')[0];
    }
    return `${groupKey}_${item.merchant_id || 'N/A'}_${item.ops_support || 'N/A'}_${item.seller_name || 'N/A'}`;
  });

  const aggregatedById4 = [];
  for (const key in groupedById4) {
    if (groupedById4.hasOwnProperty(key)) {
      const items2 = groupedById4[key];
      const [groupKey, merchant_id, ops_support, seller_name] = key.split('_');
      const aggregatedItem2 = { groupKey, merchant_id, ops_support, seller_name, id: key };
      for (const item2 of items2) {
        for (const column in item2) {
          if (column !== 'expected_ship_date' && column !== 'merchant_id' && column !== 'ops_support' && column !== 'seller_name' && (typeof item2[column] === 'number' || typeof item2[column] === 'string')) {
            aggregatedItem2[column] = (aggregatedItem2[column] || 0) + parseFloat(item2[column]);
          }
        }
      }
      aggregatedById4.push(aggregatedItem2);
    }
  }

  // Ordenar os dados pelo valor da coluna 'hdsc' do maior para o menor
  return aggregatedById4;
}, [data2, groupByType]);

  const sortedHDSC = orderBy(aggregatedDataById2AmazonComments2, ['hdsc'], ['desc']);
  const sortedPREP = orderBy(aggregatedDataById2AmazonComments2, ['prep'], ['desc']);
  const sortedRPI = orderBy(aggregatedDataById2AmazonComments2, ['rpi'], ['desc']);
  const sortedISS = orderBy(aggregatedDataById2AmazonComments2, ['iss_inbounded'], ['desc']);
  const sortedISSnot = orderBy(aggregatedDataById2AmazonComments2, ['iss_not_inbounded'], ['desc']);
  const sortedFDPS = orderBy(aggregatedDataById2AmazonComments3, ['total'], ['desc']);
  const sortedFDPSCarrier = orderBy(aggregatedDataById2AmazonComments4, ['total'], ['desc']);

  const columnsHDSC = [
    { field: 'groupKey', headerName: 'Group Key', width: 150 },
    { field: 'merchant_id', headerName: 'Merchant ID', width: 150, align: 'center', headerAlign: 'center' },
    { field: 'seller_name', headerName: 'Seller Name', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'ops_support', headerName: 'Ops Owner', flex: 1, align: 'center', headerAlign: 'center',
    renderCell: (params) => {
      const opsOwner = params?.value;
  
      if (opsOwner) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
            <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
          </div>
        );
      }
      return null; 
    }},
    { field: 'hdsc', headerName: 'HDSC', type: 'number', flex: 1, align: 'center', headerAlign: 'center', sortComparator: (v1, v2) => v2 - v1 },
  ];

  const columnsFDPS = [
    { field: 'groupKey', headerName: 'Group Key', width: 150 },
    { field: 'merchant_id', headerName: 'Merchant ID', width: 150, align: 'center', headerAlign: 'center' },
    { field: 'seller_name', headerName: 'Seller Name', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'ops_support', headerName: 'Ops Owner', flex: 1, align: 'center', headerAlign: 'center',
    renderCell: (params) => {
      const opsOwner = params?.value;
  
      if (opsOwner) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
            <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
          </div>
        );
      }
      return null; 
    }},
    { field: 'total', headerName: 'FDPS', type: 'number', flex: 1, align: 'center', headerAlign: 'center', sortComparator: (v1, v2) => v2 - v1 },
  ];

  const columnsprep = [
    { field: 'groupKey', headerName: 'Group Key', width: 150 },
    { field: 'merchant_id', headerName: 'Merchant ID', width: 150, align: 'center', headerAlign: 'center' },
    { field: 'seller_name', headerName: 'Seller Name', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'ops_support', headerName: 'Ops Owner', flex: 1, align: 'center', headerAlign: 'center',
    renderCell: (params) => {
      const opsOwner = params?.value;
  
      if (opsOwner) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
            <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
          </div>
        );
      }
      return null; 
    }},
    { field: 'prep', headerName: 'PREP', type: 'number', flex: 1, align: 'center', headerAlign: 'center', sortComparator: (v1, v2) => v2 - v1 },
  ];

  const columnsRPI = [
    { field: 'groupKey', headerName: 'Group Key', width: 150 },
    { field: 'merchant_id', headerName: 'Merchant ID', width: 150, align: 'center', headerAlign: 'center' },
    { field: 'seller_name', headerName: 'Seller Name', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'ops_support', headerName: 'Ops Owner', flex: 1, align: 'center', headerAlign: 'center',
    renderCell: (params) => {
      const opsOwner = params?.value;
  
      if (opsOwner) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
            <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
          </div>
        );
      }
      return null; 
    }},
    { field: 'rpi', headerName: 'RPI', type: 'number', flex: 1, align: 'center', headerAlign: 'center', sortComparator: (v1, v2) => v2 - v1 },
  ];

  const columnsiss = [
    { field: 'groupKey', headerName: 'Group Key', width: 150 },
    { field: 'merchant_id', headerName: 'Merchant ID', width: 150, align: 'center', headerAlign: 'center' },
    { field: 'seller_name', headerName: 'Seller Name', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'ops_support', headerName: 'Ops Owner', flex: 1, align: 'center', headerAlign: 'center',
    renderCell: (params) => {
      const opsOwner = params?.value;
  
      if (opsOwner) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
            <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
          </div>
        );
      }
      return null; 
    }},
    { field: 'iss_inbounded', headerName: 'ISS Inbounded', type: 'number', flex: 1, align: 'center', headerAlign: 'center', sortComparator: (v1, v2) => v2 - v1 },
  ];

  const columnsissnot = [
    { field: 'groupKey', headerName: 'Group Key', width: 150 },
    { field: 'merchant_id', headerName: 'Merchant ID', width: 150, align: 'center', headerAlign: 'center' },
    { field: 'seller_name', headerName: 'Seller Name', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'ops_support', headerName: 'Ops Owner', flex: 1, align: 'center', headerAlign: 'center',
    renderCell: (params) => {
      const opsOwner = params?.value;
  
      if (opsOwner) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
            <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
          </div>
        );
      }
      return null; 
    }},
    { field: 'iss_not_inbounded', headerName: 'ISS not Inbounded', type: 'number', flex: 1, align: 'center', headerAlign: 'center', sortComparator: (v1, v2) => v2 - v1 },
  ];

  const tableContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    marginTop: '20px'
  };

  const tableStyle = {
    flex: 1,
    height: '600px'
  };

  return (
    <div>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', justifyContent: 'center' }}>
        {groupTypes.map((type) => (
          <Button key={type} variant={groupByType === type ? 'contained' : 'outlined'} onClick={() => handleGroupByChange(type)}>
            {type}
          </Button>
        ))}
      </div>
      {loading ? (
        <Loader />
      ) : (
        

        <Grid container spacing={8} direction="row">
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography
          sx={{
            marginTop: '2vh',
            marginLeft: 3,
            fontFamily: 'Source Sans Pro',
            fontSize: 'calc(15px + 0.5vh)',
            fontWeight: 'bold',
          }}
        >
          HDSC
        </Typography>
        <Typography
          sx={{
            marginTop: 0,
            marginLeft: 3,
            fontFamily: 'Source Sans Pro',
            fontSize: 'calc(15px + 0.5vh)', 
            fontWeight: 'lighter',
          }}
        >
        </Typography>
        
      {loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginTop: '20vh' }}>
            <Loader size="large" /> 
          </div>
        </div>
        ) : (
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 2,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '0px',
              borderRadius: '10px',
              minHeight: '30vh',
              maxHeight: '90vh',
              overflow: 'auto', // Enable vertical scrolling if needed
            }}
            
            rows={sortedHDSC}
            columns={columnsHDSC}
            components={{
                Toolbar: CustomToolbar,
            }}
            disableColumnSelector
            disableDensitySelector
            pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              
          />
        )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          
        <Typography
        sx={{
          marginTop: '2vh',
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(15px + 0.5vh)',
          fontWeight: 'bold',
        }}
      >
        RPI
      </Typography>
      <Typography
        sx={{
          marginTop: 0,
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(7px + 0.5vh)',
          fontWeight: 'lighter',
        }}
      >
        
      </Typography>
      {loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginTop: '20vh' }}>
            <Loader size="large" /> 
          </div>
        </div>
        ) : (
        <DataGrid
            sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                minHeight: '40vh',
                maxHeight: '90vh'
                ,overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={sortedRPI}
            columns={columnsRPI}
            components={{
                Toolbar: CustomToolbar,
            }}
            disableColumnSelector
            disableDensitySelector
            pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
               
            />
        )}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
        <Typography
        sx={{
          marginTop: '2vh',
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(15px + 0.5vh)',
          fontWeight: 'bold',
        }}
      >
        ISS Inbounded
      </Typography>
      <Typography
        sx={{
          marginTop: 0,
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(7px + 0.5vh)',
          fontWeight: 'lighter',
        }}
      >
      </Typography>
      {loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginTop: '20vh' }}>
            <Loader size="large" /> 
          </div>
        </div>
        ) : (
      <DataGrid
            sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                minHeight: '40vh',
                maxHeight: '90vh',
                overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={sortedISS}
            columns={columnsiss}
            components={{
                Toolbar: CustomToolbar,
            }}
            disableColumnSelector
            disableDensitySelector
            pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
             
            />
        )}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
        <Typography
        sx={{
          marginTop: '2vh',
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(15px + 0.5vh)',
          fontWeight: 'bold',
        }}
      >
        ISS Not Inbounded
      </Typography>
      <Typography
        sx={{
          marginTop: 0,
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(7px + 0.5vh)',
          fontWeight: 'lighter',
        }}
      >
      </Typography>
      {loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginTop: '20vh' }}>
            <Loader size="large" /> 
          </div>
        </div>
        ) : (
      <DataGrid
            sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                minHeight: '40vh',
                maxHeight: '90vh',
                overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={sortedISSnot}
            columns={columnsissnot}
            components={{
                Toolbar: CustomToolbar,
            }}
            disableColumnSelector
            disableDensitySelector
            pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
             
            />
        )}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
        <Typography
        sx={{
          marginTop: '2vh',
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(15px + 0.5vh)',
          fontWeight: 'bold',
        }}
      >
        PREP
      </Typography>
      <Typography
        sx={{
          marginTop: 0,
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(7px + 0.5vh)',
          fontWeight: 'lighter',
        }}
      >
      </Typography>
      {loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginTop: '20vh' }}>
            <Loader size="large" /> 
          </div>
        </div>
        ) : (
      <DataGrid
            sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                minHeight: '40vh',
                maxHeight: '90vh',
                overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={sortedPREP}
            columns={columnsprep}
            components={{
                Toolbar: CustomToolbar,
            }}
            
            disableColumnSelector
            disableDensitySelector
            pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
             
            />
        )}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
        <Typography
        sx={{
          marginTop: '2vh',
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(15px + 0.5vh)',
          fontWeight: 'bold',
        }}
      >
        FDPS Seller Miss
      </Typography>
      <Typography
        sx={{
          marginTop: 0,
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(7px + 0.5vh)',
          fontWeight: 'lighter',
        }}
      >
      </Typography>
      {loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginTop: '20vh' }}>
            <Loader size="large" /> 
          </div>
        </div>
        ) : (
      <DataGrid
            sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                minHeight: '40vh',
                maxHeight: '90vh',
                overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={sortedFDPS}
            columns={columnsFDPS}
            components={{
                Toolbar: CustomToolbar,
            }}
            
            disableColumnSelector
            disableDensitySelector
            pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
             
            />
        )}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
        <Typography
        sx={{
          marginTop: '2vh',
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(15px + 0.5vh)',
          fontWeight: 'bold',
        }}
      >
        FDPS Carrier Miss
      </Typography>
      <Typography
        sx={{
          marginTop: 0,
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(7px + 0.5vh)',
          fontWeight: 'lighter',
        }}
      >
      </Typography>
      {loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginTop: '20vh' }}>
            <Loader size="large" /> 
          </div>
        </div>
        ) : (
      <DataGrid
            sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                minHeight: '40vh',
                maxHeight: '90vh',
                overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={sortedFDPSCarrier}
            columns={columnsFDPS}
            components={{
                Toolbar: CustomToolbar,
            }}
            
            disableColumnSelector
            disableDensitySelector
            pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
             
            />
        )}
        </Grid>

      </Grid>

      
    )}
  </div>
);
};


export default withAuthenticator(Summary);
