import { useState, useEffect, useCallback, useMemo } from "react";
import { listForecastSFS } from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import awsconfig from '../../../aws-exports';
import { groupBy, sumBy } from 'lodash';
import { Loader } from '@aws-amplify/ui-react';


Amplify.configure(awsconfig);

const SfForecast = ({ username, userGroup }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);


  const fetchData = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;

      // Calculate the date for 2 weeks ago
      const twoWeeksAgo = new Date();
      twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
      const referenceDay = twoWeeksAgo.toISOString().split('T')[0];


      do {
        const response = await API.graphql(
          graphqlOperation(listForecastSFS, { 
            limit: 20000, nextToken })
        );
        const fetchedItems = response.data.listForecastSFS.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listForecastSFS.nextToken;
      } while (nextToken);
  
      setData(items);

      const dataWithDeviation = items.map(item => ({
        ...item,
        deviation: item.capacity === 0 ? 0 : ((item.forecast - item.capacity) / item.capacity) * 100
      }));
  
      setData(dataWithDeviation);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
      try {
        fetchData();
      } catch (error) {
        console.error('Error fetching user group information:', error);
      }
  }, []);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
    </GridToolbarContainer>
  );

  const handleRefreshData = async () => {
    try {
      setLoading(true);
      await fetchData();
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };

  const sortedData = useMemo(() => {
    return data.slice().sort((a, b) => b.deviation - a.deviation); 
  }, [data]);

  const topPositiveDeviations = useMemo(() => {
    return sortedData.filter(item => item.deviation > 0); 
  }, [sortedData]);

  const topNegativeDeviations = useMemo(() => {
    return sortedData.filter(item => item.deviation < 0).sort((a, b) => a.deviation - b.deviation); 
  }, [sortedData]);

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={12} style={{ textAlign: 'center' }}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            SF - Forecast vs Capacity
          </Typography>
        </Grid>
      </Grid>
      <Typography
        sx={{
          marginTop: 0,
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(7px + 0.5vh)',
          fontWeight: 'lighter',
        }}
      >
      </Typography>

      {loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginTop: '20vh' }}>
            <Loader size="large" />
          </div>
        </div>
      ) : (
        <Grid container spacing={2}>
          {/* Top 10 Positive Deviations */}
          <Grid item lg={6} xs={6} md={6}>
            <Typography variant="h6" gutterBottom sx={{ marginLeft: 3 }}>
              Top Forecast Deviation
            </Typography>
            <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 1,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '1px solid #e0e0e0',
                borderRadius: '10px',
                minHeight: '40vh',
                maxHeight: '90vh',
                overflow: 'auto', // Enable vertical scrolling if needed
              }}
              rows={topPositiveDeviations}
              columns={[
                { field: 'node', headerName: 'Node', flex: 1 },
                { field: 'seller_name', headerName: 'Seller Name', flex: 1, align: 'center', headerAlign: 'center' },
                { field: 'ops_owner', headerName: 'Ops Owner', flex: 1, align: 'center', headerAlign: 'center' },
                { field: 'date', headerName: 'Date', flex: 1, align: 'center', headerAlign: 'center' },
                { field: 'forecast', headerName: 'Forecast', flex: 1, align: 'center', headerAlign: 'center' },
                { field: 'forecast_pkgs', headerName: 'Forecast in pkgs', flex: 1, align: 'center', headerAlign: 'center' },
                { field: 'capacity', headerName: 'Capacity', flex: 1, align: 'center', headerAlign: 'center' },
                {
                  field: 'deviation',
                  headerName: 'Deviation (%)',
                  flex: 1,
                  align: 'center',
                  headerAlign: 'center',
                  valueFormatter: (params) => `${params.value.toFixed(2)}%`
                },
              ]}
              components={{
                Toolbar: CustomToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              editMode="row"
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
              }} 
            />
          </Grid>

          {/* Top 10 Negative Deviations */}
          <Grid item lg={6} xs={6} md={6}>
            <Typography variant="h6" gutterBottom sx={{ marginLeft: 3 }}>
              Top Capacity Deviation  
            </Typography>
            <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 1,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '1px solid #e0e0e0',
                borderRadius: '10px',
                minHeight: '40vh',
                maxHeight: '90vh',
                overflow: 'auto', // Enable vertical scrolling if needed
              }}
              rows={topNegativeDeviations}
              columns={[
                { field: 'node', headerName: 'Node', flex: 1 },
                { field: 'seller_name', headerName: 'Seller Name', flex: 1, align: 'center', headerAlign: 'center' },
                { field: 'ops_owner', headerName: 'Ops Owner', flex: 1, align: 'center', headerAlign: 'center' },
                { field: 'date', headerName: 'Date', flex: 1, align: 'center', headerAlign: 'center' },
                { field: 'forecast', headerName: 'Forecast', flex: 1, align: 'center', headerAlign: 'center' },
                { field: 'capacity', headerName: 'Capacity', flex: 1, align: 'center', headerAlign: 'center' },
                {
                  field: 'deviation',
                  headerName: 'Deviation (%)',
                  flex: 1,
                  align: 'center',
                  headerAlign: 'center',
                  valueFormatter: (params) => `${params.value.toFixed(2)}%`
                },
              ]}
              components={{
                Toolbar: CustomToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              editMode="row"
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
              }} 
            />
          </Grid>
        </Grid>
      )}
    </div>
  );    
};

export default (SfForecast);




