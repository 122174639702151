import PropTypes from 'prop-types';
import { Select, InputLabel, MenuItem, FormControl, Avatar } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { groupBy, sumBy } from 'lodash';

const tableColumns2 = [
  {
    field: "week",
    headerName: "Week",
    headerClassName: 'customHeader',
    type: 'number',
    width: '110',
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: "ops_owner",
    headerName: "Ops Owner",
    headerClassName: 'customHeader',
    width: '100',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const opsOwner = params?.value;
  
      if (opsOwner) {
        return (
          <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
        );
      }
  
      return null;
    },
  },
  {
    field: "MXSD_total",
    headerName: "MXSD total units",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: "MXSD_units",
    headerName: "MXSD blank Units",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: "MXSD_percent",
    headerName: "MXSD filled",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => `${params.value}%`,
    renderCell: (params) => (
      <span style={{ fontWeight: 'bold' }}>{`${params.value}%`}</span>
    ),
  },
  {
    field: "CANCELLATION_total",
    headerName: "SCR total units",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: "CANCELLATION_units",
    headerName: "SCR blank Units",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: "CANCELLATION_percent",
    headerName: "SCR filled",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => `${params.value}%`,
    renderCell: (params) => (
      <span style={{ fontWeight: 'bold' }}>{`${params.value}%`}</span>
    ),
  },
  {
    field: "LH_BY_SELLER_total",
    headerName: "LH total units",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: "LH_BY_SELLER_units",
    headerName: "LH blank units",
    headerClassName: 'customHeader',
    width: '100',
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: "LH_BY_SELLER_percent",
    headerName: "LH filled",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => `${params.value}%`,
    renderCell: (params) => (
      <span style={{ fontWeight: 'bold' }}>{`${params.value}%`}</span>
    ),
  },
];


export default tableColumns2;
