import { useState, useEffect, useCallback, useMemo } from "react";
import { listMQBandQBRS } from "../../../graphql/queries";
import { listMisses } from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import awsconfig from '../../../aws-exports';
import tableColumns from './summary_columns';
import { groupBy, sumBy } from 'lodash';
import './summary.css';
import { Loader } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

const Summary = ({ username, userGroup }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);


  async function fetchData() {
    try {
      setLoading(true);
      const response = await API.graphql(graphqlOperation(listMQBandQBRS, { limit: 100 }));
      const items = response.data.listMQBandQBRS.items;
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  function getCurrentWeek() {
    const now = new Date();
  
    if (now.getDay() === 0 || now.getDay() === 1) { 
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
      const millisecondsTillToday = now - startOfYear;
      const weekNumberToday = Math.ceil(millisecondsTillToday / millisecondsPerWeek);
      
      const previousWeekNumber = weekNumberToday - 1;
      
      return previousWeekNumber;
    } else {
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
      const millisecondsTillToday = now - startOfYear;
      const weekNumber = Math.ceil(millisecondsTillToday / millisecondsPerWeek);
      
      return weekNumber;
    }
  }
  const currentWeekNumber = getCurrentWeek();


  const sortedData1 = useMemo(() => {
    const sortedItems = [...data].sort((a, b) => {
      const weekComparison = b.week - a.week;
      if (weekComparison !== 0) {
        return weekComparison;
      }
    });
  
    return sortedItems;
  }, [data]);


  useEffect(() => {
      try {
        fetchData();
      } catch (error) {
        console.error('Error fetching user group information:', error);
      }
  }, []);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
    </GridToolbarContainer>
  );

  const handleRefreshData = async () => {
    try {
      setLoading(true);
      await fetchData();
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
        <Grid container spacing={2} direction="row">
          <Grid item lg={3} xs={3} md={3}>
            <Typography
                sx={{
                    marginTop: '2vh',
                    marginLeft: 3,
                    fontFamily: 'Source Sans Pro',
                    fontSize: 'calc(15px + 0.5vh)',
                    fontWeight: 'bold',
                }}
                >
                Summary
                </Typography>
            </Grid>
            <Grid item xs={2} textAlign="right">
                <Button
                variant="contained"
                color="primary"
                onClick={handleRefreshData}
                >
                Refresh Data
              </Button>
          </Grid>
        </Grid>
        <Typography
        sx={{
            marginTop: 0,
            marginLeft: 3,
            fontFamily: 'Source Sans Pro',
            fontSize: 'calc(7px + 0.5vh)',
            fontWeight: 'lighter',
        }}
        >
        </Typography>
        {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
                </div>
            </div>
        ) : (
        <>
        <DataGrid
        autoHeight
        sx={{
            marginTop: '2vh',
            marginLeft: 1,
            marginRight: 2,
            padding: '10px 18px 18px 18px',
            backgroundColor: "#FFFFFF",
            border: '1px solid #e0e0e0',
            borderRadius: '10px',
            maxHeight: '80vh', // Definindo a altura máxima da tabela
            overflow: 'auto', // Permitindo o scroll vertical quando necessário
        }}
        rows={sortedData1}
        columns={[
          {
            field: "week",
            headerName: "Week",
            headerClassName: 'customHeader',
            type: 'number',
            flex: 1,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            },
          {
            field: "su_week",
            headerName: "Shipped Units",
            headerClassName: 'customHeader',
            type: 'number',
            flex: 1,
            editable: false,
            headerAlign: 'center',
            align: 'center',
          },
          {
            field: "wow",
            headerName: "WoW",
            headerClassName: 'customHeader',
            flex: 1,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: (params) => {
              const value = params.value * 100; 
              const formattedValue = `${value.toFixed(2)}%`;
        
              if (value > 0) {
                return `+ ${formattedValue}`; 
              }
        
              return formattedValue;
            },
          },

          {
            field: "su_quarter",
            headerName: "QTD",
            headerClassName: 'customHeader',
            flex: 1,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: (params) => {
              const value = params.value;
              return value ? value.toLocaleString() : '';
            },
          },
          {
            field: "ft_share",
            headerName: "FT Share",
            headerClassName: 'customHeader',
            flex: 1,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: (params) => {
              const value = params.value * 100; // Multiply by 100 to convert to percentage
              const formattedValue = `${value.toFixed(2)}%`; // Format the value with two decimal places and append the percentage sign
              return formattedValue;
            },
          },    
          ]}
        components={{
            Toolbar: CustomToolbar,
        }}
        slotProps={{
            toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            },
        }}
        editMode="row"
        disableColumnSelector
        disableDensitySelector
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          filter: {
            filterModel: {
              items: [{ field: 'week', operator: '=', value: currentWeekNumber }],
            },
          },
      }} 
        />
              <Typography
                sx={{
                    marginTop: '2vh',
                    marginLeft: 3,
                    fontFamily: 'Source Sans Pro',
                    fontSize: 'calc(15px + 0.5vh)',
                    textAlign: 'center' // Adicionando alinhamento central
                }}
                >
                Metrics
              </Typography>
            <DataGrid
              autoHeight
              sx={{
                marginTop: '2vh',
                marginLeft: 1,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '1px solid #e0e0e0',
                borderRadius: '10px',
                maxHeight: '80vh', // Definindo a altura máxima da tabela
                overflow: 'auto', // Permitindo o scroll vertical quando necessário
            }}
              rows={sortedData1} // ou substitua com os dados da segunda tabela
              columns={[
                {
                  field: "week",
                  headerName: "Week",
                  headerClassName: 'customHeader',
                  type: 'number',
                  flex: 1,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  },
                {
                  field: "lsr_perc",
                  headerName: "MXSD (%)",
                  headerClassName: 'customHeader',
                  flex: 1,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  renderCell: (params) => {
                    const value = params.value * 100;
                    const formattedValue = `${value.toFixed(2)}%`;
                    const isPositive = value >= 0.38;
                    
                    return (
                      <div
                        style={{
                          color: isPositive ? 'red' : 'green',
                          fontWeight: 'bold',
                        }}
                      >
                        {formattedValue}
                      </div>
                    );
                  },
                },
                {
                  field: "lsr",
                  headerName: "MXSD (Un)",
                  headerClassName: 'customHeader',
                  flex: 1,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueFormatter: (params) => {
                    const value = params.value;
                    return value ? value.toLocaleString() : '';
                  },
                },
                {
                  field: "lsr_wow",
                  headerName: "MXSD (WoW)",
                  headerClassName: 'customHeader',
                  flex: 1,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueFormatter: (params) => {
                    const value = params.value * 100; 
                    const formattedValue = `${value.toFixed(2)}%`; 
              
                    if (value > 0) {
                      return `+ ${formattedValue}`; 
                    }
              
                    return formattedValue;
                  },
                },
                {
                  field: "scr_perc",
                  headerName: "SCR (%)",
                  headerClassName: 'customHeader',
                  flex: 1,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  renderCell: (params) => {
                    const value = params.value * 100;
                    const formattedValue = `${value.toFixed(2)}%`;
                    const isPositive = value >= 0.07;
                    
                    return (
                      <div
                        style={{
                          color: isPositive ? 'red' : 'green',
                          fontWeight: 'bold',
                        }}
                      >
                        {formattedValue}
                      </div>
                    );
                  },
                },
                {
                  field: "scr",
                  headerName: "SCR (Un)",
                  headerClassName: 'customHeader',
                  flex: 1,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                },
                {
                  field: "scr_wow",
                  headerName: "SCR (WoW)",
                  headerClassName: 'customHeader',
                  flex: 1,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueFormatter: (params) => {
                    const value = params.value * 100;
                    const formattedValue = `${value.toFixed(2)}%`; 
              
                    if (value > 0) {
                      return `+ ${formattedValue}`; // Add a "+" sign for positive values
                    }
              
                    return formattedValue;
                  },
                },
                {
                  field: "scr_controllable_perc",
                  headerName: "SCR - Seller Controllable",
                  headerClassName: 'customHeader',
                  flex: 1,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  renderCell: (params) => {
                    const value = params.value * 100;
                    const formattedValue = `${value.toFixed(2)}%`;
                    const isPositive = value >= 0.02;
                    
                    return (
                      <div
                        style={{
                          color: isPositive ? 'red' : 'green',
                          fontWeight: 'bold',
                        }}
                      >
                        {formattedValue}
                      </div>
                    );
                  },
                },
                {
                  field: "fdps_perc",
                  headerName: "FDPS - Seller Miss (%)",
                  headerClassName: 'customHeader',
                  flex: 1,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  renderCell: (params) => {
                    const value = params.value * 100;
                    const formattedValue = `${value.toFixed(2)}%`;
                    const isPositive = value >= 1.20;
                    
                    return (
                      <div
                        style={{
                          color: isPositive ? 'red' : 'green',
                          fontWeight: 'bold',
                        }}
                      >
                        {formattedValue}
                      </div>
                    );
                  },
                }, 
                {
                  field: "fdps",
                  headerName: "FDPS - Seller Miss",
                  headerClassName: 'customHeader',
                  flex: 1,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                },
            
                {
                  field: "fdps_wow",
                  headerName: "FDPS (WoW)",
                  headerClassName: 'customHeader',
                  flex: 1,
                  editable: false,
                  headerAlign: 'center',
                  align: 'center',
                  valueFormatter: (params) => {
                    const value = params.value * 100; // Multiply by 100 to convert to percentage
                    const formattedValue = `${value.toFixed(2)}%`; // Format the value with two decimal places and append the percentage sign
              
                    if (value > 0) {
                      return `+ ${formattedValue}`; // Add a "+" sign for positive values
                    }
              
                    return formattedValue;
                  },
                },
              ]}
              components={{
                Toolbar: CustomToolbar,
              }}
              slotProps={{
                toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                },
            }}
            editMode="row"
            disableColumnSelector
            disableDensitySelector
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              filter: {
                filterModel: {
                  items: [{ field: 'week', operator: '=', value: currentWeekNumber }],
                },
              },
          }} 
            />
          </>
        )} 
    </div>
    );
    
    
};

export default (Summary);