const ErrorPermission = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h1 style={{ fontSize: '2rem', marginTop: '20px' }}>Access Denied</h1>
      <p>You do not have permission to access this page, dear user.</p>
      <div id="memeContainer">
        <img
          id="memeImage"
          src="/ric_boladao.png"
          alt="Meme"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </div>
    </div>
  );
};

export default ErrorPermission;
