import { useState, useEffect, useCallback, useMemo } from "react";


import { API, Amplify, Auth, graphqlOperation  } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Button, Snackbar, Alert, Chip, Grid  } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import awsconfig from '../../../aws-exports';
import { getISOWeek, subWeeks  } from 'date-fns'; 
import { withAuthenticator,Loader, Flex, TabItem } from '@aws-amplify/ui-react';
import DailyOrders from './es_daily_orders';
import Backlog from './main';
import QuickSightDash from './quicksight';
import NewSellers from './new_sellers';
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './table-styles.css';
import { QuickSight } from "aws-sdk";
import CRMechanism from './Orders-Pending-CR-Mechanism';

Amplify.configure(awsconfig);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const TabsEasyShip = ({ username, userGroup }) => {
  const [data, setData] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [value, setValue] = React.useState(0);

    const tableContainerStyle = {
      height: '80vh', 
      overflow: 'auto', 
      display: 'flex',
      flexDirection: 'column',
    };
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <div className='datatable' style={{ height: '100%', width: '100%', marginTop: '5vh' }}>
        <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            ES - ORDER DAILY STATUS MONITORING
        </Typography>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center', padding: '0 24px', marginTop: '1vh' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Daily Orders" {...a11yProps(0)} />
            <Tab label="Backlog" {...a11yProps(1)} />
            <Tab label="New Sellers" {...a11yProps(2)} />
            <Tab label="Quicksight" {...a11yProps(3)} />
            <Tab label="CR Mechanism - Orders Pending" {...a11yProps(4)} />
          </Tabs>
        </Box>        
        <CustomTabPanel value={value} index={0}>
          <DailyOrders
            username={username}
            userGroup={userGroup}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Backlog username={username} userGroup={userGroup}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <NewSellers username={username} userGroup={userGroup}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <QuickSightDash username={username} userGroup={userGroup}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <CRMechanism username={username} userGroup={userGroup}/>
        </CustomTabPanel>
      </Box>
      </div>
    );
    
};


export default withAuthenticator(TabsEasyShip);