import PropTypes from 'prop-types';
import {Select, InputLabel, MenuItem, FormControl, Avatar, Tooltip } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/system';

function SelectEditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
  
    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.stopCellEditMode({ id, field });
    };
  
    return (
      <FormControl fullWidth>
        <InputLabel size='small'>Reason</InputLabel>
        <Select
          value={value}
          label='Reason'
          onChange={handleChange}
          size="small"
          sx={{ height: 1 }}
        >
          <MenuItem value=''>NULL</MenuItem>
          <MenuItem value='WRONG_ORIENTATION_ON_AMAZON_SIDE'>Wrong Orientation on Amazon Side</MenuItem>
          <MenuItem value='INVENTORY_MISMATCH'>Inventory Mismatch</MenuItem>
          <MenuItem value='AMAZON_TECHNICAL_ISSUE'>Amazon Technical Issue</MenuItem>
          <MenuItem value='MISSING_INVOICE_AMAZON'>Missing Invoice - Amazon</MenuItem>
          <MenuItem value='MISSING_INVOICE_SEFAZ'>Missing Invoice - SEFAZ</MenuItem>
          <MenuItem value='MISSING_INVOICE_INTEGRATOR'>Missing Invoice - Integrator</MenuItem>
          <MenuItem value='MISSING_INVOICE_SELLER'>Missing Invoice - Seller</MenuItem>
          <MenuItem value='LOGIN_ISSUES'>Login Issues</MenuItem>
          <MenuItem value='POWER_OR_INTERNET_OUTAGE'>Power or Internet Outage</MenuItem>
          <MenuItem value='PERSONAL_EMERGENCY'>Personal Emergency</MenuItem>
          <MenuItem value='CANCELLATION_SELLER_NOT_CONTROLLABLE'>Cancellation - Seller not controllable</MenuItem>
          <MenuItem value='CANCELLATION_SELLER_CONTROLLABLE'>Cancellation - Seller controllable</MenuItem>
          <MenuItem value='CAPACITY_BREACH'>Capacity Breach</MenuItem>
          <MenuItem value='SELLER_EXITS_ES_PROGRAM'>Seller exits ES program</MenuItem>
          <MenuItem value='CARRIER_ISSUE'>Carrier Issue</MenuItem>
          <MenuItem value='PRICING_ERROR'>Pricing Error</MenuItem>
          <MenuItem value='PRINTING_ISSUES'>Printing Issues</MenuItem>
          <MenuItem value='SELLER_OPERATIONAL_ISSUE'>Seller Operational Issue</MenuItem>
          <MenuItem value='STARTED_TO_PROCESS_ORDERS_LATE'>Started to Process Orders Late</MenuItem>
          <MenuItem value='SELLER_UNRESPONSIVE'>Seller Unresponsive</MenuItem>
          <MenuItem value='HARDWARE_ISSUE'>Hardware Issue</MenuItem>
        </Select>
      </FormControl>
    );
  }

  const renderSelectEditInputCell = (params) => {
    return <SelectEditInputCell {...params} />;
  };

  SelectEditInputCell.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.any,
  };

function SelectEditMissTypeCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
  
    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.stopCellEditMode({ id, field });
    };
  
    return (
      <FormControl fullWidth>
        <InputLabel size='small'>Miss Type</InputLabel>
        <Select
          value={value}
          label='Reason'
          onChange={handleChange}
          size="small"
          sx={{ height: 1 }}
        >
          <MenuItem value='MXSD'>MxSD</MenuItem>
          <MenuItem value='CANCELLATION'>Cancellation</MenuItem>
          <MenuItem value='LH_BY_SELLER'>LH by Seller</MenuItem>
          <MenuItem value='LH_REPORT_ERROR'>LH Report Error</MenuItem>
          <MenuItem value='LH_BY_CARRIER'>LH by Carrier</MenuItem>
        </Select>
      </FormControl>
    );
  }

  SelectEditMissTypeCell.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.any,
  };

// dynamic dropdow for subreason
const subReasonOptions = {
  WRONG_ORIENTATION_ON_AMAZON_SIDE: [
    { value: 'BY_THE_SESU_TEAM', label: 'by the SeSu team' },
    { value: 'BY_SELLER_CENTRAL', label: 'by Seller Central' },
    { value: 'BY_THE_OPS_OWNER', label: 'by the ops owner' },
  ],
  INVENTORY_MISMATCH: [
    { value: 'VIRTUAL_INVENTORY', label: 'Virtual Inventory' },
    { value: 'INVENTORY_DAMAGED', label: 'Inventory damaged' },
    { value: 'STOCK_IN_A_DIFFERENT_FACILITY', label: 'Stock in a different facility' },
  ],
  AMAZON_TECHNICAL_ISSUE: [
    { value: 'ATROPS_CONFIG_ISSUE', label: 'ATROPS config issue' },
    { value: 'HAZMAT_MISCLASSIFICATION', label: 'Hazmat misclassification' },
    { value: 'SELLER_APPLIED_FOR_HOLIDAY,_BUT_ORDERS_FELL_FOR_THAT_CPT', label: 'Seller_applied_for_Holiday,_but_orders_fell_for_that_CPT' },
    { value: 'CREDIT_CARD_ISSUES', label: 'Credit_Card_Issues' },
    { value: 'HYBRID_OFFER', label: 'Hybrid offer' },
  ],
  MISSING_INVOICE_AMAZON: [
    { value: 'AMAZON_-_API_WAS_UNSTABLE', label: 'Amazon - API was unstable' },
    { value: 'NULL_BUYER_NAME/CEP/CPF', label: 'Null Buyer Name/CEP/CPF' },
    { value: 'ERROR_NOT_FOUND', label: 'Error not found' },
  ],
  MISSING_INVOICE_SEFAZ: [
    { value: 'PLATAFORM_WAS_UNSTABLE', label: 'Plataform_was_unstable' },
  ],
  MISSING_INVOICE_INTEGRATOR: [
    { value: 'PLATAFORM_WAS_UNSTABLE', label: 'Plataform_was_unstable' },
    { value: 'NFE_WITH_INVALID_SIGNATURE', label: 'Nfe_with_invalid_signature' },
  ],
  MISSING_INVOICE_SELLER: [
    { value: 'MWS_AUTHORIZATION_TOKEN_HAS_EXPIRED', label: 'MWS_authorization_token_has_expired' },
    { value: 'DUPLICATED/WRONG_CPF', label: 'Duplicated/Wrong_CPF' },
    { value: 'INVOICE_REJECTED', label: 'Invoice_rejected' },
    { value: "SELLER_MISSED_THE_INTEGRATOR'S_PAYMENT_DEADLINE", label: "Seller_missed_the_integrator's_payment_deadline"},
    { value: "BLOCKED_BY_RF", label: 'Blocked_by_RF' },
    { value: "NON-HOMOLOGATED_COMBINATION", label: 'Non-homologated_combination' },
  ],
  LOGIN_ISSUES_SELLER_CENTRAL: [
    { value: 'SHIPPING_PERFORMANCE', label: 'Shipping_Performance' },
    { value: 'POLICY_COMPLIANCE', label: 'Policy_Compliance' },
    { value: 'CUSTOMER_SERVICE_PERFORMANCE', label: 'Customer_Service_Performance' },
    { value: 'ACCOUNT_ACCESSIBILITY', label: 'account accessibility' },
  ],
  POWER_OR_INTERNET_OUTAGE: [
    { value: 'INTERNET_OUTAGE', label: 'Internet_outage' },
    { value: 'POWER_OUTAGE', label: 'Power_outage' },
  ],
  PERSONAL_EMERGENCY: [
    { value: 'GRIEF', label: 'Grief' },
  ],
  CANCELLATION_SELLER_NOT_CONTROLLABLE: [
    { value: 'CUSTOMER_REQUEST', label: 'Customer_Request' },
  ],
  CANCELLATION_SELLER_CONTROLLABLE: [
    { value: 'ROOT_CAUSE_NOT_MAPPED', label: 'root_cause_not_mapped' },
    { value: 'ORDER_PENDING_FOR_7+DAYS', label: 'Order_Pending_for_7+days' },
    { value: 'IE ISSUE', label: 'IE issue' },
    { value: 'ASIN OUT OF DIMENSION', label: 'Asin out of dimension' },
    { value: 'DISCONTINUED_PRODUCT', label: 'Discontinued_Product' },
  ],
  CAPACITY_BREACH: [
    { value: 'CAPACITY_OUTBREAK', label: 'capacity_outbreak' },
    { value: 'SPIKE_ON_BACKLOG_(NOT_MONDAY)', label: 'Spike_on_Backlog_(Not_Monday)' },
    { value: "SPIKE_ON_MONDAY'S_BACKLOG", label: "Spike_on_Monday's_backlog"},
  ],
  CARRIER_ISSUE: [
    { value: 'NO_SHOW', label: 'No_Show' },
    { value: 'PICK_UP_BEFORE_CUT_OFF', label: 'Pick_up_before_Cut_off' },
    { value: 'LATE_PICK_UP', label: 'Late_pick_up' },
    { value: 'FULL_VEHICLE', label: 'full_vehicle' },
    { value: 'BROKEN_VEHICLE', label: 'broken_vehicle' },
    { value: 'ABSENT_DRIVER', label: 'absent_driver' },
    { value: "ROUTING_ERROR_", label: "routing_error_"},
    { value: "INTEGRATION_DELAY", label: 'integration_delay' },
    { value: "APP_FAILURE", label: 'app_failure' },
  ],
  PRICING_ERROR: [
    { value: 'HIGH_FEES_VALUE', label: 'High_fees_value' },
    { value: 'ASIN_SOLD_FOR_INCORRECT_PRICE', label: 'ASIN_sold_for_incorrect_price' },
  ],
  PRINTING_ISSUES: [
    { value: 'MISSING_PRINTER_SUPPLIES', label: 'Missing_Printer_Supplies' },
    { value: 'NFE_PRINTER_ISSUE', label: 'NFe_Printer_Issue' },
    { value: 'RELABELIN', label: 'Relabeling' },
    { value: "SHIPPING__LABEL_PRINTER_NOT_WORKING", label: "Shipping__label_printer_not_working"},
  ],
  SELLER_OPERATIONAL_ISSUE: [
    { value: "DIDN'T_KNOW_HOW_TO_CHECK_ORDERS_TO_BE_SCHEDULED", label: "Didn't know how to check orders to be scheduled" },
    { value: "DIDN'T_KNOW_HOW_TO_PROCESS_ORDER", label: "Didn't_know_how_to_process_orders" },
    { value: "SELLER_CHANGED_ADDRESS_WITHOUT_PRIOR_WARNING", label: "Seller_changed_address_without_prior_warning" },
    { value: "(MASSIVE_SCHEDULING)_SELELR_IS_NOT_USING_INTEGRATOR_TOOL", label: "(massive_scheduling)_Seller_is_not_using_Integrator_Tool" },
    { value: "SELLER_CONFIRMED_THAT_THEY_WOULD_SHCEDULE,BUT_DID_NOT", label: "Seller confirmed that they would schedule, but did not" },
    { value: "PARTIAL_ORDER_PROCESSING", label: "Partial order processing" },
    { value: "UNPLANNED_HOLIDAY_/_VACATION", label: "Unplanned_holiday/vacation" },
    { value: "SELLER_DIDN'T_KNOW_HOW_TO_IGNORE_SHIPMENT", label: "Seller_didn't_know_how_to_ignore_shipment" },
    { value: "ASIN_SET_NOT_MEASURE_ON_SELLER_CENTRAL_(BOXES_ISSUE)", label: "Asin set not measure on Seller Central_(Boxes_Issue)" },
    { value: "ORDER_WAS_NOT_PACKED_WHEN_THE_DRIVER_WENT_TO_PICK_IT_UP", label: "Order_was_not_packed_when_the_driver_went_to_pick_it_up" },
    { value: "THE_ONLY_RESPONSIBLE_FOR_DBA_WAS_ABSENT", label: "The_only_responsible_for_DBA_was_absent" },
    { value: "SELLER_REGULAR_SHIFT_DOES_NOT_APPLY_FOR_DBA_RULES", label: "Seller_regular_shift_does_not_apply_for_DBA_rules"},
    { value: "SELLER_CHANGED_THE_CNPJ_TYPE_AND_CARRIER_COULD_NOT_PICKED_UP_THE_PACKAGES", label: "Seller changed the CNPJ type and carrie could not picked up the packages"},

  ],
  SELLER_EXITS_ES_PROGRAM: [
    { value: 'SELLERS_DEFAULT', label: 'Sellers Default' },
    { value: 'CNPJ_NOT_ELIGIBLE', label: 'CNPJ not eligible' },
  ],
  STARTED_TO_PROCESS_ORDERS_LATE: [
    { value: 'PRIORITIZING_OTHER_MARKETPLACES', label: 'Prioritizing_other_marketplaces ' },
    { value: 'HIGH_VOLUME', label: 'High_volume' },
    { value: 'NOT_AWARE_OF_CUTOFF_TIME', label: 'Not aware of cutoff time' },
  ],
  SELLER_UNRESPONSIVE: [
    { value: '-', label: '-' },
  ],
  HARDWARE_ISSUE: [
    { value: "COMPUTER_NOT_WORKING", label: "Computer_not_working" },
    { value: "SCANNER_NOT_WORKING", label: "Scanner_not_working" },
  ],
};
function SelectEditSubInputCell(props) {
  const { id, value, field, params } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event) => {
    await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
    apiRef.current.stopCellEditMode({ id, field });
  };

  const reason = params.row.reason; // Get the value of the "Reason" column

  // Generate the sub reason options based on the reason value
  const subReasonOptionsForReason = subReasonOptions[reason] || [];

  return (
    <FormControl fullWidth>
      <InputLabel size='small'>Sub Reason</InputLabel>
      <Select
        value={value}
        label='Reason'
        onChange={handleChange}
        size="small"
        sx={{ height: 1 }}
      >
        {subReasonOptionsForReason.map((option) => (
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const renderSelectEditSubInputCell = (params) => {
  return <SelectEditSubInputCell {...params} params={params} />;
};


  SelectEditSubInputCell.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.any,
  };

// ------------------------------------------ TABLE FOR ADMINS 
export const tableColumnsAdmin = [
  {
    field: "expected_ship_date",
    headerName: "Date",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
},
{
  field: "ops_support",
  headerName: "Ops Support",
  headerClassName: 'customHeader',
  width: '100',
  headerAlign: 'center',
  align: 'center',
  renderCell: (params) => {
    const opsOwner = params?.value;

    if (opsOwner) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
          <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
        </div>
      );
    }

    return null; 
  },
},
  {
    field: "seller_name",
    headerName: "Seller Name",
    headerClassName: 'customHeader',
    width: '250',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    },

    {
      field: "merchant_id",
      headerName: "Merchant ID",
      headerClassName: 'customHeader',
      width: '200',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      },
    {
      field: "total",
      headerName: "Boxes Shipped",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "fdps",
      headerName: "FDPS",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
{
  field: "miss_type",
  headerName: "Miss Type",
  headerClassName: 'customHeader',
  width: '100',
  editable: true,
  headerAlign: 'center',
  align: 'center',
  },
{
  field: "reason",
  headerName: "Reason Carrier",
  headerClassName: 'customHeader',
  width: '250',
  editable: false,
  headerAlign: 'center',
},
{
  field: "amazon_comments",
  headerName: "Subreason Carrier",
  headerClassName: 'customHeader',
  width: '250',
  editable: false,
  headerAlign: 'center',
},
{
  field: "miss_type_seller",
  headerName: "Miss Type",
  headerClassName: 'customHeader',
  width: '100',
  editable: true,
  headerAlign: 'center',
  align: 'center',
  },

{
  field: "reason_seller",
  headerName: "Reason Seller",
  headerClassName: 'customHeader',
  width: '250',
  editable: true,
  headerAlign: 'center',
  renderEditCell: renderSelectEditInputCell,
},
{
  field: "subreason_seller",
  headerName: "Subreason Seller",
  headerClassName: 'customHeader',
  width: '250',
  editable: true,
  headerAlign: 'center',
  renderEditCell: renderSelectEditSubInputCell,
},
{
  field: "createdAt",
  headerName: "Last Update",
  width: '150',
  editable: false,
  headerAlign: 'center',
  align: 'center',
  headerClassName: 'customHeader',
  valueFormatter: params => {
      const date = new Date(params?.value);
      const dateString = date.toLocaleDateString();
      const timeString = date.toLocaleTimeString();
      return `${dateString} ${timeString}`;
    },
},  
{
  field: "edited_by",
  headerName: "Edited By",
  headerClassName: 'customHeader',
  width: '150',
  headerAlign: 'center',
},
{
  field: "remove_from_pm",
  headerName: "Remove from PM",
  headerClassName: 'customHeader',
  type: 'boolean',
  width: '100',
  editable: false,
  headerAlign: 'center',
  align: 'center',
},
{
  field: "self_report_survey",
  headerName: "Self-Report",
  headerClassName: 'customHeader',
  width: '100',
  type: 'boolean',
  editable: false,
  headerAlign: 'center',
  align: 'center',
  renderCell: (params) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
        {params.value ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
      </div>
    );
  },
},      
];

// ------------------------------------------ TABLE FOR OPS 
export const tableColumnsOps = [
  {
    field: "expected_ship_date",
    headerName: "Date",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
},
{
  field: "ops_support",
  headerName: "Ops Support",
  headerClassName: 'customHeader',
  width: '100',
  headerAlign: 'center',
  align: 'center',
  renderCell: (params) => {
    const opsOwner = params?.value;

    if (opsOwner) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
          <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
        </div>
      );
    }

    return null; 
  },
},
  {
    field: "seller_name",
    headerName: "Seller Name",
    headerClassName: 'customHeader',
    width: '250',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    },

    {
      field: "merchant_id",
      headerName: "Merchant ID",
      headerClassName: 'customHeader',
      width: '200',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      },
    {
      field: "total",
      headerName: "Boxes Shipped",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "fdps",
      headerName: "FDPS",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
{
  field: "miss_type",
  headerName: "Miss Type",
  headerClassName: 'customHeader',
  width: '100',
  editable: true,
  headerAlign: 'center',
  align: 'center',
  },
{
  field: "reason",
  headerName: "Reason Carrier",
  headerClassName: 'customHeader',
  width: '250',
  editable: false,
  headerAlign: 'center',
},
{
  field: "amazon_comments",
  headerName: "Subreason Carrier",
  headerClassName: 'customHeader',
  width: '250',
  editable: false,
  headerAlign: 'center',
},
{
  field: "miss_type_seller",
  headerName: "Miss Type",
  headerClassName: 'customHeader',
  width: '100',
  editable: true,
  headerAlign: 'center',
  align: 'center',
  },

{
  field: "reason_seller",
  headerName: "Reason Seller",
  headerClassName: 'customHeader',
  width: '250',
  editable: true,
  headerAlign: 'center',
  renderEditCell: renderSelectEditInputCell,
},
{
  field: "subreason_seller",
  headerName: "Subreason Seller",
  headerClassName: 'customHeader',
  width: '250',
  editable: true,
  headerAlign: 'center',
  renderEditCell: renderSelectEditSubInputCell,
},
{
  field: "createdAt",
  headerName: "Last Update",
  width: '150',
  editable: false,
  headerAlign: 'center',
  align: 'center',
  headerClassName: 'customHeader',
  valueFormatter: params => {
      const date = new Date(params?.value);
      const dateString = date.toLocaleDateString();
      const timeString = date.toLocaleTimeString();
      return `${dateString} ${timeString}`;
    },
},  
{
  field: "edited_by",
  headerName: "Edited By",
  headerClassName: 'customHeader',
  width: '150',
  headerAlign: 'center',
},
{
  field: "remove_from_pm",
  headerName: "Remove from PM",
  headerClassName: 'customHeader',
  type: 'boolean',
  width: '100',
  editable: false,
  headerAlign: 'center',
  align: 'center',
},
{
  field: "self_report_survey",
  headerName: "Self-Report",
  headerClassName: 'customHeader',
  width: '100',
  type: 'boolean',
  editable: false,
  headerAlign: 'center',
  align: 'center',
  renderCell: (params) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
        {params.value ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
      </div>
    );
  },
},      
];


      // ------------------------------------------ TABLE ONLY FOR VIEW 
export const tableColumnsView = [
  {
    field: "expected_ship_date",
    headerName: "Date",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
},
{
  field: "ops_support",
  headerName: "Ops Support",
  headerClassName: 'customHeader',
  width: '100',
  headerAlign: 'center',
  align: 'center',
  renderCell: (params) => {
    const opsOwner = params?.value;

    if (opsOwner) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
          <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
        </div>
      );
    }

    return null; 
  },
},
  {
    field: "seller_name",
    headerName: "Seller Name",
    headerClassName: 'customHeader',
    width: '250',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    },

    {
      field: "merchant_id",
      headerName: "Merchant ID",
      headerClassName: 'customHeader',
      width: '200',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      },
    {
      field: "total",
      headerName: "Boxes Shipped",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "fdps",
      headerName: "FDPS",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
{
  field: "miss_type",
  headerName: "Miss Type",
  headerClassName: 'customHeader',
  width: '100',
  editable: true,
  headerAlign: 'center',
  align: 'center',
  },
{
  field: "reason",
  headerName: "Reason Carrier",
  headerClassName: 'customHeader',
  width: '250',
  editable: false,
  headerAlign: 'center',
},
{
  field: "amazon_comments",
  headerName: "Subreason Carrier",
  headerClassName: 'customHeader',
  width: '250',
  editable: false,
  headerAlign: 'center',
},
{
  field: "miss_type_seller",
  headerName: "Miss Type",
  headerClassName: 'customHeader',
  width: '100',
  editable: true,
  headerAlign: 'center',
  align: 'center',
  },

{
  field: "reason_seller",
  headerName: "Reason Seller",
  headerClassName: 'customHeader',
  width: '250',
  editable: true,
  headerAlign: 'center',
  renderEditCell: renderSelectEditInputCell,
},
{
  field: "subreason_seller",
  headerName: "Subreason Seller",
  headerClassName: 'customHeader',
  width: '250',
  editable: true,
  headerAlign: 'center',
  renderEditCell: renderSelectEditSubInputCell,
},
{
  field: "createdAt",
  headerName: "Last Update",
  width: '150',
  editable: false,
  headerAlign: 'center',
  align: 'center',
  headerClassName: 'customHeader',
  valueFormatter: params => {
      const date = new Date(params?.value);
      const dateString = date.toLocaleDateString();
      const timeString = date.toLocaleTimeString();
      return `${dateString} ${timeString}`;
    },
},  
{
  field: "edited_by",
  headerName: "Edited By",
  headerClassName: 'customHeader',
  width: '150',
  headerAlign: 'center',
},
{
  field: "remove_from_pm",
  headerName: "Remove from PM",
  headerClassName: 'customHeader',
  type: 'boolean',
  width: '100',
  editable: false,
  headerAlign: 'center',
  align: 'center',
},
{
  field: "self_report_survey",
  headerName: "Self-Report",
  headerClassName: 'customHeader',
  width: '100',
  type: 'boolean',
  editable: false,
  headerAlign: 'center',
  align: 'center',
  renderCell: (params) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
        {params.value ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
      </div>
    );
  },
},      
];