import { useState, useEffect } from "react";
import { listSellerDataPCP2s, listPCPDefects } from "../../../graphql/queries";
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Button, Grid } from '@mui/material';
import awsconfig from '../../../aws-exports';
import { withAuthenticator, Loader } from '@aws-amplify/ui-react';
import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import tableColumns from './seller_data_columns';
import './table-styles.css';

Amplify.configure(awsconfig);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const CustomToolbar = () => (
  <GridToolbarContainer
    sx={{
      marginBottom: '10px',
    }}
    spacing={2}
  >
    <GridToolbarExport />
    <GridToolbarQuickFilter
      sx={{
        position: 'absolute',
        padding: '18px',
        right: '15px'
      }}
    />
  </GridToolbarContainer>
);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SellerDataPCP = ({ username, userGroup }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      let nextToken = null;
      const items = [];
      do {
        const response = await API.graphql(graphqlOperation(listSellerDataPCP2s, {
          limit: 200000,
          nextToken: nextToken
        }));

        const fetchedItems = response.data.listSellerDataPCP2s.items;
        items.push(...fetchedItems);

        nextToken = response.data.listSellerDataPCP2s.nextToken;
      } while (nextToken);
      return items;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchDefectData = async () => {
    try {
      let nextToken = null;
      const items = [];
      do {
        const response = await API.graphql(graphqlOperation(listPCPDefects, {
          limit: 200000,
          nextToken: nextToken
        }));

        const fetchedItems = response.data.listPCPDefects.items;
        items.push(...fetchedItems);

        nextToken = response.data.listPCPDefects.nextToken;
      } while (nextToken);

      return items.filter(item => new Date(item.date_value) >= new Date('2024-01-01'));
    } catch (error) {
      console.error('Error fetching defect data:', error);
      return [];
    }
  };

  const mergeData = (sellerData, defectData) => {
    const mergedData = sellerData.map(seller => {
      const defects = defectData.filter(defect => defect.merchant_id === seller.seller_id);
      const hdscSum = defects.reduce((sum, defect) => sum + (defect.hdsc || 0), 0);
      const iss_inboundedSum = defects.reduce((sum, defect) => sum + (defect.iss_inbounded || 0), 0);
      const iss_not_inboundedSum = defects.reduce((sum, defect) => sum + (defect.iss_not_inbounded || 0), 0);
      const rpiSum = defects.reduce((sum, defect) => sum + (defect.rpi || 0), 0);
      const prepSum = defects.reduce((sum, defect) => sum + (defect.prep || 0), 0);
      const sumIn = defects.reduce((sum, defect) => sum + (defect.shipped_units || 0), 0);
      const totalSum = hdscSum + iss_inboundedSum + iss_not_inboundedSum + rpiSum + prepSum;



      const prepPerSumIn = sumIn !== 0 ? prepSum / sumIn : 0;
      
      

      const totalIn = sumIn !== 0 ? totalSum / sumIn : 0;
      const formattedTotalIn = (totalIn * 100).toFixed(2) + '%';

      const rootCauses = defects.map(defect => defect.root_cause || '').filter(Boolean);
      const uniqueRootCauses = [...new Set(rootCauses)];
      const rootCausesString = uniqueRootCauses.join(', ');

      const fieldsGreaterThanZero = [];
      if (rpiSum > 0) fieldsGreaterThanZero.push('RPI');
      if (hdscSum > 0) fieldsGreaterThanZero.push('HSDC');
      if (iss_inboundedSum > 0) fieldsGreaterThanZero.push('ISS_Inbounded');
      if (iss_not_inboundedSum > 0) fieldsGreaterThanZero.push('ISS_Not_Inbounded');
      if (prepSum > 0) fieldsGreaterThanZero.push('Prep');



      return {
        ...seller,
        rpiSum,
        hdscSum,
        iss_inboundedSum,
        iss_not_inboundedSum,
        prepSum,
        totalSum,
        prepPerSumIn,
        //totalIn,
        sumIn,
        totalIn: formattedTotalIn,
        fieldsGreaterThanZero: fieldsGreaterThanZero.join(', '),
        rootCauses: rootCausesString,
      };
    });
    return mergedData;
  };


  useEffect(() => {
    const fetchDataAndMerge = async () => {
      try {
        const sellerData = await fetchData();
        const defectData = await fetchDefectData();
        const mergedData = mergeData(sellerData, defectData);
        setData(mergedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchDataAndMerge();
  }, []);

  const handleRefreshData = async () => {
    try {
      setLoading(true);
      const sellerData = await fetchData();
      const defectData = await fetchDefectData();
      const mergedData = mergeData(sellerData, defectData);
      setData(mergedData);
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing data:', error);
      setLoading(false);
    }
  };

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2} direction="row">
        <Grid item lg={3} xs={3} md={3}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            PCP - Seller Data
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Button
            variant="contained"
            color="primary"
            onClick={handleRefreshData}
          >
            Refresh Data
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginTop: '20vh' }}>
            <Loader size="large" />
          </div>
        </div>
      ) : (
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 1,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '1px solid #e0e0e0',
              borderRadius: '10px',
              minHeight: '40vh',
              maxHeight: '90vh',
              overflow: 'auto',
            }}
            rows={data.map((row, index) => ({ ...row, id: index }))}
            columns={[
              ...tableColumns, 
              { field: 'totalSum', headerName: 'Defect Units', width: 150 },
              { field: 'fieldsGreaterThanZero', headerName: 'Defect Type', width: 200 },
              { field: 'totalIn', headerName: 'Defects %', width: 100 },
              { field: 'rootCauses', headerName: 'Root Causes', width: 200},
            ]}
            components={{
              Toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            editMode="row"
            disableColumnSelector
            disableDensitySelector
            initialState={{
              pagination: { paginationModel: { pageSize: 100 } },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default withAuthenticator(SellerDataPCP);

