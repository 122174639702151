import './Homepage.css'
import { Typography, Card, CardContent } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from "react";
import { DataGrid, GridToolbar  } from '@mui/x-data-grid';
import fake_data from '../../../assets/fake_cpt'

const Homepage =  ({ name, userGroup }) => {
    //const [name, setName] = useState('');
    useEffect(() => {
        Auth.currentAuthenticatedUser()

    }, [])
    
    return (
    <div>
        <Typography
          sx = {{
            marginTop: '2vh',
            marginLeft: 3,
            fontFamily: 'Source Sans Pro',
            fontSize: '25px',
            fontWeight: 'bold',
          }}
        >
          Homepage
        </Typography>
        <Typography
          sx = {{
            marginTop: 0,
            marginLeft: 3,
            fontFamily: 'Source Sans Pro',
            fontSize: '13px',
            fontWeight: 'lighter',
          }}
        >
          Bem vindo, {name}!
        </Typography>
        <div className = 'widget'>
            <Card sx={{ minWidth: 200, marginLeft: 2, marginRight: 2, marginTop: 3 }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Prenchimento das reasons
                    </Typography>
                    <Typography variant="h5" component="div">
                    85%
                    </Typography>
                </CardContent>
            </Card>
        </div>
        <div className = 'widget'>
            <Card sx={{ minWidth: 200, marginLeft: 2, marginRight: 2, marginTop: 3 }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Visitas
                    </Typography>
                    <Typography variant="h5" component="div">
                    20 completas
                    </Typography>
                </CardContent>
            </Card>
        </div>
        <div className = 'widget'>
            <Card sx={{ minWidth: 200, marginLeft: 2, marginRight: 2, marginTop: 3  }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Sellers
                    </Typography>
                    <Typography variant="h5" component="div">
                    459
                    </Typography>
                </CardContent>
            </Card>
        </div>
        <div className = 'widget'>
            <Card sx={{ minWidth: 200, marginLeft: 2, marginRight: 2, marginTop: 3  }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Scorecard
                    </Typography>
                    <Typography variant="h5" component="div">
                    XXXX
                    </Typography>
                </CardContent>
            </Card>
        </div>
    </div>)
};

export default Homepage;