import PropTypes from 'prop-types';
import { Select, InputLabel, MenuItem, FormControl, Avatar } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { groupBy, sumBy } from 'lodash';

const tableColumns2 = [
  {
    field: "week",
    headerName: "Week",
    type: 'number',
    headerClassName: 'customHeader',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: "owner",
    headerName: "Owner",
    headerClassName: 'customHeader',
    flex: 1,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const opsOwner = params?.value;
  
      if (opsOwner) {
        return (
          <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
        );
      }
  
      return null;
    },
  },
  {
    field: "MXSD_total",
    headerName: "MXSD total orders",
    type: 'number',
    headerClassName: 'customHeader',
    flex: 1,
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: "MXSD_orders",
    headerName: "MXSD blank orders",
    type: 'number',
    headerClassName: 'customHeader',
    flex: 1,
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: "MXSD_percent",
    headerName: "MXSD filled",
    headerClassName: 'customHeader',
    flex: 1,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const value = params.value;
      const formattedValue = `${value}%`;
      let isPositive;
      if (params.row.owner === 'siridani' || params.row.owner === 'bvaboud' || params.row.owner === 'silvinic' || params.row.owner === 'deliamor' || params.row.owner === 'joiisabe') {
        isPositive = value <= 99;
      } else {
        isPositive = value <= 79;
      }
      
      return (
        <div
          style={{
            color: isPositive ? 'red' : 'green',
            fontWeight: 'bold',
          }}
        >
          {formattedValue}
        </div>
      );
    },
  },
  {
    field: "CANCELLATION_total",
    headerName: "SCR total orders",
    type: 'number',
    headerClassName: 'customHeader',
    flex: 1,
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: "CANCELLATION_orders",
    headerName: "SCR blank orders",
    headerClassName: 'customHeader',
    type: 'number',
    flex: 1,
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: "CANCELLATION_percent",
    headerName: "SCR filled (%)",
    headerClassName: 'customHeader',
    flex: 1,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const value = params.value;
      const formattedValue = `${value}%`;
      let isPositive;
      if (params.row.owner === 'siridani' || params.row.owner === 'bvaboud' || params.row.owner === 'silvinic' || params.row.owner === 'deliamor' || params.row.owner === 'joiisabe') {
        isPositive = value <= 99;
      } else {
        isPositive = value <= 79;
      }
      
      return (
        <div
          style={{
            color: isPositive ? 'red' : 'green',
            fontWeight: 'bold',
          }}
        >
          {formattedValue}
        </div>
      );
    },
  },
  {
    field: "LH_BY_SELLER_total",
    headerName: "LH total orders",
    type: 'number',
    headerClassName: 'customHeader',
    flex: 1,
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: "LH_BY_SELLER_orders",
    headerName: "LH blank orders",
    headerClassName: 'customHeader',
    type: 'number',
    flex: 1,
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: "LH_BY_SELLER_percent",
    headerName: "LH filled",
    headerClassName: 'customHeader',
    flex: 1,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const value = params.value;
      const formattedValue = `${value}%`;
      let isPositive;
      if (params.row.owner === 'siridani' || params.row.owner === 'bvaboud' || params.row.owner === 'silvinic' || params.row.owner === 'deliamor' || params.row.owner === 'joiisabe') {
        isPositive = value <= 99;
      } else {
        isPositive = value <= 79;
      }
      
      return (
        <div
          style={{
            color: isPositive ? 'red' : 'green',
            fontWeight: 'bold',
          }}
        >
          {formattedValue}
        </div>
      );
    },
  },
];


export default tableColumns2;
