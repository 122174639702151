import PropTypes from 'prop-types';
import {Select, InputLabel, MenuItem, FormControl, Avatar } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/system';

function SelectEditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
  
    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.stopCellEditMode({ id, field });
    };
  
    return (
      <FormControl fullWidth>
        <InputLabel size='small'>Reason</InputLabel>
        <Select
          value={value}
          label='Reason'
          onChange={handleChange}
          size="small"
          sx={{ height: 1 }}
        >
          <MenuItem value=''>NULL</MenuItem>
          <MenuItem value='INVENTORY_MISMATCH'>Inventory Mismatch</MenuItem>
          <MenuItem value='AMAZON_TECHNICAL_ISSUE'>Amazon Technical Issue</MenuItem>
          <MenuItem value='MISSING_INVOICE_AMAZON'>Missing Invoice - Amazon</MenuItem>
          <MenuItem value='MISSING_INVOICE_SEFAZ'>Missing Invoice - SEFAZ</MenuItem>
          <MenuItem value='MISSING_INVOICE_INTEGRATOR'>Missing Invoice - Integrator</MenuItem>
          <MenuItem value='MISSING_INVOICE_SELLER'>Missing Invoice - Seller</MenuItem>
          <MenuItem value='LOGIN_ISSUES'>Login Issues</MenuItem>
          <MenuItem value='POWER_OR_INTERNET_OUTAGE'>Power or Internet Outage</MenuItem>
          <MenuItem value='PERSONAL_EMERGENCY'>Personal Emergency</MenuItem>
          <MenuItem value='CANCELLATION_SELLER_NOT_CONTROLLABLE'>Cancellation - Seller not controllable</MenuItem>
          <MenuItem value='CANCELLATION_SELLER_CONTROLLABLE'>Cancellation - Seller controllable</MenuItem>
          <MenuItem value='CAPACITY_BREACH'>Capacity Breach</MenuItem>
          <MenuItem value='CARRIER_ISSUE'>Carrier Issue</MenuItem>
          <MenuItem value='PRICING_ERROR'>Pricing Error</MenuItem>
          <MenuItem value='PRINTING_ISSUES'>Printing Issues</MenuItem>
          <MenuItem value='SELLER_OPERATIONAL_ISSUE'>Seller Operational Issue</MenuItem>
          <MenuItem value='STARTED_TO_PROCESS_ORDERS_LATE'>Started to Process Orders Late</MenuItem>
          <MenuItem value='SELLER_UNRESPONSIVE'>Seller Unresponsive</MenuItem>
          <MenuItem value='HARDWARE_ISSUE'>Hardware Issue</MenuItem>
        </Select>
      </FormControl>
    );
  }

  const renderSelectEditInputCell = (params) => {
    return <SelectEditInputCell {...params} />;
  };

  SelectEditInputCell.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.any,
  };

function SelectEditMissTypeCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
  
    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.stopCellEditMode({ id, field });
    };
  
    return (
      <FormControl fullWidth>
        <InputLabel size='small'>Miss Type</InputLabel>
        <Select
          value={value}
          label='Reason'
          onChange={handleChange}
          size="small"
          sx={{ height: 1 }}
        >
          <MenuItem value='MXSD'>MxSD</MenuItem>
          <MenuItem value='CANCELLATION'>Cancellation</MenuItem>
          <MenuItem value='LH_BY_SELLER'>LH by Seller</MenuItem>
          <MenuItem value='LH_REPORT_ERROR'>LH Report Error</MenuItem>
          <MenuItem value='LH_BY_CARRIER'>LH by Carrier</MenuItem>
          <MenuItem value='LH_BY_AMAZON'>LH by Carrier</MenuItem>
        </Select>
      </FormControl>
    );
  }

  const renderSelectEditMissTypeCell = (params) => {
    return <SelectEditMissTypeCell {...params} />;
  };

  SelectEditMissTypeCell.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.any,
  };
// dynamic dropdow for subreason
const subReasonOptions = {
  INVENTORY_MISMATCH: [
    { value: 'VIRTUAL_INVENTORY', label: 'Virtual Inventory' },
    { value: 'INVENTORY_DAMAGED', label: 'Inventory damaged' },
    { value: 'STOCK_IN_A_DIFFERENT_FACILITY', label: 'Stock in a different facility' },
  ],
  AMAZON_TECHNICAL_ISSUE: [
    { value: 'ATROPS_CONFIG_ISSUE', label: 'ATROPS config issue' },
    { value: 'HAZMAT_MISCLASSIFICATION', label: 'Hazmat misclassification' },
    { value: 'OFFERS_IS_ACTIVE_WHEN_NODE_WAS_DEACTIVATED', label: 'Offers_is_active_when_node_was_deactivated' },
    { value: 'SELLER_APPLIED_FOR_HOLIDAY,_BUT_ORDERS_FELL_FOR_THAT_CPT', label: 'Seller_applied_for_Holiday,_but_orders_fell_for_that_CPT' },
    { value: 'LH_BY_AMAZON', label: 'LH_by_Amazon' },
    { value: 'HYBRID_OFFER', label: 'Hybrid offer' },
  ],
  MISSING_INVOICE_AMAZON: [
    { value: 'AMAZON_-_API_WAS_UNSTABLE', label: 'Amazon - API was unstable' },
    { value: 'NULL_BUYER_NAME/CEP/CPF', label: 'Null Buyer Name/CEP/CPF' },
    { value: 'ERROR_NOT_FOUND', label: 'Error not found' },
  ],
  MISSING_INVOICE_SEFAZ: [
    { value: 'PLATAFORM_WAS_UNSTABLE', label: 'Plataform_was_unstable' },
  ],
  MISSING_INVOICE_INTEGRATOR: [
    { value: 'NFE_WITH_DIGEST_VALUE', label: 'Nfe_with_digest_value' },
    { value: 'PLATAFORM_WAS_UNSTABLE', label: 'Plataform_was_unstable' },
    { value: 'NFE_WITH_INVALID_SIGNATURE', label: 'Nfe_with_invalid_signature' },
  ],
  MISSING_INVOICE_SELLER: [
    { value: 'SELLER_DID_NOT_FOLLOW_SETUP_INSTRUCTIONS', label: 'Seller did not follow setup instructions' },
    { value: 'SKU_NOT_FOUND_ON_INTEGRATOR', label: 'SKU not found on integrator' },
    { value: 'NCM_NOT_FILLED_INTO_SKU', label: 'NCM not filled into SKU' },
    { value: 'MWS_AUTHORIZATION_TOKEN_HAS_EXPIRED', label: 'MWS authorization token has expired' },
    { value: 'DUPLICATED/WRONG_CPF', label: 'Duplicated/Wrong CPF' },
    { value: 'INVOICE_REJECTED', label: 'Invoice rejected' },
    { value: "SELLER_MISSED_THE_INTEGRATOR'S_PAYMENT_DEADLINE", label: "Seller_missed_the_integrator's_payment_deadline"},
    { value: "BLOCKED_BY_RF", label: 'Blocked by RF' },
    { value: "NON-HOMOLOGATED_COMBINATION", label: 'Non-homologated_combination' },
  ],
  LOGIN_ISSUES: [
    { value: 'SELLER_CENTRAL', label: 'Seller_Central' },
    { value: 'WMS', label: 'WMS' },
  ],
  POWER_OR_INTERNET_OUTAGE: [
    { value: 'INTERNET_OUTAGE', label: 'Internet_outage' },
    { value: 'POWER_OUTAGE', label: 'Power_outage' },
  ],
  PERSONAL_EMERGENCY: [
    { value: 'GRIEF', label: 'Grief' },
  ],
  CANCELLATION_SELLER_NOT_CONTROLLABLE: [
    { value: 'CUSTOMER_REQUEST', label: 'Customer_Request' },
    { value: 'FAKE_ORDERS', label: 'Fake_orders' },
    { value: 'INVALID_SHIP_METHOD', label: 'Invalid_Ship_Method' },
  ],
  CANCELLATION_SELLER_CONTROLLABLE: [
    { value: 'ROOT_CAUSE_NOT_MAPPED', label: 'root_cause_not_mapped' },
    { value: 'ORDER_PENDING_FOR_7+DAYS', label: 'Order_Pending_for_7+days' },
    { value: 'DISCONTINUED_PRODUCT', label: 'Discontinued_Product' },
  ],
  CAPACITY_BREACH: [
    { value: 'NOT_ENOUGH_LABOR_CAPACITY', label: 'Not_enough_labor_capacity' },
    { value: 'SPIKE_ON_BACKLOG_(NOT_MONDAY)', label: 'Spike_on_Backlog_(Not_Monday)' },
    { value: "SPIKE_ON_MONDAY'S_BACKLOG", label: "Spike_on_Monday's_backlog"},
  ],
  CARRIER_ISSUE: [
    { value: 'NO_SHOW', label: 'No_Show' },
    { value: 'PICK_UP_BEFORE_CUT_OFF', label: 'Pick_up_before_Cut_off' },
    { value: 'LATE_PICK_UP', label: 'Late_pick_up' },
    { value: 'FULL_VEHICLE', label: 'full_vehicle' },
    { value: 'BROKEN_VEHICLE', label: 'broken_vehicle' },
    { value: 'ABSENT_DRIVER', label: 'absent_driver' },
    { value: "ROUTING_ERROR_", label: "routing_error_"},
    { value: "INTEGRATION_DELAY", label: 'integration_delay' },
    { value: "APP_FAILURE", label: 'app_failure' },
  ],/*
    /*REPORTING_ERROR_NOT_LH: [
    { value: '-', label: '-' },
    { value: 'NO FIRST SCAN AT PICKUP', label: 'No first scan at pickup' },
  ],*/
  PRICING_ERROR: [
    { value: 'HIGH_FEES_VALUE', label: 'High_fees_value' },
    { value: 'ASIN_SOLD_FOR_INCORRECT_PRICE', label: 'ASIN_sold_for_incorrect_price' },
    { value: "SELLER_THOUGHT_PAYMENT_WAS_NOT_APPROVED", label: "Seller_thought_payment_was_not_approved"},
  ],
  PRINTING_ISSUES: [
    { value: 'GIFT_CARD_PRINTER_ISSUE', label: 'Gift_Card_Printer_Issue' },
    { value: 'JAVA/QZ/PRINTER_DRIVER_NOT_INSTALLED', label: 'Java/QZ/Printer_Driver_not_installed' },
    { value: 'MISSING_PRINTER_SUPPLIES', label: 'Missing_Printer_Supplies' },
    { value: 'NFE_PRINTER_ISSUE', label: 'NFe_Printer_Issue' },
    { value: 'SHIPPING_LABEL__NOT_ALIGNED', label: 'Shipping_label__not_aligned' },
    { value: 'SHIPPING__LABEL_PRINTER_NOT_HOMOLOGATED', label: 'Shipping__label_printer_not_homologated' },
    { value: "SHIPPING__LABEL_PRINTER_NOT_WORKING", label: "Shipping__label_printer_not_working"},
  ],
  SELLER_OPERATIONAL_ISSUE: [
    { value: "DIDN'T_KNOW_HOW_TO_CHECK_ORDERS_FOR_THE_EXSD", label: "Didn't_know_how_to_check_orders_for_the_ExSD" },
    { value: "DIDN'T_KNOW_HOW_TO_DO_PACK_STEP", label: "Didn't_know_how_to_do_Pack_Step" },
    { value: "DIDN'T_KNOW_HOW_TO_DO_PICK_STEP", label: "Didn't_know_how_to_do_Pick_Step" },
    { value: "DIDN'T_KNOW_HOW_TO_DO_SHIP_STEP", label: "Didn't_know_how_to_do_Ship_Step" },
    { value: "DIDN'T_KNOW_HOW_TO_DO_SPOO_PROCESS", label: "Didn't_know_how_to_do_SPOO_Process" },
    { value: "SELLER_DIDN'T_KNOW_HOW_TO_IGNORE_SHIPMENT", label: "Seller_didn't_know_how_to_ignore_shipment" },
    { value: "SELLER_CHANGED_ADDRESS_WITHOUT_PRIOR_WARNING", label: "Seller_changed_address_without_prior_warning" },
    { value: "UNPLANNED_HOLIDAY_/_NO_OPERATIONAL_DAY", label: "Unplanned_holiday_/_No_operational_day" },
    { value: "SELLER_DIDN'T_HAVE_BOXES_REGISTERED_ON_ONSITE", label: "Seller_didn't_have_boxes_registered_on_Onsite" },
    { value: "SELLER_IS_NOT_USING_FBA_ONSITE_TOOL", label: "Seller_is_not_using_FBA_Onsite_Tool" },
    { value: "THE_ONLY_RESPONSIBLE_FOR_FBAOS_WAS_ABSENT", label: "The_only_responsible_for_FBAOS_was_absent" },
    { value: "LH-ORDERS_NOT_SHIPPED_TO_THE_CARRIER", label: "LH-Orders_not_shipped_to_the_carrier" },
    { value: "CNPJ_CHANGE", label: "CNPJ Change" },
    { value: "SELLER_CHANGED_THE_CNPJ_TYPE_AND_CARRIER_COULD_NOT_PICKED_UP_THE_PACKAGES", label: "Seller changed the CNPJ type and carrie could not picked up the packages"}
  ],
  STARTED_TO_PROCESS_ORDERS_LATE: [
    { value: '-', label: '-' },
  ],
  SELLER_UNRESPONSIVE: [
    { value: '-', label: '-' },
  ],
  HARDWARE_ISSUE: [
    { value: "COMPUTER_NOT_WORKING", label: "Computer_not_working" },
    { value: "SCANNER_NOT_WORKING", label: "Scanner_not_working" },
  ],
};
function SelectEditSubInputCell(props) {
  const { id, value, field, params } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event) => {
    await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
    apiRef.current.stopCellEditMode({ id, field });
  };

  const reason = params.row.reason; // Get the value of the "Reason" column

  // Generate the sub reason options based on the reason value
  const subReasonOptionsForReason = subReasonOptions[reason] || [];

  return (
    <FormControl fullWidth>
      <InputLabel size='small'>Sub Reason</InputLabel>
      <Select
        value={value}
        label='Reason'
        onChange={handleChange}
        size="small"
        sx={{ height: 1 }}
      >
        {subReasonOptionsForReason.map((option) => (
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const renderSelectEditSubInputCell = (params) => {
  return <SelectEditSubInputCell {...params} params={params} />;
};


  SelectEditSubInputCell.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.any,
  };


// ------------------------------------------TABELA PARA ADMINS
  export const tableColumnsAdmin = [
    {
        field: "week",
        headerName: "Week",
        headerClassName: 'customHeader',
        width: '50',
        editable: false,
        headerAlign: 'center',
        align: 'center',
    },
    {
      field: "ops_owner",
      headerName: "Ops Owner",
      headerClassName: 'customHeader',
      width: '150',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
    
        return null; 
      },
    },
    {
      field: "date",
      headerName: "Miss Date",
      headerClassName: 'customHeader',
      width: '100',
      type: 'date',
      editable: true,
      valueFormatter: params => new Date(params?.value).toLocaleDateString(),
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "node",
      headerName: "Node",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      },
      {
        field: "seller_name",
        headerName: "Seller Name",
        headerClassName: 'customHeader',
        width: '250',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        },
        {
          field: "units",
          headerName: "Units",
          headerClassName: 'customHeader',
          type: 'number',
          width: '100',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          renderCell: (params) => {
            const units = params.value;
            const missType = params.row.miss_type;
        
            // Define the content of the pop-up based on the miss_type value
            let popUpContent = "";
            if (missType === "LH_BY_SELLER" || missType === "LH_BY_CARRIER") {
              popUpContent = params.row.tracking_id;
            } else if (missType === "MXSD" || missType === "CANCELLATION") {
              popUpContent = params.row.order_id_miss;
            }
        
            const StyledTooltip = styled(Tooltip)(({ theme }) => ({
              '& .MuiTooltip-tooltip': {
                color: 'black', // Text color
                backgroundColor: 'rgba(255, 255, 255, 0.9)', // Transparent background
              },
            }));
            
            const popUpComponent = (
              <StyledTooltip title={popUpContent}>
                <span>{units}</span>
              </StyledTooltip>
            );
        
            // Return the pop-up component
            return popUpComponent;
          },
        },
    {
      field: "miss_type",
      headerName: "Miss Type",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      },
    {
      field: "reason",
      headerName: "Reason",
      headerClassName: 'customHeader',
      width: '250',
      editable: true,
      headerAlign: 'center',
      renderEditCell: renderSelectEditInputCell,
    },
    {
      field: "subreason",
      headerName: "Subreason",
      headerClassName: 'customHeader',
      width: '250',
      editable: true,
      headerAlign: 'center',
      renderEditCell: renderSelectEditSubInputCell,
    },
    {
      field: "createdAt",
      headerName: "Last Update",
      width: '200',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
      valueFormatter: params => {
          const date = new Date(params?.value);
          const dateString = date.toLocaleDateString();
          const timeString = date.toLocaleTimeString();
          return `${dateString} ${timeString}`;
        },
    },  
    {
      field: "user_edition",
      headerName: "Edited by",
      headerClassName: 'customHeader',
      width: '100',
      headerAlign: 'center',
    },
    {
      field: "remove_from_pm",
      headerName: "Remove from PM",
      headerClassName: 'customHeader',
      type: 'boolean',
      width: '150',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
            {params.value ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
          </div>
        );
      },
    },
    {
      field: "self_report",
      headerName: "Self-Report",
      headerClassName: 'customHeader',
      width: '100',
      type: 'boolean',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
            {params.value ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
          </div>
        );
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      headerClassName: 'customHeader',
      width: '250',
      editable: true,
      headerAlign: 'center',
    },
    {
      field: "carrier",
      headerName: "Carrier",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
    },
    {
      field: "recurrence",
      headerName: "Recurrence",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
    },  
    {
      field: "ticket",
      headerName: "Ticket",
      headerClassName: 'customHeader',
      width: '100',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const ticketValue = params?.value;
    
        if (ticketValue) {
          return (
            <Link to={ticketValue} target="_blank">Link</Link>
          );
        }
    
        return null; 
      },
    },
    {
      field: "pod",
      headerName: "Manifesto",
      headerClassName: 'customHeader',
      width: '100',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const podValue = params?.value;
    
        if (podValue) {
          return (
            <Link to={podValue} target="_blank">Link</Link>
          );
        }
    
        return null; 
      },
    },  
    {
      field: "poa",
      headerName: "POA",
      headerClassName: 'customHeader',
      width: '100',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const poaValue = params?.value;
    
        if (poaValue) {
          return (
            <Link to={poaValue} target="_blank">Link</Link>
          );
        }
    
        return null; 
      },
    },
      
    ];

// ------------------------------------------TABELA PARA OPS
export const tableColumnsOps = [
  {
      field: "week",
      headerName: "Week",
      headerClassName: 'customHeader',
      width: '50',
      editable: false,
      headerAlign: 'center',
      align: 'center',
  },
  {
    field: "ops_owner",
    headerName: "Ops Owner",
    headerClassName: 'customHeader',
    width: '150',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const opsOwner = params?.value;
  
      if (opsOwner) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
            <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
          </div>
        );
      }
  
      return null; 
    },
  },
  {
    field: "date",
    headerName: "Miss Date",
    headerClassName: 'customHeader',
    width: '100',
    type: 'date',
    editable: false,
    valueFormatter: params => new Date(params?.value).toLocaleDateString(),
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: "node",
    headerName: "Node",
    headerClassName: 'customHeader',
    width: '100',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    },
    {
      field: "seller_name",
      headerName: "Seller Name",
      headerClassName: 'customHeader',
      width: '250',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      },
      {
        field: "units",
        headerName: "Units",
        headerClassName: 'customHeader',
        type: 'number',
        width: '100',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const units = params.value;
          const missType = params.row.miss_type;
      
          // Define the content of the pop-up based on the miss_type value
          let popUpContent = "";
          if (missType === "LH_BY_SELLER" || missType === "LH_BY_CARRIER") {
            popUpContent = params.row.tracking_id;
          } else if (missType === "MXSD" || missType === "CANCELLATION") {
            popUpContent = params.row.order_id_miss;
          }
      
          const StyledTooltip = styled(Tooltip)(({ theme }) => ({
            '& .MuiTooltip-tooltip': {
              color: 'black', // Text color
              backgroundColor: 'rgba(255, 255, 255, 0.9)', // Transparent background
            },
          }));
          
          const popUpComponent = (
            <StyledTooltip title={popUpContent}>
              <span>{units}</span>
            </StyledTooltip>
          );
      
          // Return the pop-up component
          return popUpComponent;
        },
      },
  {
    field: "miss_type",
    headerName: "Miss Type",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    },
  {
    field: "reason",
    headerName: "Reason",
    headerClassName: 'customHeader',
    width: '250',
    editable: true,
    headerAlign: 'center',
    renderEditCell: renderSelectEditInputCell,
  },
  {
    field: "subreason",
    headerName: "Subreason",
    headerClassName: 'customHeader',
    width: '250',
    editable: true,
    headerAlign: 'center',
    renderEditCell: renderSelectEditSubInputCell,
  },
  {
    field: "createdAt",
    headerName: "Last Update",
    width: '200',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'customHeader',
    valueFormatter: params => {
        const date = new Date(params?.value);
        const dateString = date.toLocaleDateString();
        const timeString = date.toLocaleTimeString();
        return `${dateString} ${timeString}`;
      },
  },
  {
    field: "user_edition",
    headerName: "Edited by",
    headerClassName: 'customHeader',
    width: '100',
    headerAlign: 'center',
  },  
  {
    field: "remove_from_pm",
    headerName: "Remove from PM",
    headerClassName: 'customHeader',
    type: 'boolean',
    width: '150',
    editable: true,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
          {params.value ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
        </div>
      );
    },
  },
  {
    field: "self_report",
    headerName: "Self-Report",
    headerClassName: 'customHeader',
    width: '100',
    type: 'boolean',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
          {params.value ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
        </div>
      );
    },
  },
  {
    field: "comments",
    headerName: "Comments",
    headerClassName: 'customHeader',
    width: '250',
    editable: true,
    headerAlign: 'center',
  },
  {
    field: "carrier",
    headerName: "Carrier",
    headerClassName: 'customHeader',
    width: '100',
    editable: false,
    headerAlign: 'center',
  },
  {
    field: "tracking_id",
    headerName: "Tracking id",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const trackingId = params.value;
  
      // Define the maximum length for displaying in the tooltip
      const maxLength = 30; // You can adjust this value as needed
  
      // Check if the trackingId exceeds the maximum length
      if (trackingId && trackingId.length > maxLength) {
        // Display a tooltip with the full trackingId on hover
        return (
          <Tooltip title={trackingId}>
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {trackingId.substring(0, maxLength) + '...'}
            </div>
          </Tooltip>
        );
      }
  
      // If the trackingId is short enough, display it directly
      return trackingId;
    },
  },
  {
    field: "recurrence",
    headerName: "Recurrence",
    headerClassName: 'customHeader',
    width: '100',
    editable: false,
    headerAlign: 'center',
  },  
  {
    field: "ticket",
    headerName: "Ticket",
    headerClassName: 'customHeader',
    width: '150',
    editable: true,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const ticketValue = params?.value;
  
      if (ticketValue) {
        return (
          <Link to={ticketValue} target="_blank">Link</Link>
        );
      }
  
      return null; 
    },
  },
  {
    field: "pod",
    headerName: "Manifesto",
    headerClassName: 'customHeader',
    width: '150',
    editable: true,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const podValue = params?.value;
  
      if (podValue) {
        return (
          <Link to={podValue} target="_blank">Link</Link>
        );
      }
  
      return null; 
    },
  },
  {
    field: "poa",
    headerName: "POA",
    headerClassName: 'customHeader',
    width: '150',
    editable: true,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const poaValue = params?.value;
  
      if (poaValue) {
        return (
          <Link to={poaValue} target="_blank">Link</Link>
        );
      }
  
      return null; 
    },
  },
    
  ];  


// ------------------------------------------ TABELA PARA ONLY VIEW    
export const tableColumnsView = [
      {
          field: "week",
          headerName: "Week",
          headerClassName: 'customHeader',
          width: '50',
          editable: false,
          headerAlign: 'center',
          align: 'center',
      },
      {
        field: "ops_owner",
        headerName: "Ops Owner",
        headerClassName: 'customHeader',
        width: '150',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const opsOwner = params?.value;
      
          if (opsOwner) {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
                <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
              </div>
            );
          }
      
          return null; 
        },
      },
      {
        field: "date",
        headerName: "Miss Date",
        headerClassName: 'customHeader',
        width: '100',
        type: 'date',
        editable: false,
        valueFormatter: params => new Date(params?.value).toLocaleDateString(),
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: "node",
        headerName: "Node",
        headerClassName: 'customHeader',
        width: '100',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        },
        {
          field: "seller_name",
          headerName: "Seller Name",
          headerClassName: 'customHeader',
          width: '250',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          },
          {
            field: "units",
            headerName: "Units",
            headerClassName: 'customHeader',
            type: 'number',
            width: '100',
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
              const units = params.value;
              const missType = params.row.miss_type;
          
              // Define the content of the pop-up based on the miss_type value
              let popUpContent = "";
              if (missType === "LH_BY_SELLER" || missType === "LH_BY_CARRIER") {
                popUpContent = params.row.tracking_id;
              } else if (missType === "MXSD" || missType === "CANCELLATION") {
                popUpContent = params.row.order_id_miss;
              }
          
              const StyledTooltip = styled(Tooltip)(({ theme }) => ({
                '& .MuiTooltip-tooltip': {
                  color: 'black', // Text color
                  backgroundColor: 'rgba(255, 255, 255, 0.9)', // Transparent background
                },
              }));
              
              const popUpComponent = (
                <StyledTooltip title={popUpContent}>
                  <span>{units}</span>
                </StyledTooltip>
              );
          
              // Return the pop-up component
              return popUpComponent;
            },
          },
      {
        field: "miss_type",
        headerName: "Miss Type",
        headerClassName: 'customHeader',
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        },
      {
        field: "reason",
        headerName: "Reason",
        headerClassName: 'customHeader',
        width: '250',
        editable: false,
        headerAlign: 'center',
      },
      {
        field: "subreason",
        headerName: "Subreason",
        headerClassName: 'customHeader',
        width: '250',
        editable: false,
        headerAlign: 'center',
      },
      {
        field: "createdAt",
        headerName: "Last Update",
        width: '200',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
        valueFormatter: params => {
            const date = new Date(params?.value);
            const dateString = date.toLocaleDateString();
            const timeString = date.toLocaleTimeString();
            return `${dateString} ${timeString}`;
          },
      },
      {
        field: "user_edition",
        headerName: "Edited by",
        headerClassName: 'customHeader',
        width: '100',
        headerAlign: 'center',
      },  
      {
        field: "remove_from_pm",
        headerName: "Remove from PM",
        headerClassName: 'customHeader',
        type: 'boolean',
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
              {params.value ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
            </div>
          );
        },
      },
      {
        field: "self_report",
        headerName: "Self-Report",
        headerClassName: 'customHeader',
        width: '100',
        type: 'boolean',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
              {params.value ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
            </div>
          );
        },
      },
      {
        field: "comments",
        headerName: "Comments",
        headerClassName: 'customHeader',
        width: '250',
        editable: false,
        headerAlign: 'center',
      },
      {
        field: "carrier",
        headerName: "Carrier",
        headerClassName: 'customHeader',
        width: '100',
        editable: false,
        headerAlign: 'center',
      },
      {
        field: "recurrence",
        headerName: "Recurrence",
        headerClassName: 'customHeader',
        width: '100',
        editable: false,
        headerAlign: 'center',
      },  
      {
        field: "ticket",
        headerName: "Ticket",
        headerClassName: 'customHeader',
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const ticketValue = params?.value;
      
          if (ticketValue) {
            return (
              <Link to={ticketValue} target="_blank">Link</Link>
            );
          }
      
          return null; 
        },
      },
      {
        field: "pod",
        headerName: "Manifesto",
        headerClassName: 'customHeader',
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const podValue = params?.value;
      
          if (podValue) {
            return (
              <Link to={podValue} target="_blank">Link</Link>
            );
          }
      
          return null; 
        },
      },
      {
        field: "poa",
        headerName: "POA",
        headerClassName: 'customHeader',
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const poaValue = params?.value;
      
          if (poaValue) {
            return (
              <Link to={poaValue} target="_blank">Link</Link>
            );
          }
      
          return null; 
        },
      },
        
      ];
  