import PropTypes from 'prop-types';
import {Select, InputLabel, MenuItem, FormControl, Avatar } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Link } from "react-router-dom";

  const tableColumns = [
    {
      field: "week",
      headerName: "Week",
      headerClassName: 'customHeader',
      type: 'number',
      width: '110',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      },
    {
      field: "su_week",
      headerName: "Shipped Units",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "wow",
      headerName: "WoW",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        const value = params.value * 100; 
        const formattedValue = `${value.toFixed(2)}%`;
  
        if (value > 0) {
          return `+ ${formattedValue}`; 
        }
  
        return formattedValue;
      },
    },
    {
      field: "lsr_perc",
      headerName: "MXSD (%)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 0.38;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: "lsr",
      headerName: "MXSD (Un)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        const value = params.value;
        return value ? value.toLocaleString() : '';
      },
    },
    {
      field: "lsr_wow",
      headerName: "MXSD (WoW)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        const value = params.value * 100; 
        const formattedValue = `${value.toFixed(2)}%`; 
  
        if (value > 0) {
          return `+ ${formattedValue}`; 
        }
  
        return formattedValue;
      },
    },
    {
      field: "scr_perc",
      headerName: "SCR (%)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 0.12;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: "scr",
      headerName: "SCR (Un)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "scr_wow",
      headerName: "SCR (WoW)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`; 
  
        if (value > 0) {
          return `+ ${formattedValue}`; // Add a "+" sign for positive values
        }
  
        return formattedValue;
      },
    },
    {
      field: "fdps_perc",
      headerName: "FDPS - Seller Miss (%)",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 1.30;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
          </div>
        );
      },
    }, 
    {
      field: "fdps",
      headerName: "FDPS - Seller Miss",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: "fdps_wow",
      headerName: "FDPS (WoW)",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        const value = params.value * 100; // Multiply by 100 to convert to percentage
        const formattedValue = `${value.toFixed(2)}%`; // Format the value with two decimal places and append the percentage sign
  
        if (value > 0) {
          return `+ ${formattedValue}`; // Add a "+" sign for positive values
        }
  
        return formattedValue;
      },
    },
    {
      field: "su_quarter",
      headerName: "QTD",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        const value = params.value;
        return value ? value.toLocaleString() : '';
      },
    },
    {
      field: "ft_share",
      headerName: "FT Share",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        const value = params.value * 100; // Multiply by 100 to convert to percentage
        const formattedValue = `${value.toFixed(2)}%`; // Format the value with two decimal places and append the percentage sign
        return formattedValue;
      },
    },    
    ];


export default tableColumns;