import PropTypes from 'prop-types';
import {Select, InputLabel, MenuItem, FormControl, Avatar } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Link } from "react-router-dom";

function SelectEditCapTypeCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
  
    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.stopCellEditMode({ id, field });
    };
  
    return (
      <FormControl fullWidth>
        <InputLabel size='small'>Instructions</InputLabel>
        <Select
          value={value}
          label='Reason'
          onChange={handleChange}
          size="small"
          sx={{ height: 1 }}
        >
          <MenuItem value=''>Blank</MenuItem>
          <MenuItem value='ONE_TIME_CHANGE'>One Time Change</MenuItem>
          <MenuItem value='BLOCK_THE_CAPACITY'>Block The Capacity</MenuItem>
        </Select>
      </FormControl>
    );
  }

  const renderSelectEditCapTypeCell = (params) => {
    return <SelectEditCapTypeCell {...params} />;
  };
// ------------------------------------------TABELA PARA ADMINS
  export const tableColumnsAdmin = [
    {
      field: "user_edition",
      headerName: "User Edit",
      headerClassName: 'customHeader',
      flex: 1,
      headerAlign: 'center',
    },
    {
        field: "id",
        headerName: "Merchant ID",
        flex: 1,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "phone_nft",
        headerName: "Phone NFT",
        flex: 1,
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "phone_comercial",
        headerName: "Phone Comercial",
        flex: 1,
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
    {
        field: "updatedAt",
        headerName: "Last Update",
        flex: 1,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
        valueFormatter: params => {
            const date = new Date(params?.value);
            const dateString = date.toLocaleDateString();
            const timeString = date.toLocaleTimeString();
            return `${dateString} ${timeString}`;
          },
    },     
    ];

// ------------------------------------------ TABLE FOR OSP    
  export const tableColumnsOps = [
    {
      field: "id",
      headerName: "Merchant ID",
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
      field: "phone_nft",
      headerName: "Phone NFT",
      flex: 1,
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
      field: "phone_comercial",
      headerName: "Phone Comercial",
      flex: 1,
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'customHeader',
},
  {
      field: "updatedAt",
      headerName: "Last Update",
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
      valueFormatter: params => {
          const date = new Date(params?.value);
          const dateString = date.toLocaleDateString();
          const timeString = date.toLocaleTimeString();
          return `${dateString} ${timeString}`;
        },
  },  
      ];
  


// ------------------------------------------ TABELA PARA ONLY VIEW    
  export const tableColumnsView = [
    {
      field: "id",
      headerName: "Merchant ID",
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
      field: "phone_nft",
      headerName: "Phone NFT",
      flex: 1,
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
      field: "phone_comercial",
      headerName: "Phone Comercial",
      flex: 1,
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'customHeader',
},
  {
      field: "updatedAt",
      headerName: "Last Update",
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
      valueFormatter: params => {
          const date = new Date(params?.value);
          const dateString = date.toLocaleDateString();
          const timeString = date.toLocaleTimeString();
          return `${dateString} ${timeString}`;
        },
  },  
      ];
  