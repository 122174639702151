import './App.css';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';

function App() {
  const dashboardRef = useRef(null);
  const [embeddedDashboard, setEmbeddedDashboard] = useState(null);
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const [embeddingContext, setEmbeddingContext] = useState(null);
  const [websocket, setWebSocket] = useState(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetch("https://ipwg4ipypl.execute-api.us-west-2.amazonaws.com/embed/anonymous-embed")
        .then((response) => response.json())
        .then((response) => {
          console.log("API Response: ", response);
          setDashboardUrl(response.EmbedUrl);
        })
        .catch((error) => console.error("Error fetching the embed URL:", error));
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const createContext = async () => {
    try {
      const context = await createEmbeddingContext();
      setEmbeddingContext(context);
      console.log("Embedding context created:", context);
    } catch (error) {
      console.error("Error creating embedding context:", error);
    }
  };

  useEffect(() => {
    if (dashboardUrl) {
      createContext();
    }
  }, [dashboardUrl]);

  useEffect(() => {
    if (embeddingContext) {
      embed();
    }
  }, [embeddingContext]);

  const embed = async () => {
    const options = {
      url: dashboardUrl,
      container: dashboardRef.current,
      height: "1200px",
      width: "1200px",
      onLoad: () => {
        console.log("Dashboard loaded successfully.");
        if (embeddedDashboard) {
          // Navigate to the desired dashboard ID here
          embeddedDashboard.navigateToDashboard('070400f7-460a-4e32-bd2f-5c6a25a66899', {});
        }
      },
      onWebSocketEvent: (event) => {
        if (event.type === 'onclose') {
          console.error(`WebSocket closed with code: ${event.code}, reason: ${event.reason}`);
          if (event.code === 1006) {
            console.warn("Attempting to reconnect WebSocket...");
            reconnectWebSocket();
          }
        }
      }
    };

    try {
      const newEmbeddedDashboard = await embeddingContext.embedDashboard(options);
      setEmbeddedDashboard(newEmbeddedDashboard);
      setWebSocket(newEmbeddedDashboard.websocket);
      console.log("Dashboard embedded:", newEmbeddedDashboard);
    } catch (error) {
      console.error("Error embedding the dashboard:", error);
    }
  };

  const reconnectWebSocket = () => {
    if (websocket) {
      websocket.close();
      setWebSocket(null);
    }
    if (embeddedDashboard) {
      embeddedDashboard.refresh().then(() => {
        console.log("WebSocket reconnected.");
      }).catch((error) => {
        console.error("Error reconnecting WebSocket:", error);
      });
    }
  };

  return (
    <>
      <main>
        <div ref={dashboardRef} className="dashboard-container" />
      </main>
    </>
  );
}

export default App;
