import PropTypes from 'prop-types';
import {Select, InputLabel, MenuItem, FormControl, Avatar } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function SelectEditCapTypeCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
  
    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.stopCellEditMode({ id, field });
    };
  
    return (
      <FormControl fullWidth>
        <InputLabel size='small'>Instructions</InputLabel>
        <Select
          value={value}
          label='Reason'
          onChange={handleChange}
          size="small"
          sx={{ height: 1 }}
        >
          <MenuItem value=''>Blank</MenuItem>
          <MenuItem value='ONE_TIME_CHANGE'>One Time Change</MenuItem>
          <MenuItem value='BLOCK_THE_CAPACITY'>Block The Capacity</MenuItem>
        </Select>
      </FormControl>
    );
  }

  const renderSelectEditCapTypeCell = (params) => {
    return <SelectEditCapTypeCell {...params} />;
  };
// ------------------------------------------TABELA PARA ADMINS
  export const tableColumnsAdmin = [
    {
      field: "user_edition",
      headerName: "User Edit",
      headerClassName: 'customHeader',
      width: '150',
      headerAlign: 'center',
    },
    {
        field: "node",
        headerName: "Node",
        width: '100',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "seller_name",
        headerName: "Seller Name",
        width: '250',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
      field: "ops_owner",
      headerName: "Ops Owner",
      headerClassName: 'customHeader',
      width: '150',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
    
        return null; 
      },
    },     
    {
        field: "backup_owner",
        headerName: "Backup Owner",
        headerClassName: 'customHeader',
        width: '150',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const backupOwner = params?.value;
      
          if (backupOwner && backupOwner !== 'Deactivated') {
            return (
              <Avatar alt={backupOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${backupOwner}`} />
            );
          }
      
          return backupOwner;
        },
    },
    {
      field: "phone1",
      headerName: "Phone 1",
      width: '150',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
      field: "phone2",
      headerName: "Phone 2",
      width: '150',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
      field: "phone3",
      headerName: "Phone 3",
      width: '150',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
    field: "email1",
    headerName: "Email 1",
    width: '250',
    editable: true,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'customHeader',
  },  
  {
      field: "email2",
      headerName: "Email 2",
      width: '250',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },  
  {
      field: "email3",
      headerName: "Email 3",
      width: '250',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },

    {
      field: "classification",
      headerName: "Classification",
      width: '250',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
    {
        field: "merchant_id",
        headerName: "Merchant ID",
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "launch_date",
        headerName: "Launch Date",
        width: '100',
        editable: false,
        valueFormatter: params => new Date(params?.value).toLocaleDateString(),
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "sprint",
        headerName: "Sprint",
        width: '100',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    }, 
    {
      field: "relaunch_seller",
      headerName: "Relaunch Seller",
      width: '150',
      editable: true,
      type: 'boolean',
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
            {params.value ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
          </div>
        );
      },
    },           
    {
        field: "capacity",
        headerName: "Capacity",
        width: '100',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "capacity_update_request",
        headerName: "Capacity Update Request",
        width: '180',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
        renderEditCell: renderSelectEditCapTypeCell,
    },
    {
        field: "updatedAt",
        headerName: "Last Update",
        width: '200',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
        valueFormatter: params => {
            const date = new Date(params?.value);
            const dateString = date.toLocaleDateString();
            const timeString = date.toLocaleTimeString();
            return `${dateString} ${timeString}`;
          },
    },     
    {
        field: "integrator",
        headerName: "Integrator",
        width: '200',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    }, 
    {
        field: "erp",
        headerName: "ERP",
        width: '200',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "certificate",
        headerName: "Certificate",
        width: '100',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },  
    {
        field: "is_node_active_fos",
        headerName: "Node Active FOS",
        type: 'boolean',
        width: '200',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },  
    {
        field: "is_temporary",
        headerName: "is temporary",
        type: 'boolean',
        width: '150',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },  
    {
        field: "latest_cpt",
        headerName: "Latest CPT",
        width: '100',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },  
    {
        field: "marketplace",
        headerName: "Marketplace",
        width: '100',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },  
    {
      field: "nam",
      headerName: "NAM",
      width: '150',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
      },
      {
        field: "location_address",
        headerName: "Address",
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
      },
      {
        field: "location_city",
        headerName: "City",
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
      },
      {
        field: "location_state",
        headerName: "State",
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
      },
      {
        field: "efpm_delay",
        headerName: "EFPM Delay",
        width: '100',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },  
    {
        field: "efpm_ft_delay",
        headerName: "EFPM FT Delay",
        width: '150',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },  
    {
      field: "return_rate_target",
      headerName: "Return Rate Target",
      width: '150',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
    },
    {
      field: "seller_poc",
      headerName: "Seller POC",
      width: '100',
      editable: true,
      headerAlign: 'center',
      headerClassName: 'customHeader',
    },
    ];

// ------------------------------------------ TABLE FOR OSP    
  export const tableColumnsOps = [
      {
          field: "node",
          headerName: "Node",
          width: '100',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
          field: "seller_name",
          headerName: "Seller Name",
          width: '250',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
        field: "ops_owner",
        headerName: "Ops Owner",
        headerClassName: 'customHeader',
        width: '150',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const opsOwner = params?.value;
      
          if (opsOwner) {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
                <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
              </div>
            );
          }
      
          return null; 
        },
      },       
      {
          field: "backup_owner",
          headerName: "Backup Owner",
          headerClassName: 'customHeader',
          width: '150',
          editable: true,
          headerAlign: 'center',
          align: 'center',
          renderCell: (params) => {
            const backupOwner = params?.value;
        
            if (backupOwner && backupOwner !== 'Deactivated') {
              return (
                <Avatar alt={backupOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${backupOwner}`} />
              );
            }
        
            return backupOwner;
          },
      },
      {
        field: "classification",
        headerName: "Classification",
        width: '250',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
      {
          field: "merchant_id",
          headerName: "Merchant ID",
          width: '150',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
          field: "phone1",
          headerName: "Phone 1",
          width: '150',
          editable: true,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
          field: "phone2",
          headerName: "Phone 2",
          width: '150',
          editable: true,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
          field: "phone3",
          headerName: "Phone 3",
          width: '150',
          editable: true,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
          field: "launch_date",
          headerName: "Launch Date",
          width: '100',
          editable: false,
          valueFormatter: params => new Date(params?.value).toLocaleDateString(),
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
          field: "sprint",
          headerName: "Sprint",
          width: '100',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
        field: "relaunch_seller",
        headerName: "Relaunch Seller",
        width: '100',
        editable: true,
        type: 'boolean',
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
        renderCell: (params) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
              {params.value ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
            </div>
          );
        },
      },           
      {
          field: "capacity",
          headerName: "Capacity",
          width: '100',
          editable: true,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
          field: "capacity_update_request",
          headerName: "Capacity Update Request",
          width: '180',
          editable: true,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
          renderEditCell: renderSelectEditCapTypeCell,
      },
      {
          field: "updatedAt",
          headerName: "Last Update",
          width: '200',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
          valueFormatter: params => {
              const date = new Date(params?.value);
              const dateString = date.toLocaleDateString();
              const timeString = date.toLocaleTimeString();
              return `${dateString} ${timeString}`;
            },
      },     
      {
          field: "email1",
          headerName: "Email 1",
          width: '250',
          editable: true,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
          field: "email2",
          headerName: "Email 2",
          width: '250',
          editable: true,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
          field: "email3",
          headerName: "Email 3",
          width: '250',
          editable: true,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
          field: "integrator",
          headerName: "Integrator",
          width: '200',
          editable: true,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      }, 
      {
          field: "erp",
          headerName: "ERP",
          width: '200',
          editable: true,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
          field: "certificate",
          headerName: "Certificate",
          width: '100',
          editable: true,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
          field: "is_node_active_fos",
          headerName: "Node Active FOS",
          type: 'boolean',
          width: '200',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
          field: "is_temporary",
          headerName: "is temporary",
          type: 'boolean',
          width: '150',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
          field: "latest_cpt",
          headerName: "Latest CPT",
          width: '100',
          editable: true,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
          field: "marketplace",
          headerName: "Marketplace",
          width: '100',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
        field: "nam",
        headerName: "NAM",
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
        },
        {
          field: "location_address",
          headerName: "Address",
          width: '150',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
        },
        {
          field: "location_city",
          headerName: "City",
          width: '150',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
        },
        {
          field: "location_state",
          headerName: "State",
          width: '150',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
        },
        {
          field: "efpm_delay",
          headerName: "EFPM Delay",
          width: '100',
          editable: true,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
          field: "efpm_ft_delay",
          headerName: "EFPM FT Delay",
          width: '150',
          editable: true,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
        field: "return_rate_target",
        headerName: "Return Rate Target",
        width: '150',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
      },
      {
        field: "seller_poc",
        headerName: "Seller POC",
        width: '100',
        editable: true,
        headerAlign: 'center',
        headerClassName: 'customHeader',
      },
      ];
  


// ------------------------------------------ TABELA PARA ONLY VIEW    
  export const tableColumnsView = [
      {
          field: "node",
          headerName: "Node",
          width: '100',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
          field: "seller_name",
          headerName: "Seller Name",
          width: '250',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
        field: "ops_owner",
        headerName: "Ops Owner",
        headerClassName: 'customHeader',
        width: '150',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const opsOwner = params?.value;
      
          if (opsOwner) {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
                <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
              </div>
            );
          }
      
          return null; 
        },
      },      
      {
          field: "backup_owner",
          headerName: "Backup Owner",
          headerClassName: 'customHeader',
          width: '150',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          renderCell: (params) => {
            const backupOwner = params?.value;
        
            if (backupOwner && backupOwner !== 'Deactivated') {
              return (
                <Avatar alt={backupOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${backupOwner}`} />
              );
            }
        
            return backupOwner;
          },
      },
      {
        field: "classification",
        headerName: "Classification",
        width: '250',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
      field: "merchant_id",
      headerName: "Merchant ID",
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
      {
          field: "launch_date",
          headerName: "Launch Date",
          width: '100',
          editable: false,
          valueFormatter: params => new Date(params?.value).toLocaleDateString(),
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
          field: "sprint",
          headerName: "Sprint",
          width: '100',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
        field: "relaunch_seller",
        headerName: "Relaunch Seller",
        width: '100',
        editable: false,
        type: 'boolean',
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
        renderCell: (params) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
              {params.value ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
            </div>
          );
        },
      },            
      {
          field: "capacity",
          headerName: "Capacity",
          width: '100',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
          field: "capacity_update_request",
          headerName: "Capacity Update Request",
          width: '180',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
          field: "updatedAt",
          headerName: "Last Update",
          width: '200',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
          valueFormatter: params => {
              const date = new Date(params?.value);
              const dateString = date.toLocaleDateString();
              const timeString = date.toLocaleTimeString();
              return `${dateString} ${timeString}`;
            },
      },     
      {
          field: "integrator",
          headerName: "Integrator",
          width: '200',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      }, 
      {
          field: "erp",
          headerName: "ERP",
          width: '200',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },
      {
          field: "certificate",
          headerName: "Certificate",
          width: '100',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
          field: "is_node_active_fos",
          headerName: "Node Active FOS",
          type: 'boolean',
          width: '200',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
          field: "is_temporary",
          headerName: "is temporary",
          type: 'boolean',
          width: '150',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
          field: "latest_cpt",
          headerName: "Latest CPT",
          width: '100',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
          field: "marketplace",
          headerName: "Marketplace",
          width: '100',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
        field: "nam",
        headerName: "NAM",
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
        },
        {
          field: "location_address",
          headerName: "Address",
          width: '150',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
        },
        {
          field: "location_city",
          headerName: "City",
          width: '150',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
        },
        {
          field: "location_state",
          headerName: "State",
          width: '150',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
        },
        {
          field: "efpm_delay",
          headerName: "EFPM Delay",
          width: '100',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
          field: "efpm_ft_delay",
          headerName: "EFPM FT Delay",
          width: '150',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          headerClassName: 'customHeader',
      },  
      {
        field: "return_rate_target",
        headerName: "Return Rate Target",
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
      },
      {
        field: "seller_poc",
        headerName: "Seller POC",
        width: '100',
        editable: false,
        headerAlign: 'center',
        headerClassName: 'customHeader',
      },
      ];
  