import { useState, useEffect, useMemo,useCallback } from "react";
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Chip, Snackbar, Grid } from '@mui/material';
import awsconfig from '../../../aws-exports';
import './summary.css';
import { withAuthenticator, Loader } from '@aws-amplify/ui-react';
import { listSellerDataPCP2s,listPCPPreWBRS} from "../../../graphql/queries";
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { tableColumnsAdmin, tableColumnsOps, tableColumnsView } from './PreWBR_PCP_columns';
import './table-styles.css';
import { startOfWeek, startOfMonth, startOfQuarter, startOfYear } from 'date-fns';
import { groupBy, orderBy } from 'lodash';
import {Select, InputLabel, MenuItem, FormControl, Avatar } from '@mui/material';
import summary from "./summary";
import { updatePCPPreWBR } from "../../../graphql/mutations";


Amplify.configure(awsconfig);

const groupTypes = ['day'];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const CustomToolbar = () => (
  <GridToolbarContainer
    sx={{
      marginBottom: '10px',
    }}
    spacing={2}
  >
    <GridToolbarExport />
    <GridToolbarQuickFilter
      sx={{
        position: 'absolute',
        padding: '18px',
        right: '15px'
      }}
    />
  </GridToolbarContainer>
);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const getStartAndEndOfWeek = (date) => {
  const start = new Date(date);
  const end = new Date(date);
  start.setDate(start.getDate() - start.getDay() + 1); // Monday
  end.setDate(end.getDate() - start.getDay() + 7); // Sunday
  return [start.toISOString().split('T')[0], end.toISOString().split('T')[0]];
};

const getStartAndEndOfMonth = (date) => {
  const start = new Date(date.getFullYear(), date.getMonth(), 1);
  const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return [start.toISOString().split('T')[0], end.toISOString().split('T')[0]];
};

const getStartAndEndOfPreviousMonth = (date) => {
  const month = date.getMonth() === 0 ? 11 : date.getMonth() - 1;
  const year = date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
  const start = new Date(year, month, 1);
  const end = new Date(year, month + 1, 0);
  return [start.toISOString().split('T')[0], end.toISOString().split('T')[0]];
};

const isFirstMondayOfMonth = (date) => {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const firstMonday = new Date(firstDayOfMonth);
  firstMonday.setDate(firstDayOfMonth.getDate() + ((1 - firstDayOfMonth.getDay() + 7) % 7));
  return date.toDateString() === firstMonday.toDateString();
};

const getFilterDates = (date, groupByType) => {
  const today = new Date(date);
  const isMonday = today.getDay() === 1;

  if (groupByType === 'day') {
    if (isMonday) {
      const lastFriday = new Date(today);
      lastFriday.setDate(today.getDate() - (today.getDay() + 2) % 7);
      return [lastFriday.toISOString().split('T')[0], lastFriday.toISOString().split('T')[0]];
    } else {
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      return [yesterday.toISOString().split('T')[0], yesterday.toISOString().split('T')[0]];
    }
  }

  if (groupByType === 'week') {
    if (isMonday) {
      const lastWeekStart = new Date(today);
      lastWeekStart.setDate(today.getDate() - 7);
      const lastWeekEnd = new Date(lastWeekStart);
      lastWeekEnd.setDate(lastWeekStart.getDate() + 6);
      return [lastWeekStart.toISOString().split('T')[0], lastWeekEnd.toISOString().split('T')[0]];
    } else {
      return getStartAndEndOfWeek(today);
    }
  }

  if (groupByType === 'month') {
    if (isFirstMondayOfMonth(today)) {
      return getStartAndEndOfPreviousMonth(today);
    } else {
      return getStartAndEndOfMonth(today);
    }
  }

  return [null, null]; // Handle other cases if needed
};

const Summary = ({ loading, onRefresh, username, userGroup }) => {
  const [isLoading, setLoading] = useState(loading);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [groupByType, setGroupByType] = useState('week');
  const [totalConfirmedPackages, setTotalConfirmedPackages] = useState(0);
  const [editedRows, setEditedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  

  
  const fetchDataSD = async () => {
    try {
      setLoading(true);
      let nextToken = null;
      const items = [];
      do {
        const response = await API.graphql(graphqlOperation(listSellerDataPCP2s, {
          limit: 200000,
          nextToken: nextToken
        }));

        const fetchedItems = response.data.listSellerDataPCP2s.items;
        items.push(...fetchedItems);

        nextToken = response.data.listSellerDataPCP2s.nextToken;
      } while (nextToken);
      return items;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchDataFDPS = async () => {
    try {
      setLoading(true);
      let nextToken = null;
      const items = [];
      do {
        const response = await API.graphql(graphqlOperation(listPCPPreWBRS, {
          limit: 200000,
          nextToken: nextToken
        }));

        const fetchedItems = response.data.listPCPPreWBRS.items;
        items.push(...fetchedItems);

        nextToken = response.data.listPCPPreWBRS.nextToken;
      } while (nextToken);
      return items;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  

  const mergeData2 = (sellerData, fdpsData) => {
    console.log('Seller Data:', sellerData);
    console.log('Defect Data:', fdpsData);
  
    const mergedData2 = fdpsData.map(fdps => {
      const seller = sellerData.find(seller => fdps.merchant_id === seller.seller_id);
      console.log('FDPS:', fdps);
      console.log('Found Seller:', seller);
  
      return {
        ...fdps,
        ops_support: seller ? seller.ops_support : null,
        seller_name: seller ? seller.seller_name : null
      };
    });
  
    console.log('Merged Data:', mergedData2);
    return mergedData2;
  };




  useEffect(() => {
    const total = data.reduce((acc, item) => acc + (item.total || 0), 0);
    setTotalConfirmedPackages(total);
  }, [data]);

  

  useEffect(() => {
    const fetchDataAndMerge2 = async () => {
      try {
        const sellerData = await fetchDataSD();
        const sellerFDPS = await fetchDataFDPS();
        const mergedData2 = mergeData2(sellerData, sellerFDPS);
        setData2(mergedData2);
        setLoading(true);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(true);
      }
    };

    fetchDataAndMerge2();
  }, []);

  const handleGroupByChange = (type) => {
    setGroupByType(type);
  };

  

  // Get the date for 1 week ago
  const sortedData = useMemo(() => {

    const sortedItems = data.sort((a, b) => {
      // Sort by date column in descending order
      const dateComparison = new Date(b.date) - new Date(a.date);
      if (dateComparison !== 0) {
        return dateComparison;
      }
  
      const ordersComparison = b.orders - a.orders;
      if (ordersComparison !== 0) {
        return ordersComparison;
      }
  
      const opsComparison = b.owner - a.owner;
      if (opsComparison !== 0) {
        return opsComparison;
      }
  
    });
  
    return sortedItems;
  }, [data]);

let tableColumns;


if (userGroup.includes('admin') )  {
  tableColumns = tableColumnsAdmin; 
} else if (userGroup.includes('ops_owner') || userGroup.includes('managers')){
  tableColumns = tableColumnsOps; 
} else {
  tableColumns = tableColumnsView; 
}



  

  const handleProcessRowUpdateError = (error, params) => {
    console.error('Error updating row:', error);
    // Perform additional error handling logic, such as showing an error message
  };

  const handleEditRow = useCallback(async (params) => {
    const { id,merchant_id,missed_units,fdps,total,reason,expected_ship_date,amazon_comments,miss_type,miss_type_seller,subreason_seller,reason_seller, _version } = params;
    const editedRow = { id,merchant_id,missed_units,fdps,total,reason,expected_ship_date,amazon_comments,miss_type,miss_type_seller,subreason_seller,reason_seller, _version };
    const existingRow = editedRows.find((row) => row.id === editedRow.id);
    try {
      if (existingRow) {
        // If the edited row is already in the state, update its value
        const updatedRows = editedRows.map((row) =>
          row.id === editedRow.id ? editedRow : row
        );
        setEditedRows(updatedRows);
      } else {
        // If the edited row is not in the state, add it
        setEditedRows((prevRows) => [...prevRows, editedRow]);
      }
    } catch (error) {
      console.error('Error updating rows:', error);
    }
  }, [editedRows]);
  
  const handleUpdateDatabase = async () => {
    try {
      for (const { id,reason_seller, _version } of editedRows) {
        const input = {
          id : id,
          reason_seller: reason_seller,
          //subreason_seller: subreason_seller,
          _version: _version,
          edited_by: username, 
        };
        console.log(input)
        // Perform the GraphQL mutation for each edited row
        await API.graphql(graphqlOperation(updatePCPPreWBR, { input }));
      }
      setInitialData((prevData) => {
        const updatedData = prevData.map((row) => {
          const editedRow = editedRows.find((editedRow) => editedRow.id === row.id);
          console.log(editedRow)
          return editedRow ? { ...row, ...editedRow } : row;
        });
        return updatedData;
      });
      // Clear the edited rows
      setEditedRows([]);
    } catch (error) {
      console.error('Error updating rows:', error);
    }
  };


  


  const tableContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    marginTop: '20px'
  };

  const tableStyle = {
    flex: 1,
    height: '600px'
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarExport />
      <Button
        variant="contained"
        color="primary"
        disabled={editedRows.length === 0} 
        onClick={handleUpdateDatabase}
      >
        Salvar
      </Button>
      <GridToolbarQuickFilter
        sx={{
          position: 'absolute',
          padding: '18px',
          right: '15px'
        }}
      />
    </GridToolbarContainer>
  );

  const handleRefreshData = async () => {
    try {
      setLoading(true);
      await fetchDataFDPS();
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };
  console.log("datafinal",data2)
    // 

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
        <div>
          <Grid container spacing={2} direction="row">
          <Grid item xs={3} md={3}>
            <Typography
                sx={{
                    marginTop: '2vh',
                    marginLeft: 3,
                    fontFamily: 'Source Sans Pro',
                    fontSize: 'calc(15px + 0.5vh)',
                    fontWeight: 'bold',
                }}
                >
                PCP - Pre WBR
                </Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
                <Button
                variant="contained"
                color="primary"
                onClick={handleRefreshData}
                >
                Refresh Data
              </Button>
          </Grid>
          <Grid item xs={8} md={8} className="blankReasonsChip" sx={{ marginTop: '3vh', textAlign: 'right' }}>
              
          </Grid>
          </Grid>
          {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
              </div>
            </div>
            ) : (
            <DataGrid
                sx={{
                  marginTop: '2vh',
                  marginLeft: 2,
                  marginRight: 2,
                  padding: '10px 18px 18px 18px',
                  backgroundColor: "#FFFFFF",
                  border: '0px',
                  borderRadius: '10px',
                  maxHeight: '90vh',
                  overflow: 'auto', 
              }}
              rows={data2}
              columns={tableColumns}
              processRowUpdate={handleEditRow}
              onCellEditCommit={handleEditRow}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              components={{
                Toolbar: CustomToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              editMode="row"
              checkboxSelection
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
              }}
            />
            )}
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000} 
              onClose={handleSnackbarClose}
              message="Edition saved successfully!"
              action={
                <Button color="primary" size="small" onClick={handleSnackbarClose}>
                  Close
                </Button>
              }
            />
        </div>
    </div>
  );
};


export default withAuthenticator(Summary);
