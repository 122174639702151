import { Layout, List, theme , Menu} from 'antd';
import { ToolFilled, ProfileFilled, AmazonCircleFilled, MinusCircleTwoTone, HomeFilled, SignalFilled, FundProjectionScreenOutlined, QuestionCircleOutlined, AmazonOutlined, LoadingOutlined, AlertTwoTone } from '@ant-design/icons';
import { useNavigate, Routes, Route, BrowserRouter as Router, Link, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { Typography, Avatar} from '@mui/material';
import { Authenticator, MenuItem, withAuthenticator } from '@aws-amplify/ui-react';
import awsmobile from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import MissesDaySF from './modules/pages/PreWBR-Pages/PreWBR_SF';
import MissesDayES from './modules/pages/PreWBR-Pages/PreWBR_ES';
import Homepage from './modules/pages/Homepage/Homepage';
import SummarySF from './modules/pages/summarySF/summary';
import SummaryES from './modules/pages/summaryES/summary';
import SellerDataSF from './modules/pages/SellerData-Pages/SellerData_SF';
import SellerDataES from './modules/pages/SellerData-Pages/SellerData_ES';
import TabsSellerFlex from './modules/pages/TabsSellerFlex/Tabs';
import TabsEasyShip from './modules/pages/TabsEasyShip/Tabs';
import TabsPCP from './modules/pages/PCP/Tabs';
import TabsVendorFlex from './modules/pages/VendorFlex/Tabs';
import CptManagement from './modules/pages/CptManagement/cpt_management';
import QuickSight from './modules/pages/QuickSight/quicksight';
import PermissionError from './error-permission';
import NotFound from './Error404';
import SfForecast from './modules/pages/Forecast/SfForecast';
import EasyShipCutOff from './modules/pages/EasyShipCutOff/main_tab';
import VendorFlexCT from './modules/pages/ControlTowerVF/main_tab';
import SellerFlexCPT from './modules/pages/EasyShipCutOff/sf_main';
import { Loader } from '@aws-amplify/ui-react';

Amplify.configure(awsmobile)
//Auth.configure(awsmobile)

function getItem(label, disabled, icon, key, children, type) {
  return {
    key,
    children,
    label,
    icon,
    type,
    disabled,
  };
}

const { Content, Footer, Sider } = Layout;

const items = [
  getItem('Home', null, <HomeFilled />, '/'),
  getItem('Scorecard', true, <SignalFilled />, '/scorecard'),
  getItem('Peak Mgt', null, <FundProjectionScreenOutlined />, 'sub2', [
    getItem('SF - Forecast', null, null, '/sfforecast'),
  ]),
  getItem('Daily Tasks', null, <ProfileFilled />, 'sub3', [
    getItem('Seller FLex', null, null, '/seller-flex'),
    getItem('Easy Ship', null, null, '/easy-ship'),
    getItem('PCP', null, null, '/pcp'),
    getItem('Vendor Flex', null, null, '/vf'), 
    getItem('SIMs', null, null, '/sims'),
  ]),
  getItem('Order Status', null, <AlertTwoTone />, 'sub4', [
    getItem('SF - CPT Management', null, null, '/cpt-mgt'),
    getItem('ES - Cut-Off', null, null, 'escutoff'),
    getItem('VF - Control Tower', null,
      <span style={{ display: 'inline-flex', backgroundColor: 'red', color: 'white', padding: '0.7em 0.4em', borderRadius: '5px', alignItems: 'center' }}>New</span>, 
        'vfct'),
  ]),
  getItem('Seller Management', false, <AmazonCircleFilled />, 'sub5', [
    getItem('[SF] Seller Data', null, null, '/seller-data'),
    getItem('[ES] Seller Data', null, null, '/seller-data-es'),
    getItem('Performance Mgt', true, null, '/performance-mgt'),
    //getItem('Quick Sight', null, null, '/quicksight'),
    getItem('Capacity', true, null, '/capacity'),
    getItem('Returns', true, null, '/returns'),
    getItem('Operational Visits', true, null, '/operational-visits'),
    getItem('Voice of the Seller', true, null, '/vos'),
  ]),
  getItem('Resources', null, <ToolFilled />, 'sub6', [
    getItem('Important Queries', null, null, '/queries'),
    getItem('Seller Flex Wiki', true, null, '/wiki'),
    getItem('SOP Repository', true, null, '/sops'),
  ]),
  getItem('Help', null, <QuestionCircleOutlined />),
  getItem('Sign Out', null, <MinusCircleTwoTone />, 'signout'),
];


function App() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [username, setUsername] = useState(null); 
  const [userGroup, setUserGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(true); // Default expanded state for "Daily Tasks"
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => {
            setUser(userData);
            setUserGroup(userData.signInUserSession.accessToken.payload['cognito:groups']); // Corrected this line
            //setUserGroup(user.signInUserSession.accessToken.payload['cognito:groups']);
            //setUserGroup(userData.attributes['cognito:groups']);
            setLoading(false);
          });
          break;
        case 'signOut':
          setUser(null);
          setUserGroup(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          setLoading(false);
          break;
      }
    });
    getUser().then(userData => {
      const fullUsername = userData.username;
      const usernameWithoutPrefix = fullUsername.replace('midwayauth2_', '');
      setUsername(usernameWithoutPrefix);
    });
    getUser().then(userData => {
      setUser(userData);
      setUserGroup(userData.signInUserSession.accessToken.payload['cognito:groups']); // Corrected this line
      //setUserGroup(user.signInUserSession.accessToken.payload['cognito:groups']);
      //setUserGroup(userData.attributes['cognito:groups']);
    });
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => {
        setLoading(false);
        return userData;
      })
      .catch(() => {
        console.log('Not signed in');
        setLoading(false);
      });
  }

  async function signOut() {
    try {
      await Auth.signOut();
      console.log('Signing Out');
    } catch (error) {
      console.log('Error signing out: ', error);
    }
  }
  function generateDynamicRoutes(userGroup) {
    const dynamicRoutes = [];

    dynamicRoutes.push({ path: '/', element: <Homepage name={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/seller-data', element: <SellerDataSF username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/seller-data-es', element: <SellerDataES username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/summary-sf', element: <SummarySF username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/summary-es', element: <SummaryES username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/queries', element: <PermissionError /> });
    dynamicRoutes.push({ path: '/cpt-mgt', element: <CptManagement /> });
    dynamicRoutes.push({ path: '*', element: <NotFound /> });
    dynamicRoutes.push({ path: '/seller-flex', element: <TabsSellerFlex username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/easy-ship', element: <TabsEasyShip username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/pcp', element: <TabsPCP username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/vf', element: <TabsVendorFlex username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/sfforecast', element: <SfForecast username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/escutoff', element: <EasyShipCutOff username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/sfcutoff', element: <SellerFlexCPT username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/vfct', element: <VendorFlexCT username={username} userGroup={userGroup} /> });

    
    if (userGroup.includes('us-west-2_cuSVtTOAA_midwayAuth2')) {
    }
    if (userGroup.includes('admin')) {
      dynamicRoutes.push({ path: '/quicksight', element: <QuickSight /> });
    } 
    if (userGroup.includes('ops_owner')) {
    } 
    if (userGroup.includes('pcp_team') || userGroup.includes('admin')) {
    dynamicRoutes.push({ path: '/pcp', element: <TabsPCP username={username} userGroup={userGroup} /> });
    } 
    if (userGroup.includes('external')) {
      //dynamicRoutes.push({ path: '/pre-wbr-sf', element: <PermissionError /> });
      dynamicRoutes.push({ path: '/pre-wbr-es', element: <PermissionError /> });
    }
    return dynamicRoutes;
  }
  const Groups = ['admin', 'pocs', 'ops_owner', 'us-west-2_cuSVtTOAA_midwayAuth2', 'managers'];
  // If still loading, display a loading state
  if (loading) {
    return (
      <Loader width="5rem" height="5rem" />
    );
  }

  if (user && userGroup) {
    const Groups = ['admin', 'pocs', 'ops_owner', 'managers'];
    console.log('Username:', username);
    console.log('Group:', userGroup);
    if (Groups.some(group => userGroup.includes(group)) || userGroup.includes("us-west-2_cuSVtTOAA_midwayAuth2")) {
      const badgePicture = `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${username}`;
      const dynamicRoutes = generateDynamicRoutes(userGroup);

      // Render the main application with userGroup-dependent elements
      return (
        <Layout style={{ minHeight: '100vh' }}>
          <Layout>
            <Sider
              collapsible
              // Set defaultCollapsed to "false" to prevent initial collapse
              defaultCollapsed={false}
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
              style={{
                height: '100vh',
                overflow: 'auto',
              }}
            >
              <div style={{ backgroundColor: 'white', textAlign: 'center' }}>
                <img
                  src={badgePicture}
                  style={{
                    display: 'inline-block',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    backgroundPosition: 'center center',
                    objectFit: 'cover',
                    marginTop: '10px',
                    marginBottom: '2px',
                  }}
                />
                <h5
                  style={{
                    fontFamily: 'Source Sans Pro',
                    fontSize: 'calc(10px + 0.5vh)',
                    paddingBottom: '15px',
                    fontWeight: 'normal',
                  }}
                >
                  {username}
                </h5>
              </div>
              <Menu
                onClick={async ({ key }) => {
                  if (key === 'signout') {
                    await signOut();
                  } else {
                    navigate(key);
                  }
                }}
                style={{ minHeight: '98vh', minWidth: '100%', fontSize: 'small' }}
                mode="inline"
                items={items}
              />
            </Sider>
            <Layout className="site-layout">
              <Content style={{ overflow: 'auto', maxHeight: '100vh' }}>
                <Routes>
                  {dynamicRoutes.map((route) => (
                    <Route key={route.path} path={route.path} element={route.element} />
                  ))}           
                </Routes>
              </Content>
              <Footer style={{ textAlign: 'center' }}>
                Ops Specialist Central © 2023 v14.0.0
              </Footer>
            </Layout>
          </Layout>
        </Layout>
      );
    } 
  } else {
    // User is not logged in
    return (
      /*<div>
        <p>User: Not logged in</p>
        <button onClick={() => Auth.federatedSignIn({ provider: 'MidwayOIDC' })}>
          Sign In
        </button>
      </div>*/
      Auth.federatedSignIn({ provider: 'midwayAuth2' })
    );
  }
}

export default App;